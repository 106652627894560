import React, { useCallback, useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';

import { ACCESS, REFRESH } from 'constants/constants';

import * as authHelper from 'utils/auth.helper';

/**
 * A reusable hook for managing a set of access tokens
 *
 * @param {refreshOnPageLoad} bool: Boolean to perform a refresh on the hook mounting
 * @param {keepTokenAlive} bool: Boolean on whether to mount a timer to auto-refresh the token
 * @param {onLogin} function: Callback for when tokens are set via login function
 * @param {onLogout} function: Callback for when tokens are cleared / token fails to refresh
 */

const DEFAULT_PARAMS = {
	refreshOnPageLoad: false,
	keepTokenAlive: false,
	onLogin: () => {},
	onLogout: () => {},
};

export default function useAuthToken(props = DEFAULT_PARAMS) {
	const { refreshOnPageLoad, keepTokenAlive, onLogout, onLogin } = {
		...DEFAULT_PARAMS,
		...props,
	};
	const [lastRefreshed, setLastRefreshed] = useState(new Date());

	const getAccessToken = useCallback(() => localStorage.getItem(ACCESS), []);

	const setAccessToken = useCallback(
		(accessToken) => localStorage.setItem(ACCESS, accessToken),
		[],
	);

	const getRefreshToken = useCallback(() => localStorage.getItem(REFRESH), []);

	const setRefreshToken = useCallback(
		(refreshToken) => localStorage.setItem(REFRESH, refreshToken),
		[],
	);

	const clearTokens = useCallback(() => {
		[ACCESS, REFRESH].forEach((key) => localStorage.removeItem(key));
	}, []);

	const login = useCallback(
		async (values = {}) => {
			try {
				clearTokens();
				const { access, refresh } = await authHelper.login(values);
				setAccessToken(access);
				setRefreshToken(refresh);
				await onLogin();
			} catch (error) {
				const { data } = error.response;
				console.log(data);
				message.error({
					className: 'cuboh-message-dark-theme',
					icon: <CloseOutlined className="icon-fill__danger" size="18" />,
					content: data?.detail,
				});
			}
		},
		[clearTokens, setAccessToken, setRefreshToken, onLogin],
	);

	/**
	 * Function to logout, clear any tokens
	 * and perform any additional callbacks bound into the hook
	 */
	const logout = useCallback(async () => {
		clearTokens();
		await onLogout();
	}, [clearTokens, onLogout]);

	/** Perform a token refresh */
	const refreshUsersToken = useCallback(async () => {
		try {
			const refreshToken = getRefreshToken();
			const accessToken = getAccessToken();
			if (!refreshToken || !accessToken) {
				return;
			}
			const axiosInstance = await createCoreAxiosInstance();
			const { data } = await axiosInstance.post('api/v2/token/refresh', {
				refresh: refreshToken,
			});
			setAccessToken(data.access);
		} catch (error) {
			logout();
			console.error(error);
		}
	}, [getRefreshToken, getAccessToken, setAccessToken, logout]);

	useEffect(() => {
		if (!refreshOnPageLoad) return;
		refreshUsersToken();
	}, [refreshOnPageLoad, refreshUsersToken]);

	useEffect(() => {
		if (!keepTokenAlive) return;

		// Refresh every 3 minutes
		const timer = setTimeout(async () => {
			await refreshUsersToken();
			setLastRefreshed(new Date());
		}, 3 * 60 * 1000);
		return () => clearTimeout(timer);
	}, [lastRefreshed, refreshUsersToken, keepTokenAlive]);

	return {
		refreshUsersToken,
		getAccessToken,
		setAccessToken,
		getRefreshToken,
		setRefreshToken,
		clearTokens,
		logout,
		login,
	};
}
