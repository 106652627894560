import React from 'react';

import { Space, List } from 'antd';
import PropTypes from 'prop-types';

const { Item } = List;

PublishResponse.propTypes = {
	publishResult: PropTypes.object,
};

export default function PublishResponse(publishResult) {
	return (
		<Space direction="vertical">
			<List
				dataSource={publishResult?.publishResult}
				renderItem={(publishResult) => (
					<Item>
						<List
							dataSource={publishResult?.responses}
							renderItem={(response) => (
								<Item>{`${
									response?.integration_name
								}: ${response?.menu_names.toString()}`}</Item>
							)}
						/>
					</Item>
				)}
			/>
		</Space>
	);
}
