import React, { useEffect, useState, useMemo, useCallback } from 'react';

import ReactJson from '@microlink/react-json-view';
import {
	Select,
	Row,
	Col,
	Button,
	Popconfirm,
	message,
	Typography,
	Card,
} from 'antd';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';

import {
	getPOSIntegrations,
	formatPosIntegrations,
	fetchResourcePOS,
	updatePosResource,
	deletePosResource,
} from 'utils/company.helper';

PointOfSaleResourceSetter.propTypes = {
	companyData: PropTypes.object.isRequired,
};

export default function PointOfSaleResourceSetter({ companyData }) {
	const [messageApi, contextHolder] = message.useMessage();
	const [pointOfSaleResource, setPointOfSaleResource] = useState({});
	const [selectedPointOfSale, setSelectedPointOfSale] = useState();
	const [loading, setLoading] = useState(false);
	const [posIntegrations, setPosIntegrations] = useState();

	useEffect(() => {
		if (!companyData.id) return;
		(async () => {
			setPosIntegrations(
				formatPosIntegrations(await getPOSIntegrations(companyData.id)),
			);
		})();
	}, [companyData.id]);

	const posOptions = useMemo(() => {
		if (!posIntegrations) return;
		return posIntegrations?.map((pointOfSale) => ({
			label: pointOfSale.name,
			value: pointOfSale.resourceName,
		}));
	}, [posIntegrations]);

	const success = () => {
		messageApi.open({
			type: 'success',
			content: 'Successfully created/updated POS Resource',
		});
	};

	const error = () => {
		messageApi.open({
			type: 'error',
			content: 'Error updating/deleting POS Resource...try again',
		});
	};

	const fetchAndBuildPointOfSaleResource = useCallback(async () => {
		setLoading(true);
		if (!posIntegrations || !companyData.id) return;

		const posResource = await fetchResourcePOS(companyData.id);

		const pointOfSale = posIntegrations.find(
			({ resourceName }) => resourceName === selectedPointOfSale,
		);

		if (!selectedPointOfSale && posResource)
			setSelectedPointOfSale(posResource.type);
		if (posResource && posResource.type === selectedPointOfSale) {
			for (let i = 0; i < posResource.conditions?.length; i++) {
				const condition = cloneDeep(posResource.conditions[i]);
				const templateCondition = cloneDeep(
					pointOfSale?.template.conditions[0],
				);
				posResource.conditions[i] = merge(templateCondition, condition);
			}
			setPointOfSaleResource(merge(pointOfSale?.template, posResource));
		} else {
			setPointOfSaleResource(
				merge(pointOfSale?.template, { name: companyData.name }),
			);
		}
		setLoading(false);
	}, [companyData, selectedPointOfSale, posIntegrations]);

	useEffect(() => {
		fetchAndBuildPointOfSaleResource();
	}, [
		companyData.id,
		selectedPointOfSale,
		posIntegrations,
		fetchAndBuildPointOfSaleResource,
	]);

	const confirmSubmission = async () => {
		setLoading(true);
		const result = await updatePosResource(companyData.id, pointOfSaleResource);
		if (result) success();
		else error();
		await fetchAndBuildPointOfSaleResource();
		setLoading(false);
	};

	const confirmDeletion = async () => {
		setLoading(true);
		const result = await deletePosResource(companyData.id);
		if (result) success();
		else error();
		await fetchAndBuildPointOfSaleResource();
		setSelectedPointOfSale();
		setLoading(false);
	};

	const handleChange = (value) => {
		setSelectedPointOfSale(value);
	};

	return (
		<>
			<div className="main-content">
				{contextHolder}
				<Card loading={loading}>
					<Row>
						<Col className="m-20">
							<Typography.Text>Point Of Sale:</Typography.Text>
							<Select
								style={{ width: 200 }}
								className="point-of-sale-select ml-10"
								placeholder="Select a POS"
								onChange={handleChange}
								options={posOptions}
								value={selectedPointOfSale}
							/>
						</Col>
						<Col className="m-20">
							<Popconfirm
								title="Save the Resource"
								description="Are you sure to save the resource"
								onConfirm={confirmSubmission}
								okText="Yes"
								cancelText="No"
							>
								<Button type="primary" disabled={loading}>
									Submit
								</Button>
							</Popconfirm>
							<Popconfirm
								title="Delete the Resource"
								description="Are you sure to delete this task? It cannot be undone"
								onConfirm={confirmDeletion}
								okText="Yes"
								cancelText="No"
							>
								<Button
									type="primary"
									danger
									className="ml-10"
									disabled={loading}
								>
									Delete
								</Button>
							</Popconfirm>
						</Col>
					</Row>
					{selectedPointOfSale && (
						<ReactJson
							src={pointOfSaleResource}
							name={null}
							onEdit={(data) => setPointOfSaleResource(data.updated_src)}
							onAdd={(data) => setPointOfSaleResource(data.updated_src)}
							onDelete={(data) => setPointOfSaleResource(data.updated_src)}
						/>
					)}
				</Card>
			</div>
		</>
	);
}
