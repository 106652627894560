import React from 'react';

import { Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';

import { updateUser } from 'utils/user.helper';

EditUser.propTypes = {
	username: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};
export default function EditUser({ id, username, email }) {
	const onFinish = async ({ newEmail, newUsername }) => {
		const result = await updateUser({ id, newEmail, newUsername });
		if (result === true) {
			alert('Success updating user!!');
			window.location.reload();
		}
	};
	const onFinishFailed = (errorInfo) => {
		alert(errorInfo);
	};
	return (
		<Form
			name="basic"
			wrapperCol={{
				span: 16,
			}}
			style={{
				minWidth: 800,
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
		>
			<Form.Item label="Username" name="newUsername" initialValue={username}>
				<Input />
			</Form.Item>

			<Form.Item
				label="Email"
				name="newEmail"
				initialValue={email}
				rules={[{ type: 'email' }]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				wrapperCol={{
					offset: 8,
					span: 16,
				}}
			>
				<Button type="primary" htmlType="submit">
					Update
				</Button>
			</Form.Item>
		</Form>
	);
}
