import React, { useState, useMemo } from 'react';

import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import { Button, Divider, Modal, Select, Space, Typography } from 'antd';
import propTypes from 'prop-types';

import { getMenuSizeResults } from 'menuManagement/MenuManagementHelpers';

const { Text } = Typography;

MenuSizeAnalyser.propTypes = {
	parentCompanyData: propTypes.object,
	companyData: propTypes.object,
};

export default function MenuSizeAnalyser({ parentCompanyData, companyData }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [menuSizeResults, setMenuSizeResults] = useState(null);
	const [selectedAnalysisType, setSelectedAnalysisType] = useState('simple');
	const [isLoading, setIsLoading] = useState(false);

	const merchantIds = useMemo(() => {
		const merchantIds = [];
		if (companyData) {
			for (const merchant of companyData.merchants) {
				merchantIds.push(merchant.id);
			}
		} else if (parentCompanyData) {
			for (const company of parentCompanyData.companies) {
				for (const merchant of company.merchants) {
					merchantIds.push(merchant.id);
				}
			}
		}
		return merchantIds;
	}, [parentCompanyData, companyData]);

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const results = await getMenuSizeResults({
				merchantIds,
				analysisType: selectedAnalysisType,
			});
			setMenuSizeResults(results);
			setResponseModalVisible(true);
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	};

	const selectAndSubmit = () => {
		return (
			<Space direction="vertical" size="small">
				<Select
					className={'menu-management-menu-selector'}
					defaultValue={'simple'}
					options={[
						{ label: 'Simple', value: 'simple' },
						{ label: 'Full', value: 'full' },
					]}
					onChange={(value) => setSelectedAnalysisType(value)}
				/>
				<Button onClick={handleSubmit}>Submit</Button>
			</Space>
		);
	};

	return (
		<>
			<Space direction="vertical">
				<Text strong>Menu Size Analysis</Text>
				<Text>
					This tool will analyze the size of all menus within all merchants in
					this Company/Parent Company
				</Text>
				<Text>
					Simple: Only displays the total count of UNIQUE items in the menus
					(ItemDetail)
				</Text>
				<Text>
					Full: Displays the count of unique items, as well as CubohItem
					entries, disabled items, etc. (may take longer)
				</Text>
				<Divider />
				{isLoading ? <Text>Loading...</Text> : selectAndSubmit()}
				<Divider />
			</Space>
			<MenuSizeResults
				results={menuSizeResults}
				visible={responseModalVisible}
				setVisible={setResponseModalVisible}
				parentOrCompanyId={
					companyData?.id
						? `company_${companyData?.id}`
						: `parent_company${parentCompanyData?.id}`
				}
			/>
		</>
	);
}

MenuSizeResults.propTypes = {
	results: propTypes.object,
	visible: propTypes.bool.isRequired,
	setVisible: propTypes.func.isRequired,
	parentOrCompanyId: propTypes.string,
};

export function MenuSizeResults({
	results,
	visible,
	setVisible,
	parentOrCompanyId,
}) {
	if (!results) return null;

	const downloadCSV = (data) => {
		const blob = new Blob([data], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.setAttribute('href', url);
		a.setAttribute('download', `menu_analysis_${parentOrCompanyId}.csv`);
		a.click();
	};

	const generateCSV = () => {
		const csvRows = [];
		const headers = ['Merchant ID', 'Menu UUID', 'Unique Items', 'Cuboh Items'];
		csvRows.push(headers.join(','));
		for (const [merchant, menus] of Object.entries(results)) {
			if (!menus) continue;
			for (const [menuId, counts] of Object.entries(menus)) {
				csvRows.push(
					`${merchant},${menuId},${counts.unique_items},${
						counts.cuboh_items || 'N/A'
					}`,
				);
			}
		}
		downloadCSV(csvRows.join('\n'));
	};

	const buildWithIcon = (property, detail_item_count, cuboh_item_count = 0) => {
		let icon = <CloseCircleOutlined style={{ color: 'red' }} />;
		if (detail_item_count <= 500 && cuboh_item_count <= 2000) {
			icon = <CheckCircleOutlined style={{ color: 'green' }} />;
		} else if (detail_item_count <= 2000 && cuboh_item_count <= 4000) {
			icon = <WarningOutlined style={{ color: 'orange' }} />;
		}
		if (cuboh_item_count) {
			return (
				<Space key={property}>
					{icon}
					<Text
						key={property}
					>{`${property}: ${detail_item_count} (${cuboh_item_count})`}</Text>
				</Space>
			);
		}
		return (
			<Space key={property}>
				{icon}
				<Text key={property}>{`${property}: ${detail_item_count}`}</Text>
			</Space>
		);
	};

	const formatMenuResults = (results) => {
		if (!results) return null;
		const formattedResults = [];
		for (const [menuId, counts] of Object.entries(results)) {
			if (!counts) continue;
			const menuSizeResult = (
				<Space direction="vertical" size={0}>
					{buildWithIcon(menuId, counts.unique_items, counts.cuboh_items)}
				</Space>
			);
			if (!counts.cuboh_items) {
				formattedResults.push(menuSizeResult);
			} else {
				// this is a full analysis, add additional results
				formattedResults.push(
					<Space direction="vertical" size={3}>
						{menuSizeResult}
						{Object.entries(counts).map(([property, count]) => {
							if (['unique_items', 'cuboh_items'].includes(property))
								return null;
							return <Text key={property}>{`${property}: ${count}`}</Text>;
						})}{' '}
					</Space>,
				);
			}
		}
		return (
			<Space direction="vertical">
				<Text>
					(Result) Menu UUID: unique items (cuboh_items - full analysis)
				</Text>
				<Space direction="vertical">{formattedResults}</Space>
			</Space>
		);
	};

	const formatResults = (results) => {
		if (!results) return null;
		const formattedResults = [];
		const merchantsNoMenus = [];
		for (const [key, value] of Object.entries(results)) {
			if (!value) continue;
			if (Object.keys(value).length === 0) {
				merchantsNoMenus.push(key);
			} else {
				formattedResults.push(
					<Space direction="vertical" key={key}>
						<Text strong>{`Merchant ${key} (${
							Object.keys(value).length
						} menu/s):`}</Text>
						{formatMenuResults(value)}
						<Divider />
					</Space>,
				);
			}
		}
		formattedResults.push(
			<Text strong>{`Merchants with no menus: ${merchantsNoMenus.join(
				', ',
			)}`}</Text>,
		);
		return formattedResults;
	};

	return (
		<Modal
			open={visible}
			centered
			className="show-result__modal"
			getContainer={false}
			title={`Menu Size Analysis Results`}
			width={750}
			footer={
				<Space size={3}>
					<Button onClick={() => setVisible(false)}>OK</Button>
					<Button
						onClick={() => generateCSV(results)}
						disabled={results.detail}
					>
						Export CSV
					</Button>
				</Space>
			}
			onCancel={() => setVisible(false)}
		>
			{results?.detail ? (
				<Text>{results.detail}</Text>
			) : (
				<Space direction="vertical">{formatResults(results)}</Space>
			)}
		</Modal>
	);
}
