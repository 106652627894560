import React from 'react';

import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';

import { updateCompanyAddress } from 'utils/company.helper';

UpdateCompanyAddress.propTypes = {
	companyData: PropTypes.object.isRequired,
};
export default function UpdateCompanyAddress({ companyData }) {
	const onFinish = async (values) => {
		const result = await updateCompanyAddress({
			...values,
			companyID: companyData.id,
		});
		if (result === true) {
			alert('Success updating company!!');
			window.location.reload();
		}
	};
	const onFinishFailed = (errorInfo) => {
		alert(errorInfo);
	};

	return (
		<Form
			name="updateCompanyAddressForm"
			wrapperCol={{
				span: 16,
			}}
			style={{
				minWidth: 800,
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
		>
			<Form.Item
				label="address"
				name="address"
				initialValue={companyData?.address}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="address2"
				name="address2"
				initialValue={companyData?.address2}
			>
				<Input />
			</Form.Item>
			<Form.Item label="City" name="city" initialValue={companyData?.city}>
				<Input />
			</Form.Item>
			<Form.Item label="State" name="state" initialValue={companyData?.state}>
				<Input />
			</Form.Item>
			<Form.Item
				label="Postal/Zip"
				name="zipCode"
				initialValue={companyData?.zipCode}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Country"
				name="country"
				initialValue={companyData?.country}
			>
				<Input />
			</Form.Item>

			<Form.Item
				wrapperCol={{
					offset: 8,
					span: 16,
				}}
			>
				<Button type="primary" htmlType="submit">
					Update
				</Button>
			</Form.Item>
		</Form>
	);
}
