import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ClientContext } from 'graphql-hooks';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

import { sleep, flattenCSVData } from 'utils/helpers';

ExportButton.propTypes = {
	entity: PropTypes.string.isRequired,
	query: PropTypes.string.isRequired,
	relations: PropTypes.array.isRequired,
	pageSize: PropTypes.number,
};

export default function ExportButton({
	entity,
	query,
	relations,
	pageSize = 100,
}) {
	const [isExportingData, setIsExportingData] = useState(false);
	const [isFinishedExport, setIsFinishedExport] = useState(false);
	const [exportData, setExportData] = useState([]);
	const graphqlClient = useContext(ClientContext);

	const relationKeys = useMemo(
		() => relations.map(({ entity }) => entity),
		[relations],
	);

	const getExportDataPage = useCallback(async () => {
		// Purposefully a for-let-loop to force synchroncity
		for (let page = 1; ; page++) {
			const { data } = await graphqlClient.request({
				query,
				variables: {
					limit: pageSize,
					offset: (page - 1) * pageSize,
				},
			});

			const csvData = flattenCSVData(data[entity], relationKeys);
			setExportData((prev) => [...csvData, ...prev]);

			// If we get less data then we requested for, then we are on the final page
			if (data[entity].length < pageSize) {
				setIsFinishedExport(true);
				break;
			}

			// Add a pause so that don't request too quickly
			await sleep(2500);
		}
	}, [graphqlClient, pageSize, query, entity, relationKeys]);

	useEffect(() => {
		let isSubscribed = true;
		if (!isExportingData) return;
		if (isFinishedExport) return;

		(async function () {
			if (!isSubscribed) return;

			try {
				setIsExportingData(true);
				await getExportDataPage();
			} catch (e) {
				console.error(e);
			} finally {
				setIsExportingData(false);
			}
		})();

		return () => (isSubscribed = false);
	}, [isExportingData, isFinishedExport, getExportDataPage]);

	const onExportButtonClick = () => setIsExportingData(true);

	const exportButtonText = isExportingData
		? `Exported ${exportData.length} ${entity}...`
		: 'Start Export';

	const filename = `${entity}-${exportData.length}.csv`;

	return isFinishedExport ? (
		<CSVLink filename={filename} data={exportData}>
			{`Download ${filename}`}
		</CSVLink>
	) : (
		<Button
			type="primary"
			icon={<DownloadOutlined />}
			onClick={onExportButtonClick}
			loading={isExportingData}
		>
			{exportButtonText}
		</Button>
	);
}
