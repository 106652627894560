import React, { useEffect, useState } from 'react';

import {
	Button,
	Slider,
	Row,
	Col,
	InputNumber,
	TimePicker,
	Checkbox,
	Switch,
	Typography,
} from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import PropTypes from 'prop-types';

import {
	fetchRushHourPrepTimes,
	UpdateRushHourPrepTimes,
	toggleRushHourPrepTimes,
} from 'utils/company.helper';

const { Text } = Typography;

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const options = [
	{ label: 'Monday', value: 'mon' },
	{ label: 'Tuesday', value: 'tue' },
	{ label: 'Wednesday', value: 'wed' },
	{ label: 'Thursday', value: 'thu' },
	{ label: 'Friday', value: 'fri' },
	{ label: 'Saturday', value: 'sat' },
	{ label: 'Sunday', value: 'sun' },
];

RushHourPrepTimes.propTypes = {
	companyID: PropTypes.string.isRequired,
	enableRushHour: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	setLoading: PropTypes.func.isRequired,
};
export default function RushHourPrepTimes({
	companyID,
	enableRushHour,
	loading,
	setLoading,
}) {
	const [rushHourPickupTimeInput, setRushHourPickupTimeInput] = useState(20);
	const [rushHourDeliveryTimeInput, setRushHourDeliveryTimeInput] =
		useState(60);
	const [rushHourDays, setRushHourDays] = useState();
	const [startTime, setStartTime] = useState();
	const [endTime, setEndTime] = useState();
	const [originalPrepTimes, setOriginalPrepTimes] = useState([]);
	const [enableRushHourToggle, setEnableRushHourToggle] =
		useState(enableRushHour);

	const timeFormat = 'HH:mm';
	const displayedTimeFormat = 'h:mm a';

	useEffect(() => {
		if (!companyID) return;
		const getRushHourPrepTimes = async () => {
			setLoading(true);
			const rushHourPrepTimes = await fetchRushHourPrepTimes(companyID);
			if (rushHourPrepTimes.length) {
				const prepTimes = [...rushHourPrepTimes];

				setOriginalPrepTimes(
					prepTimes.map((prepTime) => ({
						...prepTime,
						is_deleted: true,
					})),
				);
				setStartTime(dayjs(rushHourPrepTimes[0].start_time, timeFormat));
				setEndTime(dayjs(rushHourPrepTimes[0].end_time, timeFormat));
				setRushHourPickupTimeInput(
					Math.floor(rushHourPrepTimes[0].pickup_time / 60),
				);
				setRushHourDeliveryTimeInput(
					Math.floor(rushHourPrepTimes[0].delivery_time / 60),
				);
				setRushHourDays(rushHourPrepTimes.map(({ weekday }) => weekday));
			}
			setLoading(false);
		};
		getRushHourPrepTimes();
	}, [companyID, setLoading]);

	const onPickupTimeChange = (newTime) => {
		setRushHourPickupTimeInput(newTime);
	};
	const onDeliveryTimeChange = (newTime) => {
		setRushHourDeliveryTimeInput(newTime);
	};
	const onChange = (checkedValues) => {
		setRushHourDays(checkedValues);
	};
	const onStartTimeChange = (time) => {
		if (time.isSameOrAfter(endTime)) {
			setEndTime(time.add(1, 'm'));
		}
		setStartTime(time);
	};
	const onEndTimeChange = (time) => {
		if (time.isSameOrBefore(startTime)) {
			setStartTime(time.subtract(1, 'm'));
		}
		setEndTime(time);
	};

	const updateRushHourPrepTimesHandler = async () => {
		if (
			!rushHourDays ||
			!startTime ||
			!endTime ||
			!rushHourPickupTimeInput ||
			!rushHourDeliveryTimeInput
		) {
			alert('All fields must have at least 1 value');
			return;
		}
		const payload = rushHourDays.map((day) => ({
			weekday: day,
			start_time: startTime.format(timeFormat),
			end_time: endTime.format(timeFormat),
			pickup_time: rushHourPickupTimeInput * 60,
			delivery_time: rushHourDeliveryTimeInput * 60,
		}));
		const result = await UpdateRushHourPrepTimes(
			companyID,
			originalPrepTimes.concat(payload),
		);
		if (result) {
			alert('Success Updating Rush Hour Prep Times!!');
			window.location.reload();
		}
	};
	const onEnablePrepTimeChange = async (checked) => {
		setLoading(true);
		const { company } = await toggleRushHourPrepTimes(companyID, checked);
		if (
			Object.prototype.hasOwnProperty.call(company ?? {}, 'enable_rush_hour')
		) {
			setEnableRushHourToggle(checked);
		}
		setLoading(false);
	};
	return (
		<>
			<Row>
				<Col>
					<Typography.Title className="mt-2" level={4} underline>
						High Traffic/Rush Hour Prep Times
					</Typography.Title>
				</Col>
			</Row>
			<Row className="m-10">
				<Col span={6}>
					<Text>Enable High Traffic Prep Times:</Text>
					<Switch
						disabled={loading}
						checked={enableRushHourToggle}
						onChange={onEnablePrepTimeChange}
					/>
				</Col>
			</Row>
			<Row className="m-10">
				<Col span={2}>
					<Text>Start Time: </Text>
				</Col>
				<Col span={12}>
					<TimePicker
						value={startTime}
						format={displayedTimeFormat}
						onChange={onStartTimeChange}
						use12Hours
					/>
				</Col>
			</Row>
			<Row className="m-10">
				<Col span={2}>
					<Text>End Time: </Text>
				</Col>
				<Col span={12}>
					<TimePicker
						value={endTime}
						format={displayedTimeFormat}
						onChange={onEndTimeChange}
						use12Hours
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Typography.Title className="mt-0 mb-0" level={5}>
						Pickup:
					</Typography.Title>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Slider
						min={1}
						onChange={onPickupTimeChange}
						value={rushHourPickupTimeInput}
					/>
				</Col>
				<Col span={4}>
					<InputNumber
						min={1}
						style={{
							margin: '0 16px',
						}}
						value={rushHourPickupTimeInput}
						onChange={onPickupTimeChange}
						addonAfter="Minutes"
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Typography.Title className="mt-0 mb-0" level={5}>
						Delivery:
					</Typography.Title>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Slider
						min={1}
						onChange={onDeliveryTimeChange}
						value={rushHourDeliveryTimeInput}
					/>
				</Col>
				<Col span={4}>
					<InputNumber
						min={1}
						style={{
							margin: '0 16px',
						}}
						value={rushHourDeliveryTimeInput}
						onChange={onDeliveryTimeChange}
						addonAfter="Minutes"
					/>
				</Col>
			</Row>
			<Row className="mt-20 mb-25">
				<Checkbox.Group
					options={options}
					value={rushHourDays}
					onChange={onChange}
				/>
			</Row>
			<Row className="m-10">
				<Button
					type="primary"
					disabled={loading}
					onClick={() => {
						updateRushHourPrepTimesHandler();
					}}
				>
					Update Rush Hour Prep Times
				</Button>
			</Row>
		</>
	);
}
