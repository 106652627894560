import React, { useState } from 'react';

import PropTypes from 'prop-types';

import PrepTimes from './PrepTimes';
import RushHourPrepTimes from './RushHourPrepTimes';

PrepTimesContainer.propTypes = {
	companyData: PropTypes.object.isRequired,
};

export default function PrepTimesContainer({ companyData }) {
	const [loading, setLoading] = useState(false);
	return (
		<>
			<PrepTimes
				companyData={companyData}
				loading={loading}
				setLoading={setLoading}
			/>
			<RushHourPrepTimes
				companyID={companyData.id}
				enableRushHour={companyData.enableRushHour}
				loading={loading}
				setLoading={setLoading}
			/>
		</>
	);
}
