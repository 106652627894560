/* eslint-disable react/display-name */
import React from 'react';

import { Button, Popconfirm, message, Alert } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';

import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';

const toggleV2Api = (id, entity, performQuery, item) => {
	return (
		<Popconfirm
			title={`Toggle ChowNow V2 API to ${!item.v2ApiEnabled}?`}
			onConfirm={async () => {
				try {
					const axios = await createCoreAxiosInstance();
					await axios.put(`api/corpa/chownow/${item.merchant.id}`, {
						v2_api_enabled: !item.v2ApiEnabled,
					});
					message.success('Success!');
				} catch (err) {
					message.error('Error Toggling V2 API Active');
				} finally {
					performQuery();
				}
			}}
			okText="Toggle"
			cancelText="Cancel"
		>
			<Button key="cnV2Active">Toggle V2 API</Button>
		</Popconfirm>
	);
};

const toggleActive = (id, entity, performQuery, item) => {
	return (
		<>
			{!item.active && (
				<Alert
					className="mb-10"
					message="In order to have this set to active user must be onboarded via the ChowNow onboarding process."
					type="info"
				/>
			)}
			<Popconfirm
				disabled={!item.active}
				title={`${
					item.active ? 'Deactivate? This CANNOT be undone' : 'Activate'
				}`}
				onConfirm={async () => {
					try {
						const axios = await createCoreAxiosInstance();
						await axios.put(`api/corpa/chownow/${item.merchant.id}`, {
							active: !item.active,
						});
						message.success('Success!');
					} catch (err) {
						message.error('Error Deactivating ChowNow Integration');
					} finally {
						performQuery();
					}
				}}
				okText={`${item.active ? 'Deactivate?' : 'Activate'}`}
				cancelText="Cancel"
			>
				<Button disabled={!item.active} key="cnV2Active">
					{!item.active ? 'Activate' : 'Deactivate'}
				</Button>
			</Popconfirm>
		</>
	);
};

const toggleOrderUpdates = (id, entity, performQuery, item) => {
	return (
		<Popconfirm
			title={`${
				item.skipOrderUpdates ? 'Enable Order Updates' : 'Disable Order Updates'
			}`}
			onConfirm={async () => {
				try {
					const axios = await createCoreAxiosInstance();
					await axios.put(`api/corpa/chownow/${item.merchant.id}`, {
						skip_order_updates: !item.skipOrderUpdates,
					});
					message.success('Success!');
				} catch (err) {
					message.error('Error toggleOrderUpdates');
				} finally {
					performQuery();
				}
			}}
			okText={`${item.skipOrderUpdates ? 'Enable' : 'Disable'}`}
			cancelText="Cancel"
		>
			<Button key="toggleOrderUpdates">{`${
				item.skipOrderUpdates ? 'Enable Order Updates' : 'Disable Order Updates'
			}`}</Button>
		</Popconfirm>
	);
};

const toggleOrderPolling = (id, entity, performQuery, item) => {
	return (
		<Popconfirm
			title={`${
				item.skipOrderPolling ? 'Enable Order Polling' : 'Disable Order Polling'
			}`}
			onConfirm={async () => {
				try {
					const axios = await createCoreAxiosInstance();
					await axios.put(`api/corpa/chownow/${item.merchant.id}`, {
						skip_order_polling: !item.skipOrderPolling,
					});
					message.success('Success!');
				} catch (err) {
					message.error('Error toggleOrderPolling');
				} finally {
					performQuery();
				}
			}}
			okText={`${item.skipOrderPolling ? 'Enable' : 'Disable'}`}
			cancelText="Cancel"
		>
			<Button key="toggleOrderUpdates">{`${
				item.skipOrderPolling ? 'Enable Order Polling' : 'Disable Order Polling'
			}`}</Button>
		</Popconfirm>
	);
};

const routes = [
	{
		path: '/list/chownowCredentials',
		sideMenu: true,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
             query Q($limit: Int!, $offset: Int!, $search: String) {
                 chownowCredentials(limit: $limit, offset: $offset, search: $search) {
                    id,
                    displayName
                    merchant: merchantCuboh {
                        id
                        name
                    }
                    company {
                        id
                        name
                    }
                    active
					skipOrderUpdates
					skipOrderPolling
                 }
              }
         `,
			...relation('chownowCredential', 'many'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
			excludeColumns: ['displayName'],
		},
	},
	{
		path: '/list/chownowCredentials/:id',
		sideMenu: false,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
             query Q($id: Int!) {
                 chownowCredential(id: $id) {
                     id,
                     displayName
                     active
                     v2ApiEnabled
					 skipOrderUpdates
					 skipOrderPolling
					 merchantId
                     merchant: merchantCuboh {
                         id
                         name
                     }
                     company {
                         id
                         name
                     }
                         
                 }
              }
         `,
			...relation('chownowCredential', 'single'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
			customActions: [
				toggleActive,
				toggleV2Api,
				toggleOrderUpdates,
				toggleOrderPolling,
			],
		},
	},
];

export default routes;
