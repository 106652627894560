import {
	SET_IS_LOADING,
	SET_ERROR,
	SET_DATA,
	SET_FEATURE_FLAGS,
} from 'constants/auth.constants';
export const DEFAULT_STATE = {
	isLoading: false,
	hasError: null,
	user: null,
	featureFlags: null,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case SET_IS_LOADING:
			return { ...state, isLoading: action.isLoading };
		case SET_ERROR:
			return { ...state, hasError: action.hasError, message: action.message };
		case SET_DATA:
			return { ...state, ...action.data };
		case SET_FEATURE_FLAGS:
			return {
				...state,
				featureFlags: action.featureFlags,
			};
		default:
			return { ...state };
	}
};
