import React, { useState, useCallback } from 'react';

import { Form, Input, Button, Typography, Space } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { restoreMenu } from 'menuManagement/MenuManagementHelpers';

const { Text } = Typography;
const { Item } = Form;

RestoreMenu.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function RestoreMenu({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const onFinish = useCallback(
		async (values) => {
			const menuManagementResponse = await restoreMenu(merchantId, values);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse, setResponseModalVisible, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid UUID');
	}, []);

	return (
		<Form
			name="restoreMenu"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Space direction="vertical" size="small">
				<Text>Please note:</Text>
				<Text>
					After restoring a menu you will need to re-enter the hours and re-link
					the integrations.
				</Text>
				<Text>
					Ensure that any previously disabled items are re-disabled as that is
					reset during the restoration.
				</Text>
				<Item
					label="Menu UUID"
					name="menuUuid"
					rules={[{ required: true, message: 'Menu UUID to restore' }]}
				>
					<Input />
				</Item>
				<Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type="primary" htmlType="submit">
						Execute
					</Button>
					<ResponseModal
						title="Restore Menu Result"
						response={response}
						responseModalVisible={responseModalVisible}
						setResponseModalVisible={setResponseModalVisible}
					/>
				</Item>
			</Space>
		</Form>
	);
}
