import { theme } from 'antd';
const { defaultAlgorithm } = theme;

const colorPrimary = 'rgba(22, 23, 62, 1)';
const colorPrimaryHover = 'rgba(22, 23, 62, .6)';
const colorSecondary = 'rgba(72, 69, 223, 1)';
const colorSecondaryLight = 'rgba(72, 69, 223, .05)';
const colorSecondaryHover = 'rgba(72, 69, 223, .4)';
const colorSuccess = 'rgba(121, 238, 161, 1)';
const colorSuccessLight = 'rgba(121, 238, 161, .2)';
const colorWarning = 'rgba(255, 229, 143, 1)';
const colorWarningLight = 'rgba(255, 229, 143, .2)';
const colorError = 'rgba(250, 122, 104, 1)';
const colorErrorLight = 'rgba(250, 122, 104, .2)';

const microdoseHover = 'rgba(209, 173, 255, .6)';

const venmo = 'rgba(161, 236, 187, 1)';
const venmoHover = 'rgba(161, 236, 187, .6)';

const borderRadius = {
	borderRadius: 2,
	borderRadiusSM: 2,
	borderRadiusLG: 2,
	borderRadiusXS: 2,
	borderRadiusOuter: 2,
	controlOutline: 'transparent',
};

const fontWeight = {
	fontWeight: 400,
	fontWeightStrong: 500,
};

const fontFamily =
	'Graphik-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji';

export const corpaTheme = {
	algorithm: [defaultAlgorithm],
	token: {
		fontFamily,
		...fontWeight,
		colorSuccess,
		colorSuccessBg: colorSuccessLight,
		colorSuccessBorder: colorSuccess,
		colorWarning,
		colorWarningBg: colorWarningLight,
		colorWarningBorder: colorWarning,
		colorError,
		colorErrorBg: colorErrorLight,
		colorErrorBorder: colorError,
		colorInfo: colorSecondaryHover,
		colorInfoBg: colorSecondaryLight,
		colorInfoBorder: colorSecondary,
	},
	components: {
		Anchor: {
			colorPrimary: colorSecondary,
		},
		Button: {
			colorPrimary,
			colorPrimaryHover: colorPrimaryHover,
			colorLink: colorSecondary,
			colorLinkHover: colorPrimary,
		},
		Card: {
			...borderRadius,
		},
		Dropdown: {
			controlItemBgSelected: colorSecondaryHover,
			controlItemBgHover: colorSecondaryHover,
		},
		Input: {
			colorPrimary,
			colorPrimaryAction: colorPrimary,
			colorPrimaryHover: colorPrimary,
		},
		Menu: {
			itemBgSelected: colorSecondaryHover,
			itemBgHover: colorSecondaryHover,
			colorSubItemBgSelected: colorSecondaryHover,
			colorSubItemBgHover: colorSecondaryHover,
		},
		Pagination: {
			colorPrimary,
			colorPrimaryHover,
			colorPrimaryBorder: colorSecondary,
			controlOutline: colorSecondary,
			controlOutlineWidth: 1,
		},
		Table: {
			...borderRadius,
			colorLink: colorPrimary,
			colorLinkHover: colorPrimaryHover,
			colorText: '#4f4f4f',
			colorFillAlter: '#f0eeed',
			colorFillSecondary: '#f5f5f5',
			controlItemBgActiveHover: venmoHover,
			controlItemBgActive: venmo,
			controlItemBgHover: microdoseHover,
		},
		Tabs: {
			colorPrimary: colorSecondary,
			colorPrimaryBorder: colorSecondary,
			colorPrimaryHover: colorSecondaryHover,
			colorPrimaryActive: colorSecondaryHover,
		},
	},
};
