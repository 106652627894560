/* eslint-disable react/display-name */
import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';

const routes = [
	{
		path: '/list/orders',
		layout: 'list',
		sideMenu: true,
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
                 query AllOrders($limit: Int!, $offset: Int!, $search: String) {
                     orders(limit: $limit, offset: $offset, search: $search) {
                        id
                        code
                        delivery
                        deliveryAddress
                        pickup
                        fulfillmentType
                        fulfillmentTime
                        tableElements
                        specialInstructs
                        nameClient
                        phoneClient
                        subtotal
                        tax
                        discount
                        tip
                        grandTotal
                        active
                        created
                        app
                        synced
                        posSynced
                        appId
                        statusOrder
                        merchant {
                            id
                            name
                        }
                     }
                  }
             `,
			relations: [relation('merchant', 'single')],
			...relation('order', 'many'),
			excludeColumns: [
				'created',
				'appId',
				'delivery',
				'deliveryAddress',
				'pickup',
				'fulfillmentTime',
				'tableElements',
				'specialInstructs',
				'nameClient',
				'phoneClient',
				'subtotal',
				'tax',
				'discount',
				'tip',
				'grandTotal',
				'active',
				'synced',
				'posSynced',
			],
		},
	},
	{
		path: '/list/orders/:id',
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
                 query Order($id: Int!) {
                     order(id: $id) {
                         id
                         code
                         delivery
                         deliveryAddress
                         pickup
                         fulfillmentType
                         fulfillmentTime
                         tableElements
                         specialInstructs
                         nameClient
                         phoneClient
                         subtotal
                         tax
                         discount
                         tip
                         delivery
                         grandTotal
                         active
                         created
                         app
                         synced
                         appId
                         posSynced
                         statusOrder
                         merchant {
                             id
                             name
                         }
                     }
                  }
             `,
			relations: [relation('merchant', 'single')],
			...relation('order', 'single'),
		},
	},
];

export default routes;
