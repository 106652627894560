/* eslint-disable react/display-name */
import React from 'react';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Result, Alert, Avatar, Input, Descriptions, Tag, Image } from 'antd';
import { Link } from 'react-router-dom';

// eslint-disable-next-line import/order
import { APP_ICON_BASE_URL, PICKUP, ACTIVE } from 'constants/constants';
import delivery from 'assets/images/delivery-text.png';
// eslint-disable-next-line import/order
import pickup from 'assets/images/pickup-text.png';

export const renderHttpError = (httpError) => (
	<Result
		status="error"
		title={'HTTP Error'}
		subTitle={'Unable to connect to GraphQL Server'}
	>
		<div className="desc">{httpError.body}</div>
	</Result>
);

export const renderFetchError = (fetchError) => (
	<Result
		status="error"
		title={'Fetch Error'}
		subTitle={'Unable to retrieve data from GraphQL server'}
	>
		<div className="desc">{fetchError.message}</div>
	</Result>
);

export const renderGraphQLErrors = (graphQLErrors) => (
	<>
		{graphQLErrors &&
			graphQLErrors.map((error, index) => (
				<Alert
					key={index}
					message="GraphQL Error"
					description={`${error.message} - ${error.path?.join('->')}`}
					type="error"
				/>
			))}
	</>
);

export const renderActions = (
	defaultActions,
	customActions,
	item,
	entity,
	performQuery,
) => [
	...defaultActions,
	...customActions.map((action) => action(item.id, entity, performQuery, item)),
];

/** Compute a random color from the avatar title */
const randomAvatarColor = (title) => {
	const avatarColors = ['#8AC0DE', '#F0C5D5', '#F5C9B2', '#EAB159', '#9E6B55'];
	return avatarColors[title?.charCodeAt(0) % avatarColors.length];
};

export const renderRowAvatar = (title) => (
	<Avatar shape="square" style={{ backgroundColor: randomAvatarColor(title) }}>
		{`${title?.[0] || '?'}${title?.[1] || ''}`}
	</Avatar>
);

export const renderDescription = (item, relations, editable = false) => {
	const fieldDescriptionKeys = Object.keys(item || {}).filter(
		(key) => typeof item[key] !== 'object',
	);
	return (
		<Descriptions layout="horizontal" bordered column={2} size="small">
			{/* Render each of the basic fieldss */}
			{fieldDescriptionKeys?.map((key) => {
				const text = item[key]?.toString() || '';
				return (
					<Descriptions.Item key={key} label={key}>
						{editable ? <Input defaultValue={text} /> : text}
					</Descriptions.Item>
				);
			})}
			{/** Render each of the item relations */}
			{relations.map(({ entity, title, path }) => {
				// If we list the relation but don't pull down the data, render nothing
				if (!item?.[entity]) {
					return (
						<Descriptions.Item key={entity} label={entity}>
							...
						</Descriptions.Item>
					);
				}
				// Cast each entry to an array for easier parsing
				const relationData = Array.isArray(item[entity])
					? item[entity]
					: [item[entity]];

				if (relationData.length === 0) {
					return null;
				}

				return (
					<Descriptions.Item key={entity} label={entity}>
						{
							// Some relations don't have a breadcrumb trail,
							// in which case just render text
						}
						{relationData
							.filter((relation) => relation[title])
							.map((relation) =>
								path ? (
									<Tag className={'tag tag--info'}>
										<Link to={`/list/${path}/${relation.id}`}>
											{relation[title]}
										</Link>
									</Tag>
								) : (
									relation[title]
								),
							)}
					</Descriptions.Item>
				);
			})}
		</Descriptions>
	);
};

const getEntityConfiguration = (entity) => {
	switch (entity) {
		case 'user':
			return {
				entity,
				title: 'username',
				path: 'users',
			};
		case 'company':
			return {
				entity,
				title: 'name',
				path: 'companies',
			};
		case 'parentCompany':
			return {
				entity,
				title: 'name',
				path: 'parentCompanies',
			};
		case 'merchant':
			return {
				entity,
				title: 'name',
				path: 'merchants',
			};
		case 'order':
			return {
				entity,
				title: 'code',
				path: 'orders',
			};
		case 'statusOrder':
			return {
				entity,
				title: 'description',
				path: null,
			};
		case 'skipTheDishesCredential':
			return {
				entity,
				title: 'id',
				path: 'skipTheDishesCredentials',
			};
		case 'moduurnCredential':
			return {
				entity,
				title: 'id',
				path: 'moduurnCredentials',
			};
		case 'ritualCredential':
			return {
				entity,
				title: 'id',
				path: 'ritualCredentials',
			};
		case 'foodoraCredential':
			return {
				entity,
				title: 'id',
				path: 'foodoraCredentials',
			};
		case 'grubhubCredential':
			return {
				entity,
				title: 'id',
				path: 'grubhubCredentials',
			};
		case 'chownowCredential':
			return {
				entity,
				title: 'id',
				path: 'chownowCredentials',
			};
		case 'freshbytesCredential':
			return {
				entity,
				title: 'id',
				path: 'freshbytesCredentials',
			};
		case 'allsetCredential':
			return {
				entity,
				title: 'id',
				path: 'allsetCredentials',
			};
		case 'ubereatsCredential':
			return {
				entity,
				title: 'id',
				path: 'ubereatsCredentials',
			};
		case 'doordashCredential':
			return {
				entity,
				title: 'id',
				path: 'doordashCredentials',
			};
		case 'chargify': {
			return {
				entity,
				title: 'subscriptionId',
				path: null,
			};
		}
		default:
			throw new Error(`Unknown entity in generic route ${entity}`);
	}
};

/**
 *
 * Given an entity and relation, return props so the generic list knows what to render
 * @param {string} entity: The name of the entity e.g. 'user
 * @param {'single' | 'many'} type: The type of relation
 * @returns {{ entity, title, path }}: Relation configuration
 */
export const relation = (entity, type = 'single') => {
	const entityConfiguration = getEntityConfiguration(entity);
	return type === 'many'
		? {
				...entityConfiguration,
				entity: entityConfiguration.path,
		  }
		: entityConfiguration;
};
export const renderColumnCell = (value, key, relations) => {
	const relationKeys = relations.map((relation) => relation.entity);
	// If the column is not a relation just return the string value
	if (relationKeys.indexOf(key) === -1) {
		switch (key) {
			case 'app':
				return (
					<Avatar
						className="app-avatar"
						key={value}
						shape="circle"
						size={'default'}
						src={`${APP_ICON_BASE_URL}/${value.toLowerCase()}.png`}
					/>
				);
			case 'active':
			case 'isActive':
				return value ? <CheckOutlined /> : <CloseOutlined />;
			case 'statusOrder':
				return (
					<Tag className={`tag--${value.replace(/\s+/g, '').toLowerCase()}`}>
						{value === ACTIVE ? 'New' : value}
					</Tag>
				);
			case 'fulfillmentType':
				if (value === PICKUP) {
					return (
						<Image preview={false} width="74px" src={pickup} alt="pickup-bag" />
					);
				}
				return (
					<Image
						preview={false}
						width="82px"
						src={delivery}
						alt="deliver-bike"
					/>
				);
			default:
				return value?.toString() || '';
		}
	}

	/**
	 * Each relation returns a result from the query,
	 * the metadata determines what value to use for pathing
	 * and which key on the query to use for displaying the tag,
	 */
	const relationValue = Array.isArray(value) ? value : [value];
	const { path, title } = relations.find((relation) => relation.entity === key);

	if (relationValue.length === 0) {
		return null;
	}
	// Map all of the relations to routes in corpa
	return relationValue
		.filter((relation) => relation?.[title])
		.map((relation) =>
			path ? (
				<Tag className={'tag tag--info'}>
					<Link to={`/list/${path}/${relation.id}`}>{relation[title]}</Link>
				</Tag>
			) : (
				relation[title]
			),
		);
};
