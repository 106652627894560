import { createCoreAxiosInstance } from 'createAxiosInstance';

import { MILES_TO_KILOMETERS_MULTIPLIER } from 'constants/constants';

export const isDeliveryServiceAvailable = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.get(
		`/company/${companyID}/delivery-service-available/`,
	);
	return response?.data;
};

export const convertMilesToKilometers = (miles) =>
	Math.round(Math.abs(miles) * MILES_TO_KILOMETERS_MULTIPLIER);
