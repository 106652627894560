import { useReducer, useCallback } from 'react';

import {
	createMenuMappingAxiosInstance,
	createOrchestratorAxiosInstance,
} from 'createAxiosInstance';

// Constants
export const SYNCHRONIZATION = 'SYNCHRONIZATION';
export const SNAPSHOTS = 'SNAPSHOTS';
export const MAIN_ITEMS = 'MAIN_ITEMS';
export const POS_MAIN_ITEMS = 'POS_MAIN_ITEMS';
export const MODIFIER_GROUPS = 'MODIFIER_GROUPS';
export const MODIFIER_LISTS = 'MODIFIER_LISTS';
export const POS_MODIFIER_GROUPS = 'POS_MODIFIER_GROUPS';
export const POS_MODIFIER_LISTS = 'POS_MODIFIER_LISTS';
export const PRODUCTION_MENU_ITEMS = 'PRODUCTION_MENU_ITEMS';
export const PRODUCTION_SUBITEMS = 'PRODUCTION_SUBITEMS';
export const ORCHESTRATOR_STATUS = 'ORCHESTRATOR_STATUS';

// Actions
const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_HAS_ERROR = 'SET_HAS_ERROR';
const SET_DATA = 'SET_DATA';

const setIsLoading = ({ store, isLoading }) => ({
	store,
	type: SET_IS_LOADING,
	isLoading,
});

const setHasError = ({ store, hasError }) => ({
	store,
	type: SET_HAS_ERROR,
	hasError,
});

const setData = ({ store, data }) => ({
	store,
	type: SET_DATA,
	data,
});

// State
const defaultStorageState = {
	isLoading: false,
	hasError: false,
	data: null,
};

const DEFAULT_STATE = {
	[SYNCHRONIZATION]: defaultStorageState,
	[ORCHESTRATOR_STATUS]: defaultStorageState,
	[SNAPSHOTS]: defaultStorageState,
	[MAIN_ITEMS]: defaultStorageState,
	[POS_MAIN_ITEMS]: defaultStorageState,
	[MODIFIER_GROUPS]: defaultStorageState,
	[MODIFIER_LISTS]: defaultStorageState,
	[POS_MODIFIER_GROUPS]: defaultStorageState,
	[POS_MODIFIER_LISTS]: defaultStorageState,
	[PRODUCTION_MENU_ITEMS]: defaultStorageState,
	[PRODUCTION_SUBITEMS]: defaultStorageState,
};

const reducer = (state, action) => {
	switch (action.type) {
		case SET_IS_LOADING: {
			return {
				...state,
				[action.store]: {
					...state[action.store],
					isLoading: action.isLoading,
				},
			};
		}
		case SET_HAS_ERROR: {
			return {
				...state,
				[action.store]: {
					...state[action.store],
					hasError: action.hasError,
				},
			};
		}
		case SET_DATA: {
			return {
				...state,
				[action.store]: {
					...state[action.store],
					data: action.data,
				},
			};
		}
		default:
			return state;
	}
};

export default function useProvideMappingDetail(companyID) {
	const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

	const fetchSynchronizationStatus = useCallback(async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			dispatch(setIsLoading({ store: SYNCHRONIZATION, isLoading: true }));
			const {
				data: { logs },
			} = await axios.post('/logs', {
				company_id: companyID,
				operation: 'get_logs',
				app: 'logs',
			});
			dispatch(setData({ store: SYNCHRONIZATION, data: logs }));
			dispatch(setIsLoading({ store: SYNCHRONIZATION, isLoading: false }));
		} catch (e) {
			console.error(e.message);
			dispatch(setHasError({ store: SYNCHRONIZATION, isLoading: true }));
		}
	}, [companyID]);

	const fetchOrchestratorStatus = useCallback(async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			dispatch(setIsLoading({ store: ORCHESTRATOR_STATUS, isLoading: true }));
			const {
				data: { status },
			} = await axios.post('/status', {
				company_id: companyID,
			});
			dispatch(setData({ store: ORCHESTRATOR_STATUS, data: status }));
			dispatch(setIsLoading({ store: ORCHESTRATOR_STATUS, isLoading: false }));
		} catch (e) {
			console.error(e.message);
			dispatch(setHasError({ store: ORCHESTRATOR_STATUS, isLoading: true }));
		}
	}, [companyID]);

	const fetchSnapshots = useCallback(async () => {
		const axios = await createMenuMappingAxiosInstance(companyID);
		try {
			dispatch(setIsLoading({ store: SNAPSHOTS, isLoading: true }));
			const {
				data: { data: snapshots },
			} = await axios.get('/versions');
			dispatch(setData({ store: SNAPSHOTS, data: snapshots.sort().reverse() }));
			dispatch(setIsLoading({ store: SNAPSHOTS, isLoading: false }));
		} catch (e) {
			console.error(e.message);
			dispatch(setHasError({ store: SNAPSHOTS, isLoading: true }));
		}
	}, [companyID]);

	const fetchRawData =
		(store, table) =>
		async ({ limit = 10, offset = 0, filters = {} } = {}) => {
			const axios = await createMenuMappingAxiosInstance(companyID);
			try {
				dispatch(setIsLoading({ store, isLoading: true }));
				const queryFilters = Object.keys(filters).reduce(
					(previous, current) => {
						return `${previous}&${current}=${filters[current]}`;
					},
					'',
				);
				const { data } = await axios.get(
					`/raw/${table}?limit=${limit}&offset=${offset}${encodeURI(
						queryFilters,
					)}`,
				);
				dispatch(setData({ store, data }));
			} catch (e) {
				dispatch(setHasError({ store, hasError: true }));
			} finally {
				dispatch(setIsLoading({ store, isLoading: false }));
			}
		};

	const fetchRawMainItems = fetchRawData(MAIN_ITEMS, 'main_items');
	const fetchRawPosMainItems = fetchRawData(POS_MAIN_ITEMS, 'pos_main_items');
	const fetchRawModifierGroups = fetchRawData(
		MODIFIER_GROUPS,
		'modifier_group',
	);
	const fetchRawModifierLists = fetchRawData(MODIFIER_LISTS, 'modifier_list');
	const fetchRawPosModifierGroups = fetchRawData(
		POS_MODIFIER_GROUPS,
		'pos_modifier_groups',
	);
	const fetchRawPosModifierLists = fetchRawData(
		POS_MODIFIER_LISTS,
		'pos_modifier_list',
	);
	const fetchRawProductionMainItems = fetchRawData(
		PRODUCTION_MENU_ITEMS,
		'published/menu_items',
	);
	const fetchRawProductionSubItems = fetchRawData(
		PRODUCTION_SUBITEMS,
		'published/subitems',
	);

	const downloadSnapshot = useCallback(
		async (snapshot) => {
			const axios = await createMenuMappingAxiosInstance(companyID);
			const {
				data: { data: snapshots },
			} = await axios.get(`/versions/${snapshot}`);

			if (snapshots.menuItems) {
				window.open(snapshots.menuItems);
			}

			if (snapshots.menuModifiers) {
				window.open(snapshots.menuModifiers);
			}

			if (snapshots.posItems) {
				window.open(snapshots.posItems);
			}

			if (snapshots.posModifiers) {
				window.open(snapshots.posModifiers);
			}
		},
		[companyID],
	);

	const captureSnapshot = useCallback(async () => {
		const axios = await createMenuMappingAxiosInstance(companyID);
		await axios.post(`/publish?snapshotOnly=true`);
		dispatch(setIsLoading({ store: SNAPSHOTS, isLoading: true }));
		setTimeout(() => fetchSnapshots(), 15000);
	}, [companyID, fetchSnapshots]);

	return {
		state,
		dispatch,
		fetchSynchronizationStatus,
		fetchSnapshots,
		fetchRawMainItems,
		fetchRawPosMainItems,
		fetchRawModifierGroups,
		fetchRawModifierLists,
		fetchRawPosModifierGroups,
		fetchRawPosModifierLists,
		fetchRawProductionMainItems,
		fetchRawProductionSubItems,
		fetchOrchestratorStatus,
		downloadSnapshot,
		captureSnapshot,
		synchronizationStatus: state[SYNCHRONIZATION],
		orchestratorStatus: state[ORCHESTRATOR_STATUS],
		snapshots: state[SNAPSHOTS],
	};
}
