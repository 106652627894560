/* eslint-disable react/display-name */
import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';
const routes = [
	{
		path: '/list/doordashCredentials',
		sideMenu: true,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
             query Q($limit: Int!, $offset: Int!) {
                 doordashCredentials(limit: $limit, offset: $offset) {
                     id,
                     displayName
                     merchant: merchantCuboh {
                         id
                         name
                     }
                     company {
                         id
                         name
                     }
                    active
                 }
              }
         `,
			...relation('doordashCredential', 'many'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
			excludeColumns: ['displayName'],
		},
		showSearch: false,
	},
	{
		path: '/list/doordashCredentials/:id',
		sideMenu: false,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
             query Q($id: Int!) {
                 doordashCredential(id: $id) {
                     id,
                     displayName
                     active
                     merchant: merchantCuboh {
                         id
                         name
                     }
                     company {
                         id
                         name
                     }
                 }
              }
         `,
			...relation('doordashCredential', 'single'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
		},
	},
];

export default routes;
