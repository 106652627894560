import { createCoreAxiosInstance } from 'createAxiosInstance';

export const updateUser = async ({ id, newUsername, newEmail }) => {
	try {
		const axios = await createCoreAxiosInstance();
		await axios.patch(`api/corpa/user/${id}`, {
			username: newUsername,
			email: newEmail,
		});
		return true;
	} catch (error) {
		alert(
			`${error?.response?.data?.username} \n ${
				error?.response?.data?.email ?? ''
			}`,
		);
	}
};
