import React, { useState, useCallback } from 'react';

import { Button, Input, Modal, Space, Form, Select } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import ResponseModal from 'menuManagement/ResponseModal';

BulkIntegrationCreation.propTypes = {};

const { Item } = Form;
const { Option } = Select;

export default function BulkIntegrationCreation() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [responseModalOpen, setResponseModalOpen] = useState(false);
	const [results, setResults] = useState({});

	const onFinish = useCallback(async (values) => {
		const merchantIds = values.merchantIds
			.split(',')
			.map((id) => Number(id.trim()));
		try {
			const axios = await createCoreAxiosInstance();
			const response = await axios.post('api/v2/bulk_create_integrations', {
				integration_name: values.integrationName,
				merchant_ids: merchantIds,
			});
			setResults(response);
			setResponseModalOpen(true);
			setIsModalOpen(false);
		} catch (error) {
			setResults({
				status: 500,
				data: {
					created: [],
					exists: [],
					failed: merchantIds,
				},
			});
			alert(error);
		}
	}, []);

	const onFinishFailed = (errorInfo) => {
		console.error(errorInfo);
		alert('Failed to create integrations');
	};

	const onCancel = () => {
		setIsModalOpen(false);
		setResponseModalOpen(false);
	};

	return (
		<>
			<Button
				type="primary"
				className="mb-10"
				onClick={() => setIsModalOpen(true)}
			>
				Create Merchant Integrations
			</Button>
			<Modal
				title="Bulk Add Integration"
				open={isModalOpen}
				footer={null}
				onCancel={onCancel}
			>
				<Form
					name="reset-password"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Space direction="vertical" className="full-width" size="small">
						<Item
							label="Integration Name"
							name="integrationName"
							rules={[
								{
									required: true,
									message: 'Integration Name is required',
								},
							]}
						>
							<Select>
								<Option value={'doordash'}>DoorDash</Option>
							</Select>
						</Item>
						<Item
							label="Merchant IDs"
							name="merchantIds"
							rules={[
								{
									required: true,
									message:
										'Merchant IDs are required (comma separated integers)',
								},
							]}
						>
							<Input />
						</Item>
						<Item>
							<Button type="primary" htmlType="submit">
								Create Integrations
							</Button>
						</Item>
					</Space>
				</Form>
			</Modal>
			<ResponseModal
				title="Create Integrations Result"
				response={results}
				responseModalVisible={responseModalOpen}
				setResponseModalVisible={setResponseModalOpen}
			/>
		</>
	);
}
