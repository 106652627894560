/* eslint-disable react/display-name */
import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';

const routes = [
	{
		path: '/list/skipTheDishesCredentials',
		sideMenu: true,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
             query Q($limit: Int!, $offset: Int!) {
                 skipTheDishesCredentials(limit: $limit, offset: $offset) {
                    id,
                    displayName
                    merchant: merchantCuboh {
                        id
                        name
                    }
                    company {
                        id
                        name
                    }
                    active
                 }
              }
         `,
			...relation('skipTheDishesCredential', 'many'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
			excludeColumns: ['displayName'],
		},
		showSearch: false,
	},
	{
		path: '/list/skipTheDishesCredentials/:id',
		sideMenu: false,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
             query Q($id: Int!) {
                 skipTheDishesCredential(id: $id) {
                    id,
                    displayName
                    active
                    merchant: merchantCuboh {
                        id
                        name
                    }
                    company {
                        id
                        name
                    }
                 }
              }
         `,
			...relation('skipTheDishesCredential', 'single'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
		},
	},
];

export default routes;
