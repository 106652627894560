import React, { useState, useCallback } from 'react';

import { Form, Input, Button, Select, Typography, Divider, Space } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { syncMenuProperty } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;
const { Text } = Typography;

SyncMenuProperty.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function SyncMenuProperty({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const onFinish = useCallback(
		async (values) => {
			const menuManagementResponse = await syncMenuProperty(
				merchantId,
				values.sourceMenuUuid,
				values.targetMenuUuid,
				values.propertyName,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse, setResponseModalVisible, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	return (
		<Form
			name="syncMenuProperty"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Space direction="vertical" size="small">
				<Text>
					This tool allows you to copy a property from items in one menu to
					another. The items are matched by their NAMES.
				</Text>
				<Text>
					Note that if there are multiple items/modifiers with the same name,
					the property will be chosen AT RANDOM. This is an irreversible action.
					Use with caution.
				</Text>
			</Space>
			<Divider />
			<Item
				label="Source UUID"
				name="sourceMenuUuid"
				rules={[{ required: true, message: 'Source menu for property' }]}
			>
				<Input />
			</Item>

			<Item
				label="Target UUID"
				name="targetMenuUuid"
				rules={[{ required: true, message: 'Target menu for property' }]}
			>
				<Input />
			</Item>

			<Item
				label="Property Name"
				name="propertyName"
				rules={[{ required: true, message: 'Property name' }]}
			>
				<Select
					style={{ width: 200 }}
					options={[
						{ value: 'image_url', label: 'Image URL' },
						{ value: 'price', label: 'Price' },
					]}
				/>
			</Item>

			<Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type="primary" htmlType="submit">
					Execute
				</Button>
				<ResponseModal
					title="Property Sync Response"
					response={response}
					responseModalVisible={responseModalVisible}
					setResponseModalVisible={setResponseModalVisible}
				/>
			</Item>
		</Form>
	);
}
