import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form } from 'antd';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import propTypes from 'prop-types';
import Pusher from 'pusher-js';

import { fixMenus } from 'menuManagement/MenuManagementHelpers';

import ResponseModal from './ResponseModal';

const { Item } = Form;

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
	cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

RemoveMenuOrphans.propTypes = {
	companyId: propTypes.string.isRequired,
	merchantId: propTypes.string.isRequired,
};

export default function RemoveMenuOrphans({ companyId, merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const [menuSelectValue, setMenuSelectValue] = useState('');

	const onFinish = useCallback(async () => {
		try {
			setResponseModalVisible(true);
			setResponse({ loading: true });
			const menuManagementResponse = await fixMenus({
				menu_uuid: menuSelectValue,
				send_notification: true,
				ignored_fields: [],
				highest_fixer_item_type: 'cuboh_category',
				strict_mode: null,
				fixer_function: 'parent_menu_map',
			});
			menuManagementResponse.data = {
				...menuManagementResponse.data,
				message: 'Running asynchronously, please wait for the result...',
			};
			setResponse(menuManagementResponse);
		} catch (e) {
			setResponseModalVisible(true);
			console.error(`Exception while accessing Menu Management - ${e}`);
			setResponse({ error: 'Exception while accessing Menu Management' });
		}
	}, [setResponse, setResponseModalVisible, menuSelectValue]);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid UUID');
	}, []);

	const channel = pusher.subscribe(
		`${process.env.REACT_APP_PUSHER_STAGE}-company-${companyId}`,
	);

	useEffect(() => {
		if (!channel) return;

		channel.unbind('MENU_FIXER_SUCCESSFUL');
		channel.unbind('MENU_FIXER_FAILED');
		channel.bind('MENU_FIXER_SUCCESSFUL', async (payload) => {
			setResponse(payload);
		});
		channel.bind('MENU_FIXER_FAILED', (payload) => {
			setResponse(payload);
		});
	}, [channel]);

	return (
		<Form
			name="removeOrphans"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Item
				label="Target Menu:"
				name="menuUuid"
				rules={[
					() => ({
						validator() {
							if (!menuSelectValue.length) {
								return Promise.reject(new Error('Select a menu.'));
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<MerchantMenuSelector
					setMenuSelectValue={setMenuSelectValue}
					merchantId={merchantId}
					selectType="single"
				/>
			</Item>
			<Button type="primary" htmlType="submit" disabled={!menuSelectValue}>
				Remove Orphaned Items
			</Button>
			<ResponseModal
				title="Orphan Removal Result"
				response={response}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
			/>
		</Form>
	);
}
