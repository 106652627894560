import { createCoreAxiosInstance } from 'createAxiosInstance';

import { ACCESS, REFRESH } from 'constants/constants';

export const login = async (values) => {
	const axiosInstance = await createCoreAxiosInstance();
	const { data } = await axiosInstance.post('/api/v2/login', values);
	return data;
};

export const handleForgotPasswordRequest = async (values) => {
	[ACCESS, REFRESH].forEach((key) => localStorage.removeItem(key));
	const axiosInstance = await createCoreAxiosInstance();
	await axiosInstance.post(`/password_reset/`, values);
};

export const fetchUserData = async () => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const {
			data: { response },
		} = await axiosInstance.get('/api/v2/user');
		if (!response.isStaff) {
			const errorMessage = 'This user is not authorized for Corpa';
			throw Error(errorMessage);
		}
		return formatAuthData(response);
	} catch (error) {
		console.error(error);
		throw new Error(error.message);
	}
};

export const formatAuthData = (data) => {
	const payload = {
		user: {
			id: data.userID,
			username: data.username,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			permissions: data.permissions,
			tokenClaims: data.tokenClaims,
			isAdmin: data.isAdmin,
			isStaff: data.isStaff,
			userDetails: data.userDetails,
		},

		proxyUserID:
			data.tokenClaims.proxy_user === undefined
				? null
				: data.tokenClaims.proxy_user,
	};

	return payload;
};

export const fetchFeatureFlags = async () => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.get('/api/v2/feature-flags');
		return data;
	} catch (error) {
		console.error(error);
		throw new Error(error.message);
	}
};
