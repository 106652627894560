import React, { useState, useEffect } from 'react';

import {
	Typography,
	Space,
	Divider,
	Select,
	Button,
	message,
	Alert,
	Modal,
} from 'antd';
import dayjs from 'dayjs';
import HoursSetter from 'menuManagement/HoursSetter';
import propTypes from 'prop-types';

import {
	getParentCompanyMenus,
	massUpdateSpecialHours,
} from 'menuManagement/MenuManagementHelpers';

MassUpdateSpecialHours.propTypes = {
	parentCompanyData: propTypes.object.isRequired,
	parentCompanyId: propTypes.string.isRequired,
	isParent: propTypes.bool,
};
export default function MassUpdateSpecialHours({
	parentCompanyData,
	parentCompanyId,
	isParent = true,
}) {
	const { Text } = Typography;

	const defaultHours = {
		[dayjs().add(1, 'day').format('YYYY-MM-DD')]: {
			ranges: [{ startTime: '09:00', endTime: '17:00' }],
			closed: false,
			open24hours: false,
		},
	};

	const [hours, setHours] = useState(defaultHours);
	const [selectedMenus, setSelectedMenus] = useState([]);
	const [allMenus, setAllMenus] = useState([]);
	const [loading, setLoading] = useState(false);
	const [responseMessage, setResponseMessage] = useState('');
	const [responseModalOpen, setResponseModalOpen] = useState(false);

	useEffect(() => {
		(async function () {
			try {
				setLoading(true);
				const menusFormattedForSelect = await getParentCompanyMenus(
					parentCompanyData,
					true,
				);
				setAllMenus(menusFormattedForSelect);
			} catch (e) {
				message.error(
					'An error occurred fetching menus. Please refresh or try again later.',
				);
				console.error(e);
			} finally {
				setLoading(false);
			}
		})();
	}, [parentCompanyData]);

	const updateHours = async () => {
		try {
			const response = await massUpdateSpecialHours(
				parentCompanyId,
				hours,
				selectedMenus,
			);
			if (response.status === 200) {
				setResponseMessage(
					`Menu hours successfully updated for ${selectedMenus}`,
				);
				setResponseModalOpen(true);
			} else throw new Error();
		} catch {
			setResponseMessage('An error occurred when updating menu hours.');
			setResponseModalOpen(true);
		}
	};

	const selectAll = () => {
		setSelectedMenus(allMenus.map((menu) => menu.uuid));
	};
	const clearSelected = () => {
		setSelectedMenus([]);
	};

	const resetHours = () => setHours(defaultHours);

	const handleModalClose = () => {
		setResponseModalOpen(false);
		setResponseMessage('');
	};

	return (
		<>
			<Space direction="vertical" size="middle">
				<Alert
					message={
						'Note that if a menu has special hours set for the selected date already, it will be overwritten by this set of hours.'
					}
				/>
				<Divider />
				<Text strong={true}>Select Menus to Apply Hours to:</Text>
				<Select
					style={{ width: '100%' }}
					mode={'multiple'}
					optionFilterProp={'label'}
					showSearch
					value={selectedMenus}
					onChange={(value) => setSelectedMenus(value)}
					loading={loading}
					placeholder={loading ? 'Loading menus' : 'Select menus'}
					options={allMenus}
				/>
				<Button loading={loading} onClick={selectAll}>
					{`Select All Menus in ${isParent ? 'Parent' : ''} Company`}
				</Button>
				<Button loading={loading} onClick={clearSelected}>
					Clear Selected
				</Button>
				<Divider />
				<Text strong={true}>
					Specify the Hours to Apply to the Selected Menus:
				</Text>
				<Alert
					message={
						'Only one special date can be set at a time. If you need to set multiple special dates, you can click "Reset Hours" and run this tool multiple times.'
					}
				/>
				<HoursSetter hours={hours} setHours={setHours} isSpecial={true} />
				<Button onClick={resetHours}>Reset Hours</Button>
				<Button
					type="primary"
					loading={loading}
					disabled={!selectedMenus.length}
					onClick={updateHours}
				>
					Execute
				</Button>
			</Space>
			<Modal
				open={responseModalOpen}
				closeIcon={null}
				onOk={handleModalClose}
				onCancel={handleModalClose}
			>
				{responseMessage}
			</Modal>
		</>
	);
}
