import React, { useState } from 'react';

import {
	Button,
	Tooltip,
	Typography,
	Row,
	Col,
	Space,
	Input,
	message,
	Modal,
	Select,
} from 'antd';
import { createOrchestratorAxiosInstance } from 'createAxiosInstance';
import IntegrationSelector from 'menuManagement/IntegrationSelector';
import propTypes from 'prop-types';

import { EXTRA_SUPPORTED_APPS_MOD_MAPPING } from 'menuManagement/menuManagementConstants';

const { Text, Title } = Typography;

BatchModifierMapping.propTypes = {
	companyId: propTypes.string.isRequired,
};

export default function BatchModifierMapping({ companyId }) {
	const [appName, setAppName] = useState('*');
	const [nameOnApp, setNameOnApp] = useState(null);
	const [mainItemName, setMainItemName] = useState(null);
	const [matched, setMatched] = useState('*');
	const [appPrice, setAppPrice] = useState(null);
	const [point, setPoint] = useState(null);
	const [isMain, setIsMain] = useState('False');
	const [isAddition, setIsAddition] = useState('False');
	const [posId, setPosId] = useState(null);
	const [priceLevel, setPriceLevel] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);

	const resetFields = () => {
		setAppName('*');
		setNameOnApp(null);
		setMainItemName(null);
		setMatched('*');
		setAppPrice(null);
		setPoint(null);
		setIsMain('False');
		setIsAddition('False');
		setPosId(null);
		setPriceLevel(null);
	};

	const batchMap = async () => {
		try {
			const axios = await createOrchestratorAxiosInstance();
			await axios.post(`/mapping/${companyId}/modifier/massupdate/item`, {
				values: {
					app_name: appName,
					company_id: `${companyId}`,
					is_addition: isAddition,
					is_main: isMain,
					main_item_name: `${mainItemName ? mainItemName : '*'}`,
					matched: matched,
					name_on_app: `${nameOnApp ? nameOnApp : '*'}`,
					point: `${point ? point : '*'}`,
					pos_id: posId,
					app_price: `${appPrice ? appPrice : '*'}`,
					price_level: `${priceLevel ? priceLevel : ''}`,
				},
			});
			message.success(`Successfully started Mass Update/Map.`);
		} catch {
			message.error('An error occurred while starting Mass Update');
		}
	};

	return (
		<>
			<Col span={18}>
				<Typography.Text strong>Mass Update/Map Modifiers:</Typography.Text>{' '}
				Mass update/map modifiers using a filter to select modifiers.
			</Col>
			<Col span={4}>
				<Button type="link" onClick={() => setModalVisible(true)}>
					Mass Update/Map Modifiers
				</Button>
			</Col>
			<Modal
				open={modalVisible}
				onCancel={() => {
					setModalVisible(false);
					resetFields();
				}}
				footer={
					<Space direction="horizontal">
						<Button onClick={() => resetFields()}>Reset Fields</Button>
						<Tooltip title={!posId && 'POS ID is required.'}>
							<Button disabled={!posId} onClick={() => batchMap()}>
								Execute
							</Button>
						</Tooltip>
					</Space>
				}
			>
				<Space direction="vertical" className="full-width">
					<Title level={4}>
						Filter Fields (Fields may be left blank for no filtering):
					</Title>
					<Row>
						<Col flex="auto">
							<Text>App Name:</Text>
						</Col>
						<Col flex={15}>
							<IntegrationSelector
								setIntegrationSelectValue={setAppName}
								integrationType={'push'}
								addCustomOptions={EXTRA_SUPPORTED_APPS_MOD_MAPPING}
								selectType={'single'}
							/>
						</Col>
					</Row>
					<Row>
						<Col flex="auto">
							<Text>Matched Status:</Text>
						</Col>
						<Col flex={15}>
							<Select
								className="full-width"
								options={[
									{ label: 'Any', value: '*' },
									{ label: 'Matched', value: 'True' },
									{ label: 'Not Matched', value: 'False' },
								]}
								value={matched}
								onChange={(value) => setMatched(value)}
							></Select>
						</Col>
					</Row>

					<Input
						addonBefore="Name on App:"
						value={nameOnApp}
						onChange={(e) => setNameOnApp(e.target.value)}
					></Input>
					<Text type="secondary" style={{ fontSize: '12px' }}>
						&quot;Name on App&quot; will filter by names containing the entered
						string.
					</Text>
					<Input
						addonBefore="Main Item Name:"
						value={mainItemName}
						onChange={(e) => setMainItemName(e.target.value)}
					></Input>
					<Input
						addonBefore="App Price:"
						value={appPrice}
						onChange={(e) => setAppPrice(e.target.value)}
					></Input>
					<Input
						addonBefore="Points:"
						value={point}
						onChange={(e) => setPoint(e.target.value)}
					></Input>
					<Title level={4}>Update Fields:</Title>
					<Row>
						<Col flex="auto">
							<Text>Is Main:</Text>
						</Col>
						<Col flex={15}>
							<Select
								className="full-width"
								//Orchestrator requires string True/False for is_main and is_addition
								options={[
									{ label: 'True', value: 'True' },
									{ label: 'False', value: 'False' },
								]}
								value={isMain}
								onChange={(value) => setIsMain(value)}
							></Select>
						</Col>
					</Row>
					<Row>
						<Col flex="auto">
							<Text>Is Addition:</Text>
						</Col>
						<Col flex={15}>
							<Select
								className="full-width"
								options={[
									{ label: 'True', value: 'True' },
									{ label: 'False', value: 'False' },
								]}
								value={isAddition}
								onChange={(value) => setIsAddition(value)}
							></Select>
						</Col>
					</Row>
					<Input
						addonBefore="POS ID (Required):"
						value={posId}
						onChange={(e) => setPosId(e.target.value)}
					></Input>
					<Input
						addonBefore="Price Level:"
						value={priceLevel}
						onChange={(e) => setPriceLevel(e.target.value)}
					></Input>
				</Space>
			</Modal>
		</>
	);
}
