import React, { useMemo } from 'react';

import { Modal, Button, Typography, Space } from 'antd';
import propTypes from 'prop-types';

ResponseModal.propTypes = {
	title: propTypes.string.isRequired,
	response: propTypes.oneOfType([propTypes.object, propTypes.string])
		.isRequired,
	setResponse: propTypes.func,
	responseModalVisible: propTypes.bool,
	setResponseModalVisible: propTypes.func,
	isPublish: propTypes.bool,
	footer: propTypes.any,
};

function structureResponse(response) {
	if (response?.status && response.data) {
		return (
			<>
				<pre>Status: {response.status}</pre>
				<pre>Body: {JSON.stringify(response.data, null, 2)}</pre>
			</>
		);
	} else if (response instanceof String) {
		return <Typography key="response">{response}</Typography>;
	} else {
		return <pre key="response">{JSON.stringify(response, null, '\t')}</pre>;
	}
}

export default function ResponseModal({
	title,
	response,
	responseModalVisible,
	setResponseModalVisible,
	footer = null,
}) {
	const processResponse = useMemo(() => {
		if (!response || response?.loading) {
			return <Typography key="response">Loading...</Typography>;
		}
		if (response instanceof Array) {
			return response.map((individualResponse) =>
				structureResponse(individualResponse),
			);
		}
		return structureResponse(response);
	}, [response]);

	return (
		<Modal
			open={responseModalVisible}
			centered
			className="show-result__modal"
			getContainer={false}
			title={`RESULT - ${title}`}
			width={1000}
			footer={
				!footer ? (
					<Button
						onClick={() => {
							setResponseModalVisible(false);
						}}
						type="primary"
					>
						OK
					</Button>
				) : (
					footer
				)
			}
			onCancel={() => setResponseModalVisible(false)}
		>
			<Space direction="vertical">{processResponse}</Space>
		</Modal>
	);
}
