import React, { useState } from 'react';

import { Card, Button, Typography, Result, message, Row } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import PropTypes from 'prop-types';

const { Text } = Typography;

ResetLoginAttempts.propTypes = {
	username: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default function ResetLoginAttempts({ id, username }) {
	const [result, setResult] = useState(false);

	const resetAttempts = async () => {
		try {
			const axiosInstance = await createCoreAxiosInstance();
			await axiosInstance.patch(`api/v2/users/${id}/access-attempts`);
			setResult(true);
		} catch (error) {
			message.error(error?.message);
		}
	};

	return (
		<Card title={`Reset Failed Login Attempts - ${username}`}>
			{result ? (
				<Result
					status="success"
					title={`Successfully Reset Login Attempts For: ${username}`}
				/>
			) : (
				<>
					<Row>
						<Text>
							If a user has entered their password and gotten locked out, this
							will reset their failed login attempts and allow them to login
							successfully
						</Text>
					</Row>
					<Row style={{ margin: '30px' }}>
						<Button block type="primary" onClick={resetAttempts}>
							Reset
						</Button>
					</Row>
				</>
			)}
		</Card>
	);
}
