import 'es6-shim';
import React from 'react';

import { ConfigProvider, message } from 'antd';
import App from 'App';
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-dom-last-location';

import { corpaTheme } from 'config/theme.config';
message.config({
	duration: 4,
	maxCount: 3,
	prefixCls: 'cuboh-message',
});

export default function Root() {
	return (
		<HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
			<Router>
				<LastLocationProvider>
					<ConfigProvider prefixCls={'cuboh'} theme={corpaTheme}>
						<App />
					</ConfigProvider>
				</LastLocationProvider>
			</Router>
		</HttpsRedirect>
	);
}
