import React from 'react';

import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';

import { updateCompany } from 'utils/company.helper';

UpdateCompany.propTypes = {
	companyID: PropTypes.number.isRequired,
	companyName: PropTypes.string,
	companyEmail: PropTypes.string,
	companyEmail2: PropTypes.string,
};
export default function UpdateCompany({
	companyID,
	companyName,
	companyEmail,
	companyEmail2,
}) {
	const onFinish = async ({
		newCompanyName,
		newCompanyEmail,
		newCompanyEmail2,
	}) => {
		const result = await updateCompany({
			companyID,
			newCompanyName,
			newCompanyEmail,
			newCompanyEmail2,
		});
		if (result === true) {
			alert('Success updating company!!');
			window.location.reload();
		}
	};
	const onFinishFailed = (errorInfo) => {
		alert(errorInfo);
	};
	return (
		<Form
			name="updateCompanyForm"
			wrapperCol={{
				span: 16,
			}}
			style={{
				minWidth: 800,
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
		>
			<Form.Item label="Name" name="newCompanyName" initialValue={companyName}>
				<Input />
			</Form.Item>

			<Form.Item
				label="Email"
				name="newCompanyEmail"
				initialValue={companyEmail}
				rules={[{ type: 'email' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Email2"
				name="newCompanyEmail2"
				initialValue={companyEmail2}
				rules={[{ type: 'email' }]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				wrapperCol={{
					offset: 8,
					span: 16,
				}}
			>
				<Button type="primary" htmlType="submit">
					Update
				</Button>
			</Form.Item>
		</Form>
	);
}
