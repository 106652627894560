import React, {
	useEffect,
	useState,
	useCallback,
	useContext,
	createContext,
} from 'react';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
	Alert,
	Form,
	Switch,
	InputNumber,
	Typography,
	Row,
	Col,
	Divider,
	Select,
	message,
} from 'antd';
import PropTypes from 'prop-types';

import {
	DEFAULT_AVAILABLE_RESPONSE,
	KILOMETERS,
	MILES,
} from 'constants/storefront.constants';

import { updateCompany } from 'utils/helpers';
import { isDeliveryServiceAvailable } from 'utils/storefront.helper';

import StorefrontDetail from 'components/Company/Storefront/StorefrontDetail';
const { Item: FormItem } = Form;
const { Title, Text } = Typography;
const { Option } = Select;

StorefrontDelivery.Context = createContext();

StorefrontDelivery.propTypes = {
	companyID: PropTypes.string.isRequired,
	storefrontDeliveryActive: PropTypes.bool.isRequired,
	storefrontSelfDeliveryActive: PropTypes.bool.isRequired,
	storefrontSelfDeliveryFee: PropTypes.string,
	storefrontSelfDeliveryMaximumDistanceKilometers: PropTypes.string,
	setSubmitDisabled: PropTypes.func,
};

export default function StorefrontDelivery({
	companyID,
	storefrontDeliveryActive,
	storefrontSelfDeliveryActive,
	storefrontSelfDeliveryMaximumDistanceKilometers,
	storefrontSelfDeliveryFee,
	setSubmitDisabled = () => {},
}) {
	const [storefrontDeliveryToggleActive, setStorefrontDeliveryToggleActive] =
		useState(storefrontDeliveryActive);
	const [
		storefrontSelfDeliveryToggleActive,
		setStorefrontSelfDeliveryToggleActive,
	] = useState(storefrontSelfDeliveryActive);
	const [togglesLoading, setTogglesLoading] = useState(
		!storefrontDeliveryActive,
	);
	const { setSelfDeliveryDistanceType, selfDeliveryDistanceType } = useContext(
		StorefrontDetail.Context,
	);

	useEffect(() => {
		setSubmitDisabled(togglesLoading);
	}, [togglesLoading, setSubmitDisabled]);

	const select = (
		<Select
			defaultValue={selfDeliveryDistanceType}
			onSelect={(value) => setSelfDeliveryDistanceType(value)}
		>
			<Option value={KILOMETERS}>km</Option>
			<Option value={MILES}>mi</Option>
		</Select>
	);

	return (
		<StorefrontDelivery.Context.Provider
			value={{
				storefrontDeliveryToggleActive,
				setStorefrontDeliveryToggleActive,
				storefrontSelfDeliveryToggleActive,
				setStorefrontSelfDeliveryToggleActive,
				togglesLoading,
				setTogglesLoading,
			}}
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Title level={4} className="mt-0">
						Delivery Settings
					</Title>
				</Col>
				<Col xs={24} md={8}>
					<Text>Delivery: </Text>
					<StorefrontDelivery.DeliveryToggle companyID={companyID} />
				</Col>
				<Col xs={24} md={8}>
					<Text>Self Delivery: </Text>
					<StorefrontDelivery.SelfDeliveryToggle companyID={companyID} />
				</Col>

				{storefrontSelfDeliveryToggleActive && (
					<>
						<Col xs={24} md={8}>
							<FormItem
								label="Self Delivery Fee"
								name="storefront_self_delivery_fee"
								initialValue={storefrontSelfDeliveryFee}
								className="mb-10"
							>
								<InputNumber
									className="company-storefront__number-input-field"
									prefix="$"
									precision={2}
									min={0}
								/>
							</FormItem>
						</Col>
						<Col xs={24} sm={10}>
							<FormItem
								name="storefront_self_delivery_maximum_distance_kilometers"
								label="Max Self Delivery Distance"
								initialValue={storefrontSelfDeliveryMaximumDistanceKilometers}
							>
								<InputNumber
									className="company-storefront__number-input-field"
									addonAfter={select}
								/>
							</FormItem>
						</Col>
					</>
				)}
				<Divider />
			</Row>
		</StorefrontDelivery.Context.Provider>
	);
}

StorefrontDelivery.DeliveryToggle = function DeliveryToggle({ companyID }) {
	const {
		storefrontDeliveryToggleActive,
		setStorefrontDeliveryToggleActive,
		setStorefrontSelfDeliveryToggleActive,
		togglesLoading,
		setTogglesLoading,
	} = useContext(StorefrontDelivery.Context);
	const [response, setResponse] = useState(DEFAULT_AVAILABLE_RESPONSE);

	useEffect(() => {
		// When active, abort any loading & enable the toggle
		if (storefrontDeliveryToggleActive) {
			setTogglesLoading(false);
			setResponse({ ...DEFAULT_AVAILABLE_RESPONSE, available: true });
			return;
		}

		// Otherwise check if the location supports delivery
		(async () => {
			try {
				setTogglesLoading(true);
				const response = await isDeliveryServiceAvailable(companyID);
				setResponse(response);
			} catch (e) {
				console.error(e);
				setResponse({
					available: false,
					message: 'Unable to determine if delivery service is available.',
				});
			} finally {
				setTogglesLoading(false);
			}
		})();
	}, [companyID, storefrontDeliveryToggleActive, setTogglesLoading]);

	const onClick = useCallback(() => {
		(async () => {
			try {
				setTogglesLoading(true);
				const isTurningDeliveryOff = !storefrontDeliveryToggleActive === false;
				await updateCompany({
					companyID,
					data: {
						storefront_delivery_active: !storefrontDeliveryToggleActive,
					},
				});
				setStorefrontDeliveryToggleActive(!storefrontDeliveryToggleActive);
				if (isTurningDeliveryOff) {
					setStorefrontSelfDeliveryToggleActive(false);
				}
			} catch (e) {
				message.error(`error: ${e}`);
			} finally {
				setTogglesLoading(false);
			}
		})();
	}, [
		companyID,
		storefrontDeliveryToggleActive,
		setStorefrontDeliveryToggleActive,
		setStorefrontSelfDeliveryToggleActive,
		setTogglesLoading,
	]);

	if (!response.available && !togglesLoading) {
		return (
			<Alert
				message="Delivery Service Unavailable"
				description={response.message}
				type="warning"
				showIcon
			/>
		);
	}

	return (
		<Switch
			checkedChildren={<CheckOutlined className="switch-icon" />}
			unCheckedChildren={<CloseOutlined className="switch-icon" />}
			checked={storefrontDeliveryToggleActive}
			loading={togglesLoading}
			disabled={!response.available && !storefrontDeliveryToggleActive} // always able to turn the toggle off
			onClick={onClick}
		/>
	);
};
StorefrontDelivery.DeliveryToggle.propTypes = {
	companyID: PropTypes.string.isRequired,
	storefrontDeliveryActive: PropTypes.bool.isRequired,
};

StorefrontDelivery.SelfDeliveryToggle = function SelfDeliveryToggle({
	companyID,
}) {
	const {
		setStorefrontDeliveryToggleActive,
		storefrontSelfDeliveryToggleActive,
		setStorefrontSelfDeliveryToggleActive,
		togglesLoading,
		setTogglesLoading,
	} = useContext(StorefrontDelivery.Context);

	const onClick = useCallback(() => {
		(async () => {
			try {
				setTogglesLoading(true);

				const isTurningSelfDeliveryOff =
					!storefrontSelfDeliveryToggleActive === false;
				const data = {
					storefront_self_delivery_active: !storefrontSelfDeliveryToggleActive,
					storefront_delivery_active: !storefrontSelfDeliveryToggleActive,
				};

				if (isTurningSelfDeliveryOff) {
					try {
						const response = await isDeliveryServiceAvailable(companyID);
						data.storefront_delivery_active = response.available;
					} catch (e) {
						console.error(e);
					}
				}

				await updateCompany({
					companyID,
					data: data,
				});

				setStorefrontSelfDeliveryToggleActive(
					!storefrontSelfDeliveryToggleActive,
				);
				if (isTurningSelfDeliveryOff) {
					setStorefrontDeliveryToggleActive(data.storefront_delivery_active);
				} else {
					setStorefrontDeliveryToggleActive(
						!storefrontSelfDeliveryToggleActive,
					);
				}
			} catch (e) {
				message.error(`error: ${e}`);
			} finally {
				setTogglesLoading(false);
			}
		})();
	}, [
		companyID,
		setStorefrontDeliveryToggleActive,
		storefrontSelfDeliveryToggleActive,
		setTogglesLoading,
		setStorefrontSelfDeliveryToggleActive,
	]);

	return (
		<Switch
			checkedChildren={<CheckOutlined className="switch-icon" />}
			unCheckedChildren={<CloseOutlined className="switch-icon" />}
			checked={storefrontSelfDeliveryToggleActive}
			loading={togglesLoading}
			onClick={onClick}
		/>
	);
};
StorefrontDelivery.SelfDeliveryToggle.propTypes = {
	companyID: PropTypes.string.isRequired,
};
