/* eslint-disable react/display-name */
import React from 'react';

import { Card } from 'antd';
import MenuManagementAdminParentCompany from 'menuManagement/MenuManagementAdminParentCompany';

import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';

const routes = [
	{
		path: '/list/parentCompanies',
		sideMenu: true,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
             query AllParentCompanies($limit: Int!, $offset: Int!, $search: String) {
                 parentCompanies(limit: $limit, offset: $offset, search: $search) {
                     id,
                     name
                     companyUuid,
                     active,
                     user {
                         id
                         username
                     }
                     companies {
                         id
                         name
                     }
                 }
              }
         `,
			relations: [relation('user', 'single'), relation('company', 'many')],
			...relation('parentCompany', 'many'),
			excludeColumns: ['companyUuid', 'companies'],
		},
	},
	{
		path: '/list/parentCompanies/:id',
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
             query ParentCompany($id: Int!) {
                 parentCompany(id: $id) {
                    id
                    name
                    user {
                        id
                        username
                    }
                     companies {
                        id
                        name
                        merchants {
                            id
                            name
                            doordashCredentials: doordash {
                                id
                            }
                            moduurnCredentials: moduurn {
                                id
                            }
                            ritualCredentials: ritual {
                                id
                            }
                            grubhubCredentials: grubhub {
                                id
                            }
                            skipTheDishesCredentials: skip {
                                id
                            }
                            chownowCredentials: chownow {
                                id
                            }
                            freshbytesCredentials: freshbytes {
                                id
                            }
                            allsetCredentials: allset {
                                id
                            }
                            ubereatsCredentials: credential {
                                id
                            }
                        }
                    }
                 }
              }
         `,
			relations: [relation('user', 'single'), relation('company', 'many')],
			...relation('parentCompany', 'single'),
			extra: (detail) => (
				<Card title="Menu Management Admin Panel">
					<MenuManagementAdminParentCompany
						companyId={detail?.id}
						companyData={detail}
					/>
				</Card>
			),
		},
	},
];

export default routes;
