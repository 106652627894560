/* eslint-disable react/display-name */
import CredentialRoutes from 'components/Generic/routes/credential.routes';
import GenericRoutes from 'components/Generic/routes/index.routes';
import Loader from 'components/Loader';

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		container: Loader(() => import('containers/Dashboard')),
		layout: 'dashboard',
	},
	{
		name: 'Generic',
		layout: 'List',
		routes: GenericRoutes,
	},
	{
		name: 'Credentials',
		layout: 'List',
		routes: CredentialRoutes,
	},
];

export default routes;
