import React, { useState, useCallback, useEffect } from 'react';

import { Form, Select, Typography, Space, Button, Modal, message } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import {
	buildBulkPublishObject,
	getParentCompanyMenus,
	bulkPublish,
} from 'menuManagement/MenuManagementHelpers';

import PublishResponse from './PublishResponse';

const { Item } = Form;
const { Text } = Typography;

PublishMenuMultiple.propTypes = {
	parentCompanyData: propTypes.object.isRequired,
	parentCompanyId: propTypes.string.isRequired,
};

export default function PublishMenuMultiple({
	parentCompanyId,
	parentCompanyData,
}) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [publishResponse, setPublishResponse] = useState({});
	const [publishModalVisible, setPublishModalVisible] = useState(false);
	const [buildResponse, setBuildResponse] = useState(false);
	const [allMenus, setAllMenus] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const onClickPublish = async () => {
		message.success(`Started publishing menus, please wait...`);
		const result = await bulkPublish(buildResponse?.data);
		setResponseModalVisible(false);
		setPublishResponse(result);
		setPublishModalVisible(true);
	};

	const onFinish = async (values) => {
		setIsLoading(true);
		setResponseModalVisible(true);
		const displayFormattedMenus = await buildBulkPublishObject(
			values.menuUuids,
			'all',
			true,
		);
		setBuildResponse(displayFormattedMenus);
		setPublishModalVisible(false);
		setIsLoading(false);
	};

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	const handleCancel = useCallback(() => {
		setAllMenus([]);
		setResponseModalVisible(false);
		setPublishResponse({});
		setBuildResponse(false);
		setPublishModalVisible(false);
	}, []);

	useEffect(() => {
		(async function () {
			setIsLoading(true);
			try {
				const menusFormattedForSelect = await getParentCompanyMenus(
					parentCompanyData,
				);
				setAllMenus(menusFormattedForSelect);
			} catch (e) {
				message.error(
					'An error occurred fetching menus. Please refresh or try again later.',
				);
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [parentCompanyData]);

	return (
		<>
			<Space direction="vertical" style={{ width: 600 }}>
				<Text strong>Publish all selected menus</Text>
				<Text>
					Select menus from the merchants in this parent company (
					{parentCompanyId}). They will be published to all apps which are
					linked to those menus!
				</Text>
				<Form
					name="publishMenuMultiple"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Item
						label="Menus to Push"
						name="menuUuids"
						rules={[{ required: true, message: 'Select at least one menu' }]}
					>
						<Select
							style={{ width: '100%' }}
							mode={'multiple'}
							optionFilterProp={'label'}
							showSearch
							loading={isLoading}
							placeholder={isLoading ? 'Loading menus' : 'Select menus'}
							options={allMenus}
						/>
					</Item>
					<Item>
						<Button type="primary" htmlType="submit">
							Execute
						</Button>
					</Item>
				</Form>
			</Space>
			<ResponseModal
				title="Menus to be published:"
				response={buildResponse}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
				isPublish={true}
				footer={
					<Button
						disabled={buildResponse.status !== 200}
						onClick={onClickPublish}
					>
						Publish
					</Button>
				}
			/>
			<Modal
				open={publishModalVisible}
				onCancel={handleCancel}
				onOk={handleCancel}
				title="Menus sent to publish:"
			>
				<PublishResponse publishResult={publishResponse} />
			</Modal>
		</>
	);
}
