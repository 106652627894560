import React from 'react';

import { Avatar } from 'antd';

import { APP_ICON_BASE_URL } from 'constants/constants';
export const renderMappingCell = (key, value) => {
	switch (key) {
		case 'app_name':
			return (
				<Avatar
					className="app-avatar"
					key={key}
					shape="circle"
					size={'default'}
					src={`${APP_ICON_BASE_URL}/${value.toLowerCase()}.png`}
				/>
			);

		default:
			return value?.toString() || '';
	}
};
