import { useEffect, useReducer, useCallback, useMemo } from 'react';

import {
	setIsLoading,
	setError,
	setData,
	setFeatureFlags,
} from 'actions/auth.actions';

import { reducer, DEFAULT_STATE } from 'reducers/auth.reducer';

import * as authHelper from 'utils/auth.helper';

import useAuthToken from 'hooks/useAuthToken';

// Provider hook that creates auth object and handles state
export default function useProvideAuth() {
	const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

	const { user } = state;

	const getUserData = useCallback(async () => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			dispatch(setIsLoading({ isLoading: true }));
			const newUserData = await authHelper.fetchUserData();
			const featureFlags = await authHelper.fetchFeatureFlags();
			dispatch(setData({ data: newUserData }));
			dispatch(setFeatureFlags({ featureFlags }));
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		} finally {
			dispatch(setIsLoading({ isLoading: false }));
		}
	}, []);

	const onLogout = useCallback(() => {
		dispatch(setData({ data: DEFAULT_STATE }));
	}, []);

	const { getAccessToken, login, logout } = useAuthToken({
		refreshOnPageLoad: true,
		keepTokenAlive: true,
		onLogin: getUserData,
		onLogout: onLogout,
	});

	useEffect(() => {
		let isSubscribed = true;
		const missingAccessToken = !getAccessToken() || !window;

		(async function () {
			if (isSubscribed) {
				try {
					if (missingAccessToken) {
						return;
					}
					await getUserData();
				} catch (error) {
					console.error(error);
				}
			}
		})();

		return () => {
			isSubscribed = false;
		};
	}, [getUserData, getAccessToken]);

	/**
	 * Check whether the user has permission to perfrom an action
	 * @param permission: String permission constant from auth.constants
	 *
	 * @returns if user has permission, default to false for no user in state
	 */
	const hasPermission = useCallback(
		(permission) => {
			if (!user) {
				return false;
			}

			return user.permissions.includes(permission);
		},
		[user],
	);

	const flags = useMemo(() => user?.flags || {}, [user]);

	return {
		...state,
		getUserData,
		hasPermission,
		login,
		logout,
		flags,
		userMeta: state?.userDetails?.userMeta,
		isGuest: false,
	};
}
