import React, { useState } from 'react';

import { Button, Typography, Space } from 'antd';
import { createMenuManagementAxiosInstance } from 'createAxiosInstance';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import propTypes from 'prop-types';

import ResponseModal from './ResponseModal';

const { Text } = Typography;

PublishMenu.propTypes = {
	merchantId: propTypes.string.isRequired,
	companyId: propTypes.string.isRequired,
};

export default function PublishMenu({ merchantId, companyId }) {
	const [publishResponseModalVisible, setPublishResponseModalVisible] =
		useState(false);
	const [buildModalVisible, setBuildModalVisible] = useState(false);
	const [menuSelectValue, setMenuSelectValue] = useState('');
	const [buildResponse, setBuildResponse] = useState({});
	const [publishResponse, setPublishResponse] = useState({});

	const buildPublishBody = async () => {
		const axios = await createMenuManagementAxiosInstance();
		const response = await axios.get(
			`v2/company/${companyId}/merchant/${merchantId}/menu/${menuSelectValue}/push/build`,
		);
		setBuildResponse(response);
		setBuildModalVisible(true);
	};

	const publishMenu = async () => {
		setBuildModalVisible(false);
		const axios = await createMenuManagementAxiosInstance();
		setPublishResponse(
			await axios.post(
				`v2/company/${companyId}/merchant/${merchantId}/menu/push`,
				buildResponse.data,
			),
		);
		setPublishResponseModalVisible(true);
	};

	return (
		<Space direction="vertical" style={{ width: 600 }}>
			<Text>Publish a menu:</Text>
			<MerchantMenuSelector
				setMenuSelectValue={setMenuSelectValue}
				merchantId={merchantId}
				selectType="single"
			/>
			<Button
				onClick={buildPublishBody}
				disabled={!menuSelectValue}
				type="primary"
			>
				Publish
			</Button>
			<ResponseModal
				title={'Click OK to Publish These Menus:'}
				responseModalVisible={buildModalVisible}
				setResponseModalVisible={publishMenu}
				response={buildResponse}
			/>
			<ResponseModal
				title={'Published Menus'}
				responseModalVisible={publishResponseModalVisible}
				setResponseModalVisible={setPublishResponseModalVisible}
				response={publishResponse}
			/>
		</Space>
	);
}
