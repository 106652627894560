import React, { useState, useCallback } from 'react';

import { Form, Input, Button, Typography, Space } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { inspectMenuManagementItem } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;
const { Text } = Typography;

InspectMenuManagementItem.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function InspectMenuManagementItem({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const onFinish = useCallback(
		async (values) => {
			const menuManagementResponse = await inspectMenuManagementItem(
				merchantId,
				values,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse, setResponseModalVisible, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	return (
		<Space direction="vertical" style={{ width: 600 }}>
			<Text>
				Paste in an Item UUID to see the details of the item and information
				regarding its place in the menu
			</Text>
			<Form
				name="inspectMenuManagementItem"
				labelCol={{ span: 5 }}
				wrapperCol={{ span: 18 }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Item
					label="Item UUID"
					name="itemUuid"
					rules={[{ required: true, message: 'Item UUID to inspect' }]}
				>
					<Input />
				</Item>

				<Item wrapperCol={{ offset: 5, span: 16 }}>
					<Button type="primary" htmlType="submit">
						Execute
					</Button>
					<ResponseModal
						title="Inspected Item Result"
						response={response}
						responseModalVisible={responseModalVisible}
						setResponseModalVisible={setResponseModalVisible}
					/>
				</Item>
			</Form>
		</Space>
	);
}
