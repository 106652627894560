/**
 * Given an array of items, normalize them by a property ('key')
 * @example
 *  normalizeByKey([{ id: 'x', name: 'foo' }], 'id') => {x: {name: 'foo'}}
 *
 * @param items: Array of items to transform
 * @param key: The key to normalize each item by
 * @param transformItem: (Optional) Callback to perform data transform on item
 * @returns Object with keys of each property 'key' in item
 */
export const normalizeByKey = (
	items,
	key,
	transformItem = (item) => ({ ...item }),
) => {
	return items.reduce((previous, current) => {
		previous[current[key]] = transformItem(current);
		return previous;
	}, {});
};

export const alphabeticalCompareForSorting = (string, string2) => {
	if (string.label.toLowerCase() < string2.label.toLowerCase()) {
		return -1;
	}
	if (string.label.toLowerCase() < string2.label.toLowerCase()) {
		return -1;
	}
	return 0;
};

export const formatURL = (url, queryParams = {}) => {
	const queryParamString = Object.keys(queryParams)
		.filter(
			(param) =>
				queryParams[param] !== null && queryParams[param] !== undefined,
		)
		.map((param) => `${param}=${queryParams[param]}`)
		.join('&');

	return queryParamString.length > 0 ? `${url}?${queryParamString}` : url;
};

export const stringToSnakeCase = (string) => {
	return string
		.replace(/\W+/g, ' ')
		.split(/ |\B(?=[A-Z])/)
		.map((word) => word.toLowerCase())
		.join('_');
};

export const snakeToCamelCase = (snakeCaseString) =>
	snakeCaseString.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

export const cleanEmptyProperties = (object) => {
	Object.entries(object).forEach(([k, v]) => {
		if (v && typeof v === 'object') {
			cleanEmptyProperties(v);
		}
		if (
			(v && typeof v === 'object' && !Object.keys(v).length) ||
			v === null ||
			v === undefined
		) {
			if (Array.isArray(object)) {
				object.splice(k, 1);
			} else {
				delete object[k];
			}
		}
	});
	return object;
};
