import axios from 'axios';

export async function createCoreAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_CORE_SERVER_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem('access')
				? 'Bearer ' + localStorage.getItem('access')
				: null,
		},
	});
}

export async function createMenuMappingAxiosInstance(companyID) {
	return axios.create({
		baseURL: `${process.env.REACT_APP_MENU_MAPPER_URL}/V2/company/${companyID}`,
		headers: {
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem('access')
				? 'Bearer ' + localStorage.getItem('access')
				: null,
		},
	});
}

export async function createMenuMappingV2AxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_MENU_MAPPING_V2_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem('access')
				? 'Bearer ' + localStorage.getItem('access')
				: null,
		},
	});
}

export async function createOrchestratorAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_ORCHESTRATOR_URL,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Api-Key': process.env.REACT_APP_ORCHESTRATOR_KEY,
		},
	});
}

export async function createAlertBannerAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_ALERT_SERVER_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem('access')
				? 'Bearer ' + localStorage.getItem('access')
				: null,
		},
	});
}

export async function createMenuManagementAxiosInstance() {
	return axios.create({
		baseURL: process.env.REACT_APP_MENU_MANAGEMENT_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem('access')
				? 'Bearer ' + localStorage.getItem('access')
				: null,
		},
		validateStatus: () => true,
	});
}
