import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import PropTypes from 'prop-types';

import { getSupportedIntegrations } from 'menuManagement/MenuManagementHelpers';

IntegrationSelector.propTypes = {
	setIntegrationSelectValue: PropTypes.func.isRequired,
	integrationType: PropTypes.string,
	selectType: PropTypes.string,
	addCustomOptions: PropTypes.array,
	makeAllCaps: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default function IntegrationSelector({
	setIntegrationSelectValue,
	integrationType,
	selectType,
	addCustomOptions,
	makeAllCaps,
	disabled,
}) {
	const [allIntegrations, setAllIntegrations] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async function () {
			setIsLoading(true);
			try {
				const supportedIntegrations = await getSupportedIntegrations(
					integrationType,
				);
				const integrationList =
					supportedIntegrations?.supported_integrations?.map((integration) => ({
						label: integration.readable_app_name,
						value: integration.integration_name,
					}));

				if (makeAllCaps) {
					integrationList.forEach((integration) => {
						integration.value = integration.value.toUpperCase();
					});
				}
				if (addCustomOptions) {
					for (const option of addCustomOptions) {
						if (
							!integrationList.some(
								(integration) => integration.value === option.value,
							)
						)
							integrationList.unshift(option);
					}
				}

				setAllIntegrations(integrationList);
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [integrationType, addCustomOptions, makeAllCaps]);

	return (
		<Select
			mode={selectType || 'single'}
			optionFilterProp={'label'}
			showSearch
			showArrow
			loading={isLoading}
			placeholder={'Select from integrations...'}
			options={allIntegrations}
			onChange={(value) => setIntegrationSelectValue(value)}
			disabled={disabled}
		/>
	);
}
