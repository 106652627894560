import CompanyRoutes from 'components/Generic/routes/company.routes';
import MerchantRoutes from 'components/Generic/routes/merchant.routes';
import OrderRoutes from 'components/Generic/routes/order.routes';
import ParentCompanyRoutes from 'components/Generic/routes/parentCompany.routes';
import UserRoutes from 'components/Generic/routes/user.routes';

/** Barrel file to bind all of the generic routes together into one export */
const routes = [
	...ParentCompanyRoutes,
	...CompanyRoutes,
	...MerchantRoutes,
	...OrderRoutes,
	...UserRoutes,
];

export default routes;
