import React from 'react';

import { Tabs, Card } from 'antd';
import PropTypes from 'prop-types';

import EditUser from './EditUser';
import ResetLoginAttempts from './ResetLoginAttempts';

UserDetail.propTypes = {
	username: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};
export default function UserDetail({ id, username, email }) {
	const items = [
		{
			label: 'Edit User',
			key: 'editUser',
			children: <EditUser id={id} email={email} username={username} />,
		},
		{
			label: 'Reset Login Attempts',
			key: 'resetLogin',
			children: <ResetLoginAttempts id={id} username={username} />,
		},
	];
	return (
		<Card style={{ minWidth: 750 }}>
			<Tabs items={items} tabPosition="left" destroyInactiveTabPane />
		</Card>
	);
}
