import React, { useState, useCallback } from 'react';

import {
	FormOutlined,
	CheckCircleOutlined,
	EditOutlined,
	CloseCircleOutlined,
	CloudSyncOutlined,
} from '@ant-design/icons';
import {
	Divider,
	Form,
	Input,
	Button,
	Modal,
	Spin,
	Typography,
	Space,
} from 'antd';

import { checkPublishStatus } from 'menuManagement/MenuManagementHelpers';

import { INTEGRATION_STATUS } from './menuManagementConstants';

const { Item } = Form;
const { Text } = Typography;

export default function PublishStatusChecker() {
	const [mainModalVisible, setMainModalVisible] = useState(false);
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const [loading, setLoading] = useState(false);
	const onFinish = useCallback(async (values) => {
		try {
			setLoading(true);
			const allMenus = values.menuIds.split(',').map((id) => id.trim());
			const menuManagementResponse = await checkPublishStatus(allMenus);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	}, []);

	const onCancel = () => {
		setResponseModalVisible(false);
		setResponse(false);
		setMainModalVisible(false);
		setLoading(false);
	};

	const iconMap = {
		PUBLISHED: (
			<CheckCircleOutlined
				style={{ color: INTEGRATION_STATUS.PUBLISHED, fontSize: '22px' }}
			/>
		),
		IN_PROGRESS: (
			<CloudSyncOutlined
				style={{ color: INTEGRATION_STATUS.IN_PROGRESS, fontSize: '22px' }}
			/>
		),
		FAILED: (
			<CloseCircleOutlined
				style={{ color: INTEGRATION_STATUS.FAILED, fontSize: '22px' }}
			/>
		),
		MODIFIED: (
			<EditOutlined
				style={{ color: INTEGRATION_STATUS.MODIFIED, fontSize: '22px' }}
			/>
		),
	};

	const formatResponse = (raw_response) => {
		let formattedResponse = [];
		for (let menuId in raw_response) {
			let integrationsArray = [];
			if (!raw_response[menuId].integrations) {
				formattedResponse.push(
					<Space direction="vertical">
						<Text strong={true}>{`${menuId} - ${raw_response[menuId]}`}</Text>
						<Divider />
					</Space>,
				);
			} else {
				for (let integration of raw_response[menuId].integrations) {
					integrationsArray.push(
						<Space direction="horizontal">
							<Text strong={true}>
								{iconMap[integration.status]} {integration.integration}
							</Text>
							<Text>Last published:{integration.publish_date}</Text>
						</Space>,
					);
				}
				formattedResponse.push(
					<Space direction="vertical">
						<Space direction="vertical">
							<Space direction="horizontal">
								<Text strong={true}>
									{`${raw_response[menuId].merchant_id} - ${raw_response[menuId].menu_name}`}
								</Text>
								<Text>({menuId})</Text>
							</Space>
							<Space direction="vertical">{integrationsArray}</Space>
						</Space>
						<Divider />
					</Space>,
				);
			}
		}
		return formattedResponse;
	};

	return (
		<>
			<Button
				type="primary"
				icon={<FormOutlined />}
				onClick={() => {
					setMainModalVisible(true);
					setResponseModalVisible(false);
					setResponse(false);
				}}
			>
				Check Publish Status
			</Button>
			<Modal
				title="Check Publish Status"
				open={mainModalVisible}
				width={800}
				onCancel={onCancel}
				footer={<Button onClick={onCancel}>Cancel</Button>}
			>
				{responseModalVisible && (
					<Space direction="vertical">
						{formatResponse(response.publish_status)}
					</Space>
				)}
				{loading ? (
					<Spin />
				) : (
					!responseModalVisible && (
						<Form
							name="checkPublishStatus"
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 16 }}
							onFinish={onFinish}
							autoComplete="off"
						>
							<Item
								label="Menu IDs (comma-separated)"
								name="menuIds"
								rules={[
									{
										required: true,
										message: 'List of Menu IDs is required',
									},
									{
										validator: (_, value) => {
											if (!(value && value.length)) {
												return;
											}
											const menuIds = value.split(',').map((id) => id.trim());
											for (let menuId of menuIds) {
												if (!menuId) {
													return Promise.reject(
														'Enter a valid list of Menu UUIDs, separated by commas',
													);
												}
											}
											return Promise.resolve();
										},
									},
								]}
							>
								<Input className="meta-update-inputs" />
							</Item>
							<Item wrapperCol={{ offset: 18 }}>
								<Button type="primary" htmlType="submit">
									Execute
								</Button>
							</Item>
						</Form>
					)
				)}
			</Modal>
		</>
	);
}
