import React, { useCallback, useEffect, useState } from 'react';

import {
	Button,
	Space,
	Form,
	Select,
	Typography,
	Divider,
	Checkbox,
} from 'antd';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import propTypes from 'prop-types';
import Pusher from 'pusher-js';

import { fixMenus } from 'menuManagement/MenuManagementHelpers';

import ResponseModal from './ResponseModal';

const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
	cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

RunMenuFixer.propTypes = {
	companyId: propTypes.string.isRequired,
	merchantId: propTypes.string.isRequired,
};

export default function RunMenuFixer({ companyId, merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const [menuSelectValue, setMenuSelectValue] = useState('');

	const onFinish = useCallback(async () => {
		try {
			setResponseModalVisible(true);
			setResponse({ loading: true });
			const menuManagementResponse = await fixMenus({
				menu_uuid: menuSelectValue,
				send_notification: true,
				ignored_fields: [],
				highest_fixer_item_type: 'cuboh_category',
				strict_mode: null,
				fixer_function: 'fix_menu',
			});
			menuManagementResponse.data = {
				...menuManagementResponse.data,
				message: 'Running asynchronously, please wait for the result...',
			};
			setResponse(menuManagementResponse);
		} catch (e) {
			setResponseModalVisible(true);
			console.error(`Exception while accessing Menu Management - ${e}`);
			setResponse({ error: 'Exception while accessing Menu Management' });
		}
	}, [setResponse, setResponseModalVisible, menuSelectValue]);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid UUID');
	}, []);

	const channel = pusher.subscribe(
		`${process.env.REACT_APP_PUSHER_STAGE}-company-${companyId}`,
	);

	useEffect(() => {
		if (!channel) return;

		channel.unbind('MENU_FIXER_SUCCESSFUL');
		channel.unbind('MENU_FIXER_FAILED');
		channel.bind('MENU_FIXER_SUCCESSFUL', async (payload) => {
			setResponse(payload);
		});
		channel.bind('MENU_FIXER_FAILED', (payload) => {
			setResponse(payload);
		});
	}, [channel]);

	return (
		<Form
			name="runMenuFixer"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Space direction="vertical" size="small">
				<Text>
					This tool optimises menus by combining items which ar identical into
					one record. It should have no visible effect on the menu structure in
					the UI, but will ensure that the minimum number of records is stored.
				</Text>
				<Text>
					Top Level of Fix: only items below this level will be merged
				</Text>
				<Text>
					Strict Mode: Only the POS ID will be considered (do not use for
					non-POS-sourced menus)
				</Text>
			</Space>
			<Divider />
			<Item
				label="Target Menu:"
				name="menuUuid"
				rules={[
					() => ({
						validator() {
							if (!menuSelectValue.length) {
								return Promise.reject(new Error('Select a menu.'));
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<MerchantMenuSelector
					setMenuSelectValue={setMenuSelectValue}
					merchantId={merchantId}
					selectType="single"
				/>
			</Item>
			<Item
				label="Top Level of Fix:"
				name="highestFixerItemType"
				rules={[
					() => ({
						validator() {
							if (!menuSelectValue.length) {
								return Promise.reject(new Error('Select a level.'));
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Select>
					<Option value="cuboh_category">Category</Option>
					<Option value="cuboh_item">Item</Option>
					<Option value="cuboh_modifier_group">Modifier Group</Option>
					<Option value="cuboh_modifier">Modifier</Option>
				</Select>
			</Item>
			<Item label="Strict Mode:" name="strictMode">
				<Checkbox />
			</Item>
			<Button type="primary" htmlType="submit" disabled={!menuSelectValue}>
				Fix Menu
			</Button>
			<ResponseModal
				title="Menu Fix Result"
				response={response}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
			/>
		</Form>
	);
}
