/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Table, Input, Button, Space, Result } from 'antd';
import { Tag } from 'antd';

import { renderMappingCell } from 'utils/mappingTable.helper';

function MappingTable({ state, fetchMore }) {
	const { data: dataSource, pagination } = state?.data || {};
	const { isLoading } = state;
	const searchInput = useRef(null);
	const [filters, setFilters] = useState({});

	useEffect(() => {
		fetchMore({ filters });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const getCurrentPage = () =>
		Math.ceil(pagination.offset / (pagination.limit + 1)) + 1;

	const handleSearch = ([searchTerm], confirm, dataIndex) => {
		confirm();
		setFilters({ ...filters, [dataIndex]: searchTerm });
	};

	const removeFilter = (key) => {
		const newFilters = { ...filters };
		delete newFilters[key];
		setFilters(newFilters);
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div key={dataIndex} style={{ padding: 8 }}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => {
							removeFilter(dataIndex);
							clearFilters();
						}}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: Object.keys(filters).find((key) => key === dataIndex)
						? '#1890ff'
						: undefined,
				}}
			/>
		),
	});

	const columns = Object.keys(dataSource?.[0] ?? {}).map((key) => ({
		title: key,
		dataIndex: key,
		key,
		...getColumnSearchProps(key),
		render: (dataSource) => renderMappingCell(key, dataSource),
	}));

	if (state.hasError) {
		return <Result status="500" title="Failed to load data" />;
	}

	return (
		<>
			<Table
				title={() => (
					<>
						{Object.keys(filters).map((filterKey) => (
							<Tag key={filterKey}>
								{filterKey}={filters[filterKey]}...
							</Tag>
						))}
						<Button size="small" onClick={() => setFilters({})}>
							Clear Filters
						</Button>
					</>
				)}
				rowKey="id"
				columns={columns}
				dataSource={dataSource || null}
				pagination={
					pagination && {
						total: pagination.totalItemCount,
						pageSize: pagination.limit,
						showSizeChanger: true,
						current: getCurrentPage(),
						size: 'small',
						showQuickJumper: true,
					}
				}
				loading={isLoading}
				onChange={({ current: page, pageSize }) => {
					fetchMore({
						offset: (page - 1) * pageSize,
						limit: pageSize,
						filters,
					});
				}}
				size="small"
				scroll={{ x: 2000, y: 600 }}
			/>
		</>
	);
}

export default MappingTable;
