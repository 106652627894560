import React, { useState, useCallback } from 'react';

import {
	Button,
	Modal,
	Typography,
	DatePicker,
	Input,
	Space,
	Spin,
	Divider,
	Col,
	Row,
	Pagination,
	message,
} from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function PosErrorCount() {
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(null);
	const [paginationPage, setPaginationPage] = useState(1);

	const [rangeValue, setRangeValue] = useState([]);
	const [companyValue, setCompanyValue] = useState('');

	const rangePresets = [
		{ label: 'Last 5 Days', value: [dayjs().add(-5, 'd'), dayjs()] },
		{ label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
		{ label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
		{ label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
		{
			label: 'Last (previous) Month',
			value: [
				dayjs().add(-1, 'month').startOf('month'),
				dayjs().add(-1, 'month').endOf('month'),
			],
		},
	];

	const resetFields = () => {
		setRangeValue([]);
		setPaginationPage(1);
		setCompanyValue('');
		setResult(null);
	};

	const onCancel = () => {
		resetFields();
		setModalOpen(false);
	};

	const submitQuery = useCallback(async () => {
		setLoading(true);
		if (!rangeValue.length) {
			setLoading(false);
			return;
		}
		try {
			const axios = await createCoreAxiosInstance();
			const { data } = await axios.get(
				companyValue.length
					? `/api/v2/pos-exceptions/${companyValue}?startTime=${rangeValue[0]?.unix()}&endTime=${rangeValue[1]?.unix()}`
					: `/api/v2/pos-exceptions?startTime=${rangeValue[0]?.unix()}&endTime=${rangeValue[1]?.unix()}`,
			);
			const resultData = data.reduce((accumulator, current) => {
				const company = current[1];
				const current_payload = current[0];
				// current is an array of [payload, companyid]
				if (!accumulator[company.id]) {
					accumulator[company.id] = [
						{
							companyName: company.name,
							companyID: company.id,
							pos: company.pos,
							errorType: current_payload.error_type,
							errorMessage: current_payload.error_message,
						},
					];
				} else {
					accumulator[company.id] = [
						...accumulator[company.id],
						{
							companyName: company.name,
							companyID: company.id,
							pos: company.pos,
							errorType: current_payload.error_type,
							errorMessage: current_payload.error_message,
						},
					];
				}
				return accumulator;
			}, {});
			setResult(resultData);
		} catch (err) {
			setResult(null);
			console.error(err);
			message.error(
				'Unable to retrieve data. Please verify inputs and try again or contact the dev team for assistance',
			);
		}
		setLoading(false);
	}, [companyValue, rangeValue]);

	const dataDisplay = result ? (
		<>
			{Object.keys(result)
				.filter(
					(_, i) => i >= (paginationPage - 1) * 5 && i < paginationPage * 5,
				)
				.map((companyID) => {
					const errs = result[companyID].reduce((acc, cur) => {
						if (!acc[cur.errorType]) {
							acc[cur.errorType] = [cur.errorMessage];
						} else {
							acc[cur.errorType] = [...acc[cur.errorType], cur.errorMessage];
						}
						return acc;
					}, {});

					return (
						<span key={companyID}>
							<Row justify="space-between" align="middle">
								<Col span={10}>
									<strong>
										{result[companyID][0].companyName}&nbsp;(
										{result[companyID][0].companyID})
									</strong>
								</Col>
								<Col span={8}>POS:{result[companyID][0].pos ?? 'No POS'}</Col>
								<Col span={6}>
									Count:
									{Object.values(errs).reduce(
										(sum, current) => (sum += current.length),
										0,
									)}
								</Col>
							</Row>
							{Object.keys(errs).map((errKey) => {
								const errorSet = [...new Set(errs[errKey])];
								return (
									<>
										<Divider />
										<Row key={`${companyID}-${errKey}`}>
											<Col span={10}>{errKey}</Col>
											<Col span={14}>
												{errorSet.map((errMsg, i) => (
													<>
														<Row key={`${companyID}-${errKey}-${i}`}>
															{errMsg}
														</Row>
														<Divider />
													</>
												))}
											</Col>
										</Row>
									</>
								);
							})}
							<Divider />
						</span>
					);
				})}
			<Pagination
				simple
				current={paginationPage}
				total={Object.keys(result).length}
				onChange={(page) => setPaginationPage(page)}
				defaultPageSize={5}
			/>
		</>
	) : (
		<>
			<RangePicker
				presets={rangePresets}
				value={rangeValue}
				onChange={setRangeValue}
			/>
			<Input
				placeholder="Company ID (optional)"
				onChange={(e) => setCompanyValue(e.target.value)}
				value={companyValue}
			/>
		</>
	);

	return (
		<>
			<Modal
				open={modalOpen}
				onOk={() => {
					result ? onCancel() : submitQuery();
				}}
				okText={result ? 'OK' : 'Submit'}
				okButtonProps={{ disabled: rangeValue.length !== 2 }}
				onCancel={() => {
					onCancel();
				}}
			>
				<Space direction="vertical" className="full-width">
					<Title level={2}>Query POS Exception</Title>
					{loading ? <Spin /> : dataDisplay}
				</Space>
			</Modal>
			<Button type="primary" onClick={() => setModalOpen(true)}>
				Query POS Errors
			</Button>
		</>
	);
}
