import { createCoreAxiosInstance } from 'createAxiosInstance';
import cloneDeep from 'lodash.clonedeep';

import { cleanEmptyProperties } from './format.helper';

export const SUBMIT_ACTIONS = {
	SUBMIT: 'submit',
	CONNECT: 'connect',
	VERIFY: 'verify',
	OK: 'ok',
	CONTINUE: 'continue',
};
export const INTEGRATION_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	PENDING: 'pending',
};

export const updateCompany = async ({
	companyID,
	newCompanyName,
	newCompanyEmail,
	newCompanyEmail2,
}) => {
	try {
		const axios = await createCoreAxiosInstance();
		await axios.patch(`api/corpa/company/${companyID}`, {
			company: {
				name: newCompanyName,
				email: newCompanyEmail,
				email2: newCompanyEmail2,
			},
		});
		return true;
	} catch (error) {
		alert(error);
	}
};

export const updateCompanyAddress = async ({
	companyID,
	address,
	address2,
	city,
	state,
	zipCode,
	country,
}) => {
	try {
		const axios = await createCoreAxiosInstance();
		await axios.patch(`api/corpa/company/${companyID}`, {
			company: {
				address,
				address2,
				city,
				state,
				zip_code: zipCode,
				country,
			},
		});
		return true;
	} catch (error) {
		alert(error);
	}
};

export const updatePrepTimes = async ({
	companyID,
	pickup_time,
	delivery_time,
}) => {
	try {
		const axios = await createCoreAxiosInstance();
		await axios.patch(`api/corpa/company/${companyID}`, {
			company: {
				pickup_time,
				delivery_time,
			},
		});
		return true;
	} catch (error) {
		alert(error);
	}
};

export const fetchRushHourPrepTimes = async (companyID) => {
	try {
		const axios = await createCoreAxiosInstance();
		const { data } = await axios.get(
			`/api/v2/company/${companyID}/rush-hour-data`,
		);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const UpdateRushHourPrepTimes = async (companyID, payload) => {
	try {
		const axios = await createCoreAxiosInstance();
		const { data } = await axios.post(
			`/api/v2/company/${companyID}/rush-hour-data`,
			payload,
		);
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const toggleRushHourPrepTimes = async (companyID, enabled) => {
	try {
		const axios = await createCoreAxiosInstance();
		const { data } = await axios.put(`/api/v2/company/${companyID}`, {
			enable_rush_hour: enabled,
		});
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const fetchResourcePOS = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { status, data: response } = await axios.get(
			`/api/v2/pos-resource/${companyID}`,
		);
		if (status === 200) {
			return response.resource;
		}
		return null;
	} catch {
		return null;
	}
};

export const updatePosResource = async (companyID, data) => {
	const axios = await createCoreAxiosInstance();
	try {
		const posResource = cloneDeep(data ?? {});
		const { status } = await axios.post(
			`/api/v2/pos-resource/${companyID}`,
			cleanEmptyProperties(posResource),
		);
		if (status === 200) {
			return true;
		}
		return false;
	} catch {
		return false;
	}
};

export const deletePosResource = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { status } = await axios.delete(`/api/v2/pos-resource/${companyID}`);
		if (status === 200) {
			return true;
		}
		return false;
	} catch {
		return false;
	}
};

export const formatPosIntegrations = (pos) => {
	return pos?.map((system) => {
		return {
			key: system.pos,
			name: system.metaData?.name,
			submitAction: system.metaData?.submitAction ?? SUBMIT_ACTIONS.SUBMIT, // default to submit for most integrations
			fields: system.metaData?.fields ?? [],
			excerpt: system.metaData?.excerpt ?? 'Point of Sale System',
			status: system.isActive
				? INTEGRATION_STATUS.ACTIVE
				: INTEGRATION_STATUS.INACTIVE,
			imageUrl: system.metaData?.imageUrl,
			isExternalIntegration: system.metaData?.is_external_integration ?? false,
			integrationType: 'pos',
			activeData: system.activeData && {
				...system.activeData,
				external_location_id:
					system.activeData?.external_location_id ||
					system.activeData?.store_id ||
					system.activeData?.merchant_id ||
					system.activeData?.location_id,
			},
			connectionUrl: system.metaData?.connection_url,
			extraInfo: {
				bypassConnectionPage: true,
				...system.metaData,
			},
			resourceName: system.metaData?.resourceName,
			template: system.metaData?.template,
			isSquare: system.pos.toUpperCase().includes('SQUARE'),
			isClover: system.pos.toUpperCase().includes('CLOVER'),
		};
	});
};

export async function getPOSIntegrations(companyID) {
	const axiosInstance = await createCoreAxiosInstance();
	const { data } = await axiosInstance.get(
		`/api/v2/pos-integrations?companyID=${companyID}`,
	);
	const addMeta = Object.keys(data.pointOfSales ?? {}).map((pos) => ({
		pos,
		...data.pointOfSales[pos],
	}));
	return addMeta;
}
