import React, { useState, useEffect } from 'react';

import { EditOutlined, DownloadOutlined } from '@ant-design/icons';
import {
	Breadcrumb,
	Pagination,
	Row,
	Col,
	Typography,
	Skeleton,
	Card,
	Button,
	Input,
	Divider,
	Table,
	message,
} from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import { useQuery } from 'graphql-hooks';
import BulkPublishButton from 'menuManagement/BulkPublishButton';
import PublishStatusChecker from 'menuManagement/PublishStatusChecker';
import UpdateStatusButton from 'menuManagement/UpdateStatusButton';
import UserMetaButton from 'menuManagement/UserMetaButton';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IN_PROGRESS, COMPLETED, CANCELLED } from 'constants/constants';

import { capitalize } from 'utils/helpers';

import PosErrorCount from 'components/Company/PosErrorCount';
import PublishLogs from 'components/Company/PublishLogs';
import ExportButton from 'components/Generic/ExportButton';
import {
	renderHttpError,
	renderFetchError,
	renderGraphQLErrors,
	renderRowAvatar,
	renderDescription,
	renderActions,
	renderColumnCell,
} from 'components/Generic/genericHelper';
import BulkIntegrationCreation from 'components/Merchant/BulkIntegrationCreation';

const { Meta } = Card;

/**
 * Prop defintions
 * query: GrapQL query as a string
 * entity: The key of root item in the query (e.g. user, users, company, etc)
 * title: The key of the root entity to take as the title for the entity (e.g. 'username' for user, 'name' for company)
 * relations: Array of objects to the related entity
 * customActions: Array of components to spread into the action bar of the card
 * showSearch: Bool to show search bar
 * showSearch: Bool to show the list title
 * getDataSourceFromQuery: Callback function extract datasource from the query (for nested queries)
 * excludeColumns: If not null, filter out columns from row in the list of column keys
 */
GenericList.propTypes = {
	query: PropTypes.string.isRequired,
	entity: PropTypes.string.isRequired,
	relations: PropTypes.array,
	title: PropTypes.string,
	customActions: PropTypes.array,
	showSearch: PropTypes.bool,
	showListTitle: PropTypes.bool,
	getDataSourceFromQuery: PropTypes.func,
	excludeColumns: PropTypes.array,
};

export default function GenericList({
	query,
	entity,
	relations,
	title,
	customActions = [],
	showSearch = true,
	showListTitle = true,
	getDataSourceFromQuery = null,
	excludeColumns = null,
}) {
	const [limit, setLimit] = useState(20);
	const [offset, setOffset] = useState(0);
	const [count, setCount] = useState(0);
	const [search, setSearch] = useState('');
	const [expandedRowKeys, setExpandedRowKeys] = useState([]);
	const { loading, error, data } = useQuery(query, {
		variables: { limit, offset, search },
	});

	useEffect(() => {
		if (data) {
			setCount(data[`total${capitalize(entity)}`]);
		}
	}, [loading, data, count, entity, error]);

	const { httpError, graphQLErrors, fetchError } = error ?? {};

	if (httpError) {
		return renderHttpError(httpError);
	}

	if (fetchError) {
		return renderFetchError(fetchError);
	}

	const onPaginationChange = (page, pageSize) => {
		setOffset((page - 1) * pageSize);
		setLimit(pageSize);
	};

	const onExpandRender = (item) => (
		<Card>
			<Skeleton loading={loading} avatar active>
				{!loading && <Meta description={renderDescription(item, relations)} />}
			</Skeleton>
		</Card>
	);

	const performSearch = (e) => {
		setOffset(0);
		setSearch(e.target.value);
	};

	const getCurrentPage = () => Math.ceil(offset / (limit + 1)) + 1;

	/**
	 * Showing the true total count is too costly
	 * on the django ORM for large tables, so we dynamically
	 * show ten more pages than the current
	 */
	const getTotalPagesCount = () => {
		const currentlyLoaded = limit * getCurrentPage();
		const nextFivePagesCount = 10 * limit;
		return data?.[entity]?.length !== 0
			? currentlyLoaded + nextFivePagesCount
			: currentlyLoaded;
	};

	const downloadOrderJSON = async (id) => {
		try {
			const axios = await createCoreAxiosInstance();
			const { data } = await axios.get(`/api/v2/pos-audits/${id}`);
			const dataStr =
				'data:text/json;charset=utf-8,' +
				encodeURIComponent(JSON.stringify(data));
			const element = document.createElement('a');
			element.setAttribute('href', dataStr);
			element.setAttribute('download', id + '.json');
			document.body.appendChild(element);
			element.click();
		} catch {
			message.error('Error pulling order JSON.');
		}
	};

	/**
	 * Read data from query data.<entity name> OR fill with blank data
	 * to prevent exception from ANTD Component
	 */
	let dataSource =
		data?.[entity] ||
		' '
			.repeat(limit)
			.split(' ')
			.map(() => ({}));

	/** Override function to derive dataSource from query */
	if (getDataSourceFromQuery !== null) {
		dataSource = getDataSourceFromQuery(data);
	}

	/**
	 * Derive the columns from the data returned by the query
	 * Each row is rendered into [icon][data columns][actions]
	 */
	const columns = [
		{
			key: 'icon',
			title: '',
			dataIndex: 'icon',
			render: (_, item) => renderRowAvatar(item[title]),
		},
		...Object.keys(dataSource?.[0] ?? {})
			.filter((key) => excludeColumns === null || !excludeColumns.includes(key))
			.map((key) => ({
				key,
				title: key,
				dataIndex: key,
				render: (value) => renderColumnCell(value, key, relations),
			})),
		{
			dataIndex: 'actions',
			render: (_, item) =>
				item &&
				renderActions(
					[
						<Link to={`/list/${entity}/${item.id}`} key="edit">
							<Button key="edit" type="link" icon={<EditOutlined key="edit" />}>
								Edit
							</Button>
						</Link>,
						entity === 'orders' &&
							[IN_PROGRESS, COMPLETED, CANCELLED].includes(
								item?.statusOrder,
							) && (
								<Button
									icon={<DownloadOutlined key="download" />}
									key={`download-${item.id}`}
									type="link"
									onClick={() => {
										downloadOrderJSON(item.id);
									}}
								>
									Download
								</Button>
							),
					],
					customActions,
					item,
					entity,
				),
		},
	];

	// Use the id of the item as the row key
	dataSource = dataSource?.map((item) => ({ ...item, key: item.id })) || [];

	const onRowExpand = (expanded, record) => {
		const keys = new Set(
			expanded
				? [...expandedRowKeys, record.id]
				: [...expandedRowKeys].filter((key) => key !== record.id),
		);
		setExpandedRowKeys(Array.from(keys));
	};
	return (
		<>
			{renderGraphQLErrors(graphQLErrors)}
			<Table
				key={entity}
				title={() => (
					<>
						<Row align={'bottom'} gutter={[16, 16]}>
							<Col flex={4}>
								{showListTitle && (
									<>
										<Typography.Title>{capitalize(entity)}</Typography.Title>
										<Breadcrumb>
											<Breadcrumb.Item>
												<Link to={`/list`}>list</Link>
											</Breadcrumb.Item>
											<Breadcrumb.Item>{entity}</Breadcrumb.Item>
										</Breadcrumb>
									</>
								)}
								<Divider />
								{showSearch && (
									<Input
										placeholder='Type and press "Enter" to search'
										allowClear
										onPressEnter={performSearch}
									/>
								)}
							</Col>
							<Col flex={1}>
								<Pagination
									onChange={onPaginationChange}
									defaultCurrent={1}
									total={getTotalPagesCount()}
									showSizeChanger={true}
									pageSize={limit}
									current={getCurrentPage()}
									size="small"
									showQuickJumper
								/>
							</Col>
							<Col flex={1}>{entity === 'users' && <UserMetaButton />}</Col>
							<Col flex={2}>
								<ExportButton
									entity={entity}
									query={query}
									relations={relations}
								/>
							</Col>
							{entity === 'companies' && (
								<>
									<Col flex={2}>
										<PublishLogs />
									</Col>
									<Col flex={3}>
										<BulkPublishButton />
									</Col>
									<Col flex={4}>
										<PublishStatusChecker />
									</Col>
									<Col flex={5}>
										<UpdateStatusButton />
									</Col>
									<Col flex={3}>
										<PosErrorCount />
									</Col>
								</>
							)}
							{entity === 'merchants' && (
								<Col flex={3}>
									<BulkIntegrationCreation />
								</Col>
							)}
						</Row>
					</>
				)}
				footer={() => (
					<Pagination
						onChange={onPaginationChange}
						defaultCurrent={1}
						total={getTotalPagesCount()}
						showSizeChanger={true}
						pageSize={limit}
						current={getCurrentPage()}
						size="small"
						showQuickJumper
					/>
				)}
				rowKey="id"
				columns={columns}
				dataSource={dataSource}
				loading={loading}
				expandable={{
					rowExpandable: () => true,
					expandedRowRender: (row) => onExpandRender(row),
					expandedRowKeys,
					onExpand: onRowExpand,
				}}
				pagination={false}
			/>
		</>
	);
}
