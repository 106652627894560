import React, { useEffect, useState } from 'react';

import ReactJson from '@microlink/react-json-view';
import { Button } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
	cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

PointOfSaleResource.propTypes = {
	companyID: PropTypes.number.isRequired,
};
export default function PointOfSaleResource({ companyID }) {
	const [fetchingResource, setFetchingResource] = useState(false);
	const [resource, setResource] = useState(false);

	const fetchResource = async () => {
		try {
			setFetchingResource(true);
			const axios = await createCoreAxiosInstance();
			await axios.get(`/company/${companyID}/collect-pos-resources/`);
		} catch {
			alert(
				'NOOOOOOOOOOOOOOOO! Something went wrong so you should message the creator of this giving them a piece of your mind',
			);
			setResource(null);
			setFetchingResource(false);
		}
	};
	const bindWithChunking = (channel, event, callback) => {
		channel.bind(event, callback); // Allow normal unchunked events.

		// Now the chunked variation. Allows arbitrarily long messages.
		var events = {};
		channel.bind('chunked-' + event, (data) => {
			if (!Object.hasOwn(events, data.id)) {
				events[data.id] = { chunks: [], receivedFinal: false };
			}
			var ev = events[data.id];
			ev.chunks[data.index] = data.chunk;
			if (data.final) ev.receivedFinal = true;
			if (
				ev.receivedFinal &&
				ev.chunks.length === Object.keys(ev.chunks).length
			) {
				callback(JSON.parse(ev.chunks.join('')));
				delete events[data.id];
			}
		});
	};
	useEffect(() => {
		if (!companyID) return;

		const subscribeToPusherEvent = async () => {
			const channel = pusher.subscribe(
				`${process.env.REACT_APP_PUSHER_STAGE}-company-${companyID}`,
			);
			bindWithChunking(channel, 'POS_RESOURCE_COLLECTED', (data) => {
				setResource(data);
				setFetchingResource(false);
			});
		};
		subscribeToPusherEvent();
	}, [companyID]);

	return (
		<>
			<Button
				type="primary"
				onClick={fetchResource}
				loading={fetchingResource}
				className="mb-10"
			>
				Fetch POS Resource Values
			</Button>
			{!fetchingResource && resource && (
				<ReactJson src={resource} name={null} />
			)}
		</>
	);
}
