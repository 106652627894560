export const LOGIN_ROUTE = '/login';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';

export const REFRESH = 'refresh';
export const ACCESS = 'access';

export const APP_ICON_BASE_URL =
	'https://assets.cuboh.net/android-image-resources';

export const ACTIVE = 'Active';
export const IN_PROGRESS = 'In Progress';
export const COMPLETED = 'Completed';
export const INACTIVE = 'Inactive';
export const SCHEDULED = 'Scheduled';
export const CANCELLED = 'Canceled Orders';

export const DELIVERY = 'DELIVERY';
export const PICKUP = 'PICKUP';

export const ORDER_STATUS_FILTER = [
	{ text: 'Active', value: 'Active' },
	{ text: 'In Progress', value: 'In Progress' },
	{ text: 'Inactive', value: 'Inactive' },
	{ text: 'Cancelled', value: 'Canceled Orders' },
	{ text: 'Completed', value: 'Completed' },
	{ text: 'Scheduled', value: 'Scheduled' },
];

export const MILES_TO_KILOMETERS_MULTIPLIER = 1.609344;
