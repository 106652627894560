import React, { useState } from 'react';

import {
	Typography,
	Button,
	Space,
	message,
	Modal,
	Spin,
	Table,
	Divider,
} from 'antd';
import { createMenuManagementAxiosInstance } from 'createAxiosInstance';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import PropTypes from 'prop-types';

MatchMappingMenuManagement.propTypes = {
	companyId: PropTypes.string.isRequired,
	merchantId: PropTypes.string.isRequired,
};
export default function MatchMappingMenuManagement({ companyId, merchantId }) {
	const [menuUuid, setMenuUuid] = useState(null);
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const { Text, Title } = Typography;
	const tableColumns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},

		{
			title: 'UUID',
			dataIndex: 'uuid',
			key: 'uuid',
		},
		{
			title: 'External ID',
			dataIndex: 'external_id',
			key: 'external_id',
		},
	];

	const downloadCSV = (key, data) => {
		const blob = new Blob([data], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');
		element.setAttribute('href', url);
		element.setAttribute('download', `${key}_copied.csv`);
		element.click();
	};

	const generateCSV = (key) => {
		const csvRows = [];
		const data = [
			...response[key].unmapped_items,
			...response[key].unchanged_mapped_items,
			...response[key].updated_items,
		];
		const headers = Object.keys(data[0]);
		csvRows.push(headers.join(','));
		for (const row of data) {
			const values = headers.map((header) => {
				const val = row[header];
				return `"${typeof val === 'object' ? JSON.stringify(val) : val}"`;
			});
			csvRows.push(values.join(','));
		}
		downloadCSV(key, csvRows.join('\n'));
	};

	const onCancel = () => {
		setLoading(false);
		setResponse({});
		setModalVisible(false);
	};

	const formatTableName = (key) =>
		key
			.split('_')
			.filter((x) => x.length > 0)
			.map((x) => x.charAt(0).toUpperCase() + x.slice(1))
			.join(' ');

	const generateKeyedTable = (itemType) => {
		return Object.keys(response?.[itemType] ?? {}).map((key) => {
			return (
				<>
					<Table
						pagination={{ hideOnSinglePage: true }}
						title={() => formatTableName(key)}
						key={key}
						dataSource={response?.[itemType][key] ?? null}
						columns={tableColumns}
					/>
					<Divider style={{ visibility: 'hidden' }} />
				</>
			);
		});
	};

	const copyData = async () => {
		setLoading(true);
		setModalVisible(true);
		let data;
		try {
			const axios = await createMenuManagementAxiosInstance();
			data = await axios.patch(
				`/company/${companyId}/merchant/${merchantId}/map/menu/${menuUuid}`,
			);
			setResponse(data.data.result);
			if (data.status !== 200) {
				throw new Error(data.data.detail);
			}
		} catch (e) {
			message.error({
				content: `${
					data?.data?.detail
						? `${data.data.detail} - Status: ${data.status}`
						: 'Exception on Menu Management while patching data.'
				}`,

				duration: 10,
			});
			setResponse({});
			setModalVisible(false);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Space direction="vertical" style={{ width: 600 }}>
			<Title level={5}>Match Mapping and Menu Management Items:</Title>
			<Text>
				Select a Menu and click execute to copy its Mapping data into Menu
				Management:
			</Text>
			<MerchantMenuSelector
				setMenuSelectValue={setMenuUuid}
				merchantId={merchantId}
				selectType="single"
			/>
			<Button disabled={!menuUuid} onClick={copyData}>
				Execute
			</Button>
			<Modal
				width={700}
				open={modalVisible}
				closable
				footer={<Button onClick={onCancel}>Cancel</Button>}
				onCancel={onCancel}
			>
				{loading ? (
					<Spin />
				) : (
					<>
						<Title level={3}>Main Items</Title>
						<Button
							onClick={() => {
								generateCSV('main_items');
							}}
						>
							Export Main Items Copy Result to CSV
						</Button>
						{generateKeyedTable('main_items')}
						<Title level={3}>Modifiers</Title>
						<Button
							onClick={() => {
								generateCSV('modifiers');
							}}
						>
							Export Modifiers Copy Result to CSV
						</Button>
						{generateKeyedTable('modifiers')}
					</>
				)}
			</Modal>
		</Space>
	);
}
