import React, { useState, useCallback } from 'react';

import { Form, Input, Button, InputNumber } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { duplicateMenu } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;

DuplicateMenu.propTypes = {
	merchantId: propTypes.string.isRequired,
	companyId: propTypes.string.isRequired,
};

export default function DuplicateMenu({ merchantId, companyId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const onFinish = useCallback(
		async (values) => {
			const menuManagementResponse = await duplicateMenu(
				companyId,
				merchantId,
				values,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse, setResponseModalVisible, companyId, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	return (
		<Form
			name="duplicateMenu"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Item
				label="Menu UUID"
				name="menuUuid"
				rules={[{ required: true, message: 'Menu UUID to duplicate' }]}
			>
				<Input />
			</Item>

			<Item
				label="New Menu Name"
				name="menuName"
				rules={[{ required: true, message: 'Name to give to new menu' }]}
			>
				<Input />
			</Item>

			<Item
				label="Target Company"
				name="targetCompany"
				rules={[{ required: true, message: 'Enter the target Company' }]}
			>
				<InputNumber />
			</Item>

			<Item
				label="Target Merchant"
				name="targetMerchant"
				rules={[{ required: true, message: 'Enter the target Merchant' }]}
			>
				<InputNumber />
			</Item>

			<Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type="primary" htmlType="submit">
					Execute
				</Button>
				<ResponseModal
					title="Duplicate Menu Result"
					response={response}
					responseModalVisible={responseModalVisible}
					setResponseModalVisible={setResponseModalVisible}
				/>
			</Item>
		</Form>
	);
}
