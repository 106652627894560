import React, { useState, useCallback } from 'react';

import { FormOutlined } from '@ant-design/icons';
import { Form, Input, Button, Modal } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { updateUserMeta } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;

ExportButton.propTypes = {
	visibility: propTypes.bool.isRequired,
};

export default function ExportButton({ visibility }) {
	const [mainModalVisible, setMainModalVisible] = useState(false);
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const onFinish = useCallback(
		async (values) => {
			const allUsers = values.userIds.split(',').map((id) => id.trim());
			const userMetaJSON = JSON.parse(values.userMeta);
			const menuManagementResponse = await updateUserMeta(
				allUsers,
				userMetaJSON,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	return (
		<>
			<Button
				type="primary"
				icon={<FormOutlined />}
				onClick={() => setMainModalVisible(true)}
				hidden={!visibility}
			>
				Bulk Update User Meta
			</Button>
			<Modal
				title="User Meta Update"
				open={mainModalVisible}
				width={800}
				footer={null}
				onCancel={() => setMainModalVisible(false)}
			>
				<Form
					name="userMetaUpdateButton"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Item
						label="User IDs (comma-separated)"
						name="userIds"
						rules={[
							{
								required: true,
								message: 'List of User IDs is required',
							},
							{
								validator: (_, value) => {
									if (!(value && value.length)) return;
									const userIds = value.split(',').map((id) => id.trim());
									for (let userId of userIds) {
										if (Number.isNaN(parseInt(userId))) {
											return Promise.reject(
												'Enter a valid list of User IDs, separated by commas',
											);
										}
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input className="meta-update-inputs" />
					</Item>

					<Item
						label="User Meta Changes (JSON)"
						name="userMeta"
						rules={[
							{
								required: true,
								message: 'JSON User Meta is required',
							},
							{
								validator: (_, value) => {
									try {
										JSON.parse(value);
									} catch {
										return Promise.reject('Enter a valid JSON User Meta');
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input className="meta-update-inputs" />
					</Item>

					<Item wrapperCol={{ offset: 18 }}>
						<Button type="primary" htmlType="submit">
							Execute
						</Button>
						<ResponseModal
							title="User Meta Update"
							response={response}
							responseModalVisible={responseModalVisible}
							setResponseModalVisible={setResponseModalVisible}
						/>
					</Item>
				</Form>
			</Modal>
		</>
	);
}
