import React, { useEffect, useState } from 'react';

import { Form, Button, Typography, Space, Select } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { getAppMenus } from 'menuManagement/MenuManagementHelpers';
import { deleteMenuFromApp } from 'menuManagement/MenuManagementHelpers';

import { SUPPORTED_APPS_DELETE } from './menuManagementConstants';

const { Text } = Typography;

DeleteMenuFromApp.propTypes = {
	merchantId: propTypes.string.isRequired,
	doorDashCredentials: propTypes.string,
};

export default function DeleteMenuFromApp({ doorDashCredentials }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const [menuSelectValue, setMenuSelectValue] = useState('');
	const [appMenus, setAppMenus] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedApp, setSelectedApp] = useState('');

	useEffect(() => {
		(async function () {
			setIsLoading(true);
			if (!selectedApp) return;
			try {
				// to be updated to take the alternate credentials when more apps can be deleted from. currently, doordash is the only selectable app
				const menusObjectArray = await getAppMenus(
					doorDashCredentials,
					selectedApp,
				);

				if (!menusObjectArray) return;

				const menusFormattedForSelect = menusObjectArray.map((menu) => ({
					label: menu.menu_name,
					value: menu.menu_uuid,
				}));

				setAppMenus(menusFormattedForSelect);
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [doorDashCredentials, selectedApp]);

	const onFinish = async () => {
		try {
			if (!selectedApp || !menuSelectValue) {
				setResponse({
					status: 'error',
					data: 'Please select an app and a menu',
				});
				setResponseModalVisible(true);
				return;
			}
			const orchestratorResponse = await deleteMenuFromApp({
				selectedApp,
				doorDashCredentials,
				menuSelectValue,
			});
			setResponse(orchestratorResponse);
			setResponseModalVisible(true);
		} catch (e) {
			console.error(`Exception while deleting from App - ${e}`);
			setResponse({ status: 'error', data: 'Exception while deleting App' });
			setResponseModalVisible(true);
		}
	};

	return (
		<>
			<Form
				name="deleteMenu"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				onFinish={onFinish}
				autoComplete="off"
				style={{ width: 600 }}
			>
				<Space direction="vertical" size="small">
					<Text strong={true}>Delete Menu from App:</Text>
					<Text strong={true}>
						Usage: Select an app, then select a menu to delete (or input the
						menu&apos;s UUID).
					</Text>
					<Text>
						This will remove a menu from the selected app&apos;s listing.
					</Text>
					<Select
						options={SUPPORTED_APPS_DELETE}
						onChange={(value) => {
							setSelectedApp(value);
						}}
						isLoading={isLoading}
						placeholder={'Please select an app'}
					/>
					<Select
						options={appMenus}
						onChange={(value) => {
							setMenuSelectValue(value);
						}}
						isLoading={isLoading}
						placeholder={'Please select a menu'}
						disabled={!selectedApp}
					/>
					<Button type="primary" htmlType="submit">
						Execute
					</Button>
				</Space>
			</Form>
			<ResponseModal
				title="Delete Menu Result"
				response={response}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
			/>
		</>
	);
}
