import AllsetRoutes from 'components/Generic/routes/allsetCredential.routes';
import ChownowRoutes from 'components/Generic/routes/chownowCredential.routes';
import DoordashRoutes from 'components/Generic/routes/doordashCredential.routes';
import GrubhubRoutes from 'components/Generic/routes/grubhubCredential.routes';
import RitualRoutes from 'components/Generic/routes/ritualCredential.routes';
import SkipTheDishesRoutes from 'components/Generic/routes/skipTheDishesCredential.routes';
import UbereatsRoutes from 'components/Generic/routes/ubereatsCredential.routes';

/** Barrel file to bind all of the generic routes together into one export */
const routes = [
	...AllsetRoutes,
	...ChownowRoutes,
	...DoordashRoutes,
	...GrubhubRoutes,
	...RitualRoutes,
	...SkipTheDishesRoutes,
	...UbereatsRoutes,
];

export default routes;
