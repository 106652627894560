/* eslint-disable react/display-name */
import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';

const routes = [
	{
		path: '/list/ubereatsCredentials',
		sideMenu: true,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
             query Q($limit: Int!, $offset: Int!) {
                 ubereatsCredentials(limit: $limit, offset: $offset) {
                    id,
                    name
                    merchant: merchantCuboh {
                        id
                        name
                    }
                    company {
                        id
                        name
                    }
                    active
                 }
              }
         `,
			...relation('ubereatsCredential', 'many'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
			excludeColumns: ['name'],
		},
		showSearch: false,
	},
	{
		path: '/list/ubereatsCredentials/:id',
		sideMenu: false,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
             query Q($id: Int!) {
                 ubereatsCredential(id: $id) {
                     id,
                     name
                     active
                     merchant: merchantCuboh {
                         id
                         name
                     }
                     company {
                         id
                         name
                     }
                 }
              }
         `,
			...relation('ubereatsCredential', 'single'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
		},
	},
];

export default routes;
