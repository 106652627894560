import React, { useState, useCallback } from 'react';

import { Button, Typography, Space, Popconfirm, Divider } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import {
	fetchDeletedMenus,
	clearDeletedMenus,
} from 'menuManagement/MenuManagementHelpers';

const { Text } = Typography;

ViewDeletedMenus.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function ViewDeletedMenus({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const showDeleted = useCallback(async () => {
		const menuManagementResponse = await fetchDeletedMenus(merchantId);
		setResponse(menuManagementResponse);
		setResponseModalVisible(true);
	}, [setResponse, setResponseModalVisible, merchantId]);

	const permanentlyDeleteMenus = useCallback(async () => {
		const menuManagementResponse = await clearDeletedMenus(merchantId);
		setResponse(menuManagementResponse);
		setResponseModalVisible(true);
	}, [setResponse, setResponseModalVisible, merchantId]);

	return (
		<Space direction="vertical" size="small" style={{ width: 600 }}>
			<Text>{`Click "View" to view all the deleted menus of this merchant`}</Text>
			<Button type="primary" onClick={showDeleted}>
				View
			</Button>
			<Divider />
			<Text>{`Click "Permanently Delete" to remove all the DELETED menus of this merchant from the database`}</Text>
			<Popconfirm
				title="This delete operation cannot be undone. Are you sure?"
				onConfirm={permanentlyDeleteMenus}
				okText="Delete"
				cancelText="Cancel"
			>
				<Button type="primary">Permanently Delete</Button>
			</Popconfirm>
			<ResponseModal
				title="Deleted Menus from Merchants"
				response={response}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
			/>
		</Space>
	);
}
