import React, { useState, useCallback } from 'react';

import { Form, Button, Typography } from 'antd';
import IntegrationSelector from 'menuManagement/IntegrationSelector';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { triggerMenuCache } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;
const { Text } = Typography;

TriggerMenuCache.propTypes = {
	merchantId: propTypes.string.isRequired,
	companyId: propTypes.string.isRequired,
};

export default function TriggerMenuCache({ merchantId, companyId }) {
	const [form] = Form.useForm();

	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const onFinish = useCallback(
		async (values) => {
			const allPromises = [];
			for (const application of values.applicationNames) {
				allPromises.push(
					await triggerMenuCache(companyId, merchantId, {
						applicationName: application,
						menuUuid: values.menuUuid,
					}),
				);
			}
			Promise.all(allPromises).then(() => {
				setResponse(allPromises);
				setResponseModalVisible(true);
			});
		},
		[setResponse, setResponseModalVisible, merchantId, companyId],
	);

	const onFinishFailed = useCallback((values) => {
		console.error('Invalid form data', values);
	}, []);

	return (
		<Form
			name="triggerMenuCache"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			form={form}
			style={{ width: 600 }}
		>
			<Item>
				<Text>
					This tool is ONLY for use during onboarding - before publishing a
					menu.
				</Text>
			</Item>
			<Item
				label="Menu UUID"
				name="menuUuid"
				rules={[{ required: true, message: 'Menu UUID to trigger cache for.' }]}
			>
				<MerchantMenuSelector
					setMenuSelectValue={(value) =>
						form.setFieldsValue({ menuUuid: value })
					}
					merchantId={merchantId}
					selectType="single"
				/>
			</Item>

			<Item
				label="Application Names"
				name="applicationNames"
				rules={[
					{
						required: true,
						message: 'Select the applications you want to trigger a cache for.',
					},
				]}
			>
				<IntegrationSelector
					setIntegrationSelectValue={(value) => {
						form.setFieldsValue({ applicationNames: value });
					}}
					integrationType={'push'}
					selectType={'multiple'}
				/>
			</Item>

			<Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type="primary" htmlType="submit">
					Execute
				</Button>
				<ResponseModal
					title="Trigger Menu Cache"
					response={response}
					responseModalVisible={responseModalVisible}
					setResponseModalVisible={setResponseModalVisible}
				/>
			</Item>
		</Form>
	);
}
