import React, { useEffect, useState } from 'react';

import { SyncOutlined, DownloadOutlined } from '@ant-design/icons';
import {
	Card,
	Typography,
	Divider,
	Tabs,
	Button,
	Timeline,
	Tag,
	Alert,
	Space,
	Spin,
} from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import dayjs from 'dayjs';
import MassUpdateMenuHours from 'menuManagement/MassUpdateMenuHours';
import MenuSizeAnalyser from 'menuManagement/MenuSizeAnalysis';
import PropTypes from 'prop-types';

import useMappingDetail, {
	MAIN_ITEMS,
	POS_MAIN_ITEMS,
	MODIFIER_GROUPS,
	MODIFIER_LISTS,
	POS_MODIFIER_GROUPS,
	POS_MODIFIER_LISTS,
	PRODUCTION_MENU_ITEMS,
} from 'hooks/useMappingDetail';
import { PRODUCTION_SUBITEMS } from 'hooks/useMappingDetail';

import BulkMerchantCreator from 'components/Company/BulkMerchantCreator';
import MappingTable from 'components/Company/MappingTable';
import MappingTasks from 'components/Company/MappingTasks';
import PointOfSaleResource from 'components/Company/PointOfSaleResource';
import PrepTimesContainer from 'components/Company/PrepTimesContainer';
import UpdateCompany from 'components/Company/UpdateCompany';
import UpdateCompanyAddress from 'components/Company/UpdateCompanyAddress';

import { READABLE_POS_NAME } from 'menuManagement/menuManagementConstants';

import PointOfSaleResourceSetter from './PointOfSaleResourceSetter';

MappingDetail.propTypes = {
	companyID: PropTypes.number.isRequired,
	companyName: PropTypes.string,
	companyEmail: PropTypes.string,
	companyEmail2: PropTypes.string,
	companyData: PropTypes.object,
};

export default function MappingDetail({
	companyID,
	companyName,
	companyEmail,
	companyEmail2,
	companyData,
}) {
	const [posInfo, setPosInfo] = useState(null);

	const {
		fetchSynchronizationStatus,
		fetchSnapshots,
		synchronizationStatus,
		orchestratorStatus,
		fetchOrchestratorStatus,
		snapshots,
		downloadSnapshot,
		captureSnapshot,
		fetchRawMainItems,
		fetchRawPosMainItems,
		fetchRawModifierGroups,
		fetchRawModifierLists,
		fetchRawPosModifierGroups,
		fetchRawPosModifierLists,
		fetchRawProductionMainItems,
		fetchRawProductionSubItems,
		state,
	} = useMappingDetail(companyID);

	useEffect(() => {
		fetchSynchronizationStatus();
		fetchOrchestratorStatus();
	}, [fetchSynchronizationStatus, fetchOrchestratorStatus]);

	useEffect(() => {
		async function fetchPosData() {
			const axios = await createCoreAxiosInstance();
			setPosInfo(await axios.get(`/api/v2/pos-resource/${companyID}`));
		}
		fetchPosData();
	}, [companyID]);

	const logsContent = () => (
		<>
			<Typography.Title level={2}>Sync Logs</Typography.Title>
			<Button
				onClick={() => {
					fetchSynchronizationStatus();
					fetchOrchestratorStatus();
				}}
				icon={<SyncOutlined spin={synchronizationStatus.isLoading} />}
			/>
			<Divider />
			<Typography.Text code>
				{orchestratorStatus.isLoading ? <Spin /> : orchestratorStatus.data}
			</Typography.Text>
			<Typography.Text code>
				{!synchronizationStatus.isLoading && (
					<pre>{JSON.stringify(synchronizationStatus.data, null, 2)}</pre>
				)}
			</Typography.Text>
		</>
	);

	const snapshotContent = () => (
		<>
			<div>
				<Typography.Title level={2}>Snapshots</Typography.Title>
				<Alert
					message="It may take several minutes for the snapshot to appear in the dashboard depending on the amount of mapping data."
					type="info"
					showIcon
				/>
				<Divider />
				<Button type="primary" onClick={() => captureSnapshot()}>
					New Snapshot
				</Button>
				<Divider />
				<Timeline pending={snapshots.isLoading} mode="left">
					{!snapshots.isLoading &&
						snapshots.data &&
						snapshots.data.map((snapshot) => (
							<Timeline.Item
								key={snapshot}
								color="green"
								label={dayjs.unix(snapshot).format('D/MM/YYYY hh:mm:ss a')}
							>
								<Tag onClick={() => downloadSnapshot(snapshot)}>
									Created Snapshot [{snapshot}] <DownloadOutlined />
								</Tag>
							</Timeline.Item>
						))}
					{snapshots?.data?.length === 0 && (
						<Timeline.Item label={'No snapshots have been created'} color="red">
							No Available snapshots
						</Timeline.Item>
					)}
				</Timeline>
			</div>
		</>
	);

	const stagedMapping = () => (
		<>
			<Typography.Title level={2}>Staged Mapping</Typography.Title>
			<Divider />
			<Typography.Title level={3}>Mapping Views</Typography.Title>
			<Divider />
			<MappingTable state={state[MAIN_ITEMS]} fetchMore={fetchRawMainItems} />
			<Typography.Title level={3}>POS Main Items</Typography.Title>
			<Divider />
			<MappingTable
				state={state[POS_MAIN_ITEMS]}
				fetchMore={fetchRawPosMainItems}
			/>
			<Typography.Title level={3}>Modifier Groups</Typography.Title>
			<Divider />
			<MappingTable
				state={state[MODIFIER_GROUPS]}
				fetchMore={fetchRawModifierGroups}
			/>
			<Typography.Title level={3}>Modifier List</Typography.Title>
			<Divider />
			<MappingTable
				state={state[MODIFIER_LISTS]}
				fetchMore={fetchRawModifierLists}
			/>
			<Typography.Title level={3}>POS Modifier Groups</Typography.Title>
			<Divider />
			<MappingTable
				state={state[POS_MODIFIER_GROUPS]}
				fetchMore={fetchRawPosModifierGroups}
			/>
			<Typography.Title level={3}>POS Modifier List</Typography.Title>
			<Divider />
			<MappingTable
				state={state[POS_MODIFIER_LISTS]}
				fetchMore={fetchRawPosModifierLists}
			/>
		</>
	);

	const publishedMapping = () => (
		<>
			<Typography.Title level={2}>Live Mapping</Typography.Title>
			<Divider />
			<Typography.Title level={3}>Menu Items</Typography.Title>
			<Divider />
			<MappingTable
				state={state[PRODUCTION_MENU_ITEMS]}
				fetchMore={fetchRawProductionMainItems}
			/>
			<Typography.Title level={3}>Sub Items</Typography.Title>
			<Divider />
			<MappingTable
				state={state[PRODUCTION_SUBITEMS]}
				fetchMore={fetchRawProductionSubItems}
			/>
		</>
	);

	const items = [
		{
			label: 'Sync Logs',
			key: 'logs',
			children: logsContent(),
		},
		{
			label: 'Snapshots',
			key: 'snapshots',
			children: snapshotContent(),
		},
		{
			label: 'Background Tasks',
			key: 'backgroundTasks',
			children: <MappingTasks companyID={companyID} />,
		},
		{
			label: 'Staged Mapping',
			key: 'staggedMapping',
			children: stagedMapping(),
		},
		{
			label: 'Live Mapping',
			key: 'publishedMapping',
			children: publishedMapping(),
		},
		{
			label: 'Mass Update Hours',
			key: 'tools',
			children: (
				<MassUpdateMenuHours
					parentCompanyID={companyID}
					parentCompanyData={{ companies: [{ id: companyID }] }}
					isParent={false}
				/>
			),
		},
		{
			label: 'POS Resource',
			key: 'posResource',
			children: <PointOfSaleResource companyID={companyID} />,
		},
		{
			label: 'Bulk Merchant Creator',
			key: 'bulkMerchant',
			children: <BulkMerchantCreator companyID={companyID} />,
		},
		{
			label: 'Edit Company',
			key: 'editCompany',
			children: (
				<UpdateCompany
					companyID={companyID}
					companyName={companyName}
					companyEmail={companyEmail}
					companyEmail2={companyEmail2}
				/>
			),
		},
		{
			label: 'Edit Address',
			key: 'editAddress',
			children: <UpdateCompanyAddress companyData={companyData} />,
		},
		{
			label: 'Prep Times',
			key: 'prepTimes',
			children: <PrepTimesContainer companyData={companyData} />,
		},
		{
			label: 'Menu Size Analysis',
			key: 'menuSizeAnalysis',
			children: <MenuSizeAnalyser companyData={companyData} />,
		},
		{
			label: 'Resource Setter',
			key: 'resourceSetter',
			children: <PointOfSaleResourceSetter companyData={companyData} />,
		},
	];

	const onTabChange = (activeTab) => {
		switch (activeTab) {
			case 'logs':
				fetchSynchronizationStatus();
				break;
			case 'snapshots':
				fetchSnapshots();
				break;
			default:
				break;
		}
	};
	return (
		<>
			<Card>
				<Space direction="vertical">
					<>
						<Typography.Text strong>
							POS Name:
							{posInfo?.data?.resource?.type
								? READABLE_POS_NAME[posInfo?.data?.resource?.type]
								: 'N/A'}
						</Typography.Text>
					</>
					<>
						<Typography.Text strong>
							{posInfo?.data?.resource?.use_mm_endpoint
								? 'POS Shooter: Active'
								: 'POS Shooter: Inactive'}
						</Typography.Text>
					</>
				</Space>
			</Card>
			<Card>
				<Tabs
					items={items}
					tabPosition="left"
					onChange={onTabChange}
					defaultActiveKey="backgroundTasks"
					destroyInactiveTabPane
				/>
			</Card>
		</>
	);
}
