import React, { useCallback, useState } from 'react';

import { Button, Form, Input } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { deleteMenuManagementItem } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;

DeleteMenuManagementItem.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function DeleteMenuManagementItem({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const onFinish = useCallback(
		async (values) => {
			const menuManagementResponse = await deleteMenuManagementItem(
				merchantId,
				values,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse, setResponseModalVisible, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	return (
		<Form
			name="deleteMenuManagementItem"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
		>
			<Item
				label="Item UUID"
				name="itemUuid"
				rules={[{ required: true, message: 'Item UUID to delete' }]}
			>
				<Input />
			</Item>

			<Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type="primary" htmlType="submit">
					Delete
				</Button>
				<ResponseModal
					title="Delete Item From Menu"
					response={response}
					responseModalVisible={responseModalVisible}
					setResponseModalVisible={setResponseModalVisible}
				/>
			</Item>
		</Form>
	);
}
