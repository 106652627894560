import React, { useState, useCallback } from 'react';

import { Form, Button, Input, Typography, Space, Divider } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { fetchDeletedMenuItems } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;

ViewDeletedItemsFromMenu.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function ViewDeletedItemsFromMenu({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});

	const onFinish = useCallback(
		async (values) => {
			const menuManagementResponse = await fetchDeletedMenuItems(
				merchantId,
				values,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse, setResponseModalVisible, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	return (
		<Form
			name="viewDeletedItemsFromMenu"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Space direction="vertical" size="small">
				<Typography.Text>
					Click Execute to view all the deleted items from the entered menu UUID
				</Typography.Text>
				<Divider />
				<Item
					label="Menu UUID"
					name="menuUuid"
					rules={[{ required: true, message: 'Parent Menu UUID to view' }]}
				>
					<Input />
				</Item>
				<Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type="primary" htmlType="submit">
						Execute
					</Button>
					<ResponseModal
						title="Deleted Items from Menu"
						response={response}
						responseModalVisible={responseModalVisible}
						setResponseModalVisible={setResponseModalVisible}
					/>
				</Item>
			</Space>
		</Form>
	);
}
