import React, { useState, useCallback } from 'react';

import { FormOutlined } from '@ant-design/icons';
import { Form, Input, Button, Modal, Checkbox, Spin, message } from 'antd';
import IntegrationSelector from 'menuManagement/IntegrationSelector';
import ResponseModal from 'menuManagement/ResponseModal';

import {
	buildBulkPublishObject,
	bulkPublish,
} from 'menuManagement/MenuManagementHelpers';

import PublishResponse from './PublishResponse';

const { Item } = Form;

export default function BulkPublishButton() {
	const [publishPlatform, setPublishPlatform] = useState('all');
	const [mainModalVisible, setMainModalVisible] = useState(false);
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [buildResponse, setBuildResponse] = useState({});
	const [publishResponse, setPublishResponse] = useState(null);
	const [showPublishedModal, setShowPublishedModal] = useState(false);
	const [useAll, setUseAll] = useState(false);
	const [loading, setLoading] = useState(false);
	const onFinish = useCallback(
		async (values) => {
			setLoading(true);
			const allMenus = values.menuIds.split(',').map((id) => id.trim());
			const menuManagementResponse = await buildBulkPublishObject(
				allMenus,
				publishPlatform,
				useAll,
			);
			setPublishPlatform(null);
			setUseAll(null);
			setBuildResponse(menuManagementResponse);
			setResponseModalVisible(true);
			setMainModalVisible(false);
			setLoading(false);
		},
		[publishPlatform, useAll],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	const onClickPublish = async () => {
		message.success(`Started publishing menus, please wait...`);
		const result = await bulkPublish(buildResponse?.data);
		setResponseModalVisible(false);
		setPublishResponse(result);
		setShowPublishedModal(true);
	};

	const onCancel = () => {
		setPublishPlatform(null);
		setResponseModalVisible(false);
		setBuildResponse(false);
		setMainModalVisible(false);
		setPublishResponse(false);
		setShowPublishedModal(false);
		setUseAll(false);
		setLoading(false);
	};

	const handleSetAll = () => {
		setUseAll(!useAll);
		if (useAll) {
			setPublishPlatform('all');
		}
	};

	return (
		<>
			<Button
				type="primary"
				icon={<FormOutlined />}
				onClick={() => {
					setMainModalVisible(true);
					setPublishPlatform('all');
					setResponseModalVisible(false);
					setBuildResponse(false);
				}}
			>
				Bulk Publish Menus
			</Button>
			<Modal
				title="Bulk Publish Menus"
				open={mainModalVisible}
				width={800}
				footer={null}
				onCancel={onCancel}
			>
				{loading ? (
					<Spin></Spin>
				) : (
					<Form
						name="bulkPublishButton"
						labelCol={{ span: 8 }}
						wrapperCol={{ span: 16 }}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Item
							label="Menu IDs (comma-separated)"
							name="menuIds"
							rules={[
								{
									required: true,
									message: 'List of Menu IDs is required',
								},
								{
									validator: (_, value) => {
										if (!(value && value.length)) return;
										const menuIds = value.split(',').map((id) => id.trim());
										for (let menuId of menuIds) {
											if (!menuId) {
												return Promise.reject(
													'Enter a valid list of Menu UUIDs, separated by commas',
												);
											}
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<Input className="meta-update-inputs" />
						</Item>
						<Item label="App" name="bulkPublish">
							<IntegrationSelector
								setIntegrationSelectValue={setPublishPlatform}
								integrationType={'push'}
								disabled={useAll}
							/>
						</Item>
						<Item wrapperCol={{ offset: 8 }}>
							<Checkbox checked={useAll} onChange={handleSetAll}>
								Publish All Integrations
							</Checkbox>
						</Item>
						<Item wrapperCol={{ offset: 18 }}>
							<Button type="primary" htmlType="submit">
								Execute
							</Button>
						</Item>
					</Form>
				)}
			</Modal>
			<ResponseModal
				title="Menus to be published:"
				response={buildResponse}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
				isPublish={true}
				footer={
					<Button
						disabled={buildResponse.status !== 200}
						onClick={onClickPublish}
					>
						Publish
					</Button>
				}
			/>
			<Modal
				open={showPublishedModal}
				onCancel={onCancel}
				onOk={onCancel}
				title="Menus sent to publish:"
			>
				<PublishResponse publishResult={publishResponse} />
			</Modal>
		</>
	);
}
