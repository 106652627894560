import {
	SET_IS_LOADING,
	SET_ERROR,
	SET_DATA,
	SET_FEATURE_FLAGS,
} from 'constants/auth.constants';

export const setIsLoading = ({ isLoading }) => ({
	type: SET_IS_LOADING,
	isLoading,
});

export const setError = ({ hasError, message }) => ({
	type: SET_ERROR,
	hasError,
	message,
});

export const setData = ({ data }) => ({
	type: SET_DATA,
	data,
});

export const setFeatureFlags = ({ featureFlags }) => ({
	type: SET_FEATURE_FLAGS,
	featureFlags,
});
