import {
	atLeastOneLowerCase,
	atLeastOneNumber,
	atLeastOneSpecialCharacter,
	atleastOneUpperCase,
	atLeastTenCharactersLong,
	noEmptySpaces,
} from 'utils/helpers';

export const PERMISSION_ERROR =
	"You don't have permission to perform this action.";

export const ALL_LOCATIONS = 'All Locations';

/** Action Types */
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_DATA = 'SET_DATA';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';

export const PASSWORD_RULES = [
	{
		condition: noEmptySpaces,
		label: 'No empty spaces',
	},
	{
		condition: atLeastTenCharactersLong,
		label: 'At least 10 characters long',
	},
	{
		condition: (value) =>
			atLeastOneSpecialCharacter(value) || atLeastOneNumber(value),
		label: 'At least 1 special character or number',
	},
	{
		condition: (value) =>
			atLeastOneLowerCase(value) && atleastOneUpperCase(value),
		label: 'At least 1 upper and lower case',
	},
];
