import React from 'react';

import {
	Typography,
	TimePicker,
	Space,
	Checkbox,
	Button,
	DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import propTypes from 'prop-types';

HoursSetter.propTypes = {
	hours: propTypes.object.isRequired,
	setHours: propTypes.func.isRequired,
	isSpecial: propTypes.bool,
};
function HoursSetter({ hours, setHours, isSpecial = false }) {
	const { Text } = Typography;
	const { RangePicker } = TimePicker;

	const onDateChange = (date, dateString) => {
		setHours({
			[dateString]: Object.values(hours)[0],
		});
	};

	const generateButtons = (day) => {
		if (isSpecial) return;
		return (
			<>
				<Button
					onClick={() =>
						setHours({
							...hours,
							[day]: {
								ranges: [
									...hours[day].ranges,
									{ startTime: '09:00', endTime: '17:00' },
								],
								closed: false,
								open24hours: false,
							},
						})
					}
				>
					Add {day.charAt(0).toUpperCase() + day.slice(1)} Range
				</Button>
				<Button
					disabled={hours[day].ranges.length < 2}
					onClick={() =>
						setHours({
							...hours,
							[day]: {
								ranges: hours[day].ranges.slice(0, -1),
								closed: false,
								open24hours: false,
							},
						})
					}
				>
					Remove {day.charAt(0).toUpperCase() + day.slice(1)} Range
				</Button>
			</>
		);
	};

	return (
		<Space direction="vertical">
			{Object.keys(hours).map((day) => (
				<Space key={day} size="sm" direction="vertical">
					{isSpecial ? null : (
						<Text>{`${day.charAt(0).toUpperCase()}${day.slice(1)}`}</Text>
					)}
					<Space direction="horizontal">
						{hours[day].ranges.map((range, index) => (
							<Space direction="horizontal" key={`${day}-${index}-space`}>
								{isSpecial ? (
									<DatePicker
										defaultValue={dayjs(day)}
										onChange={onDateChange}
										allowClear={false}
										disabledDate={(current) =>
											current && current < dayjs().endOf('day')
										}
									/>
								) : null}
								<RangePicker
									key={`${day}-${index}`}
									allowClear={false}
									order={false}
									disabled={hours[day].open24hours || hours[day].closed}
									format={'HH:mm'}
									value={[
										dayjs(range.startTime, 'HH:mm'),
										dayjs(range.endTime, 'HH:mm'),
									]}
									onChange={(newHours) => {
										let newRanges = hours[day].ranges;
										newRanges[index] = {
											startTime: newHours[0].format('HH:mm'),
											endTime: newHours[1].format('HH:mm'),
										};
										setHours({
											...hours,
											[day]: {
												...hours[day],
												ranges: newRanges,
											},
										});
									}}
								/>
							</Space>
						))}
						<Checkbox
							onChange={(e) => {
								setHours({
									...hours,
									[day]: {
										...hours[day],
										ranges: [
											{
												startTime: '00:00',
												endTime: '23:59',
											},
										],
										open24hours: e.target.checked,
									},
								});
							}}
							disabled={hours[day].closed}
							checked={hours[day].open24hours}
						>
							24 Hours
						</Checkbox>
						<Checkbox
							onChange={(e) => {
								setHours({
									...hours,
									[day]: {
										...hours[day],
										ranges: [
											{
												startTime: '00:00',
												endTime: '00:00',
											},
										],
										closed: e.target.checked,
									},
								});
							}}
							disabled={hours[day].open24hours}
							checked={hours[day].closed}
						>
							Closed
						</Checkbox>
						{generateButtons(day)}
					</Space>
				</Space>
			))}
		</Space>
	);
}

export default HoursSetter;
