import React, { useState } from 'react';

import {
	Modal,
	message,
	Typography,
	Button,
	Tag,
	Space,
	Input,
	DatePicker,
	Select,
	List,
	Tooltip,
	Row,
	Col,
	Spin,
	Divider,
} from 'antd';
import { createMenuManagementAxiosInstance } from 'createAxiosInstance';
import IntegrationSelector from 'menuManagement/IntegrationSelector';
import PropTypes from 'prop-types';

import { READABLE_APPS_NAME } from 'menuManagement/menuManagementConstants';

PublishLogs.propTypes = {
	companyID: PropTypes.number.isRequired,
};

export default function PublishLogs() {
	const [showPublishModal, setShowPublishModal] = useState(false);
	const [publishMerchantId, setPublishMerchantId] = useState(null);
	const [publishMenuUuid, setPublishMenuUuid] = useState(null);
	const [publishPlatform, setPublishPlatform] = useState(null);
	const [publishResult, setPublishResult] = useState(null);
	const [publishStartDate, setPublishStartDate] = useState([]);
	const [publishEndDate, setPublishEndDate] = useState([]);
	const [publishCompanyId, setPublishCompanyId] = useState(null);
	const [showPublishLogs, setShowPublishLogs] = useState(false);
	const [data, setData] = useState(null);
	const { Text, Title } = Typography;

	const clearFields = () => {
		setPublishMenuUuid(null);
		setPublishMerchantId(null);
		setPublishPlatform(null);
		setPublishStartDate([]);
		setPublishEndDate([]);
		setPublishCompanyId(null);
		setPublishResult(null);
	};

	const handlePublishSubmit = async () => {
		setData(null);
		let publishSubmitObject = {};
		//Endpoint requires properties to only exist if they are part of the query
		publishCompanyId && (publishSubmitObject.company_id = publishCompanyId);
		publishStartDate &&
			(publishSubmitObject.start_time = `${publishStartDate[1]} 00:00:00.000 Z`);
		publishEndDate &&
			(publishSubmitObject.end_time = `${publishEndDate[1]} 23:59:59.999 Z`);
		publishMerchantId && (publishSubmitObject.merchant_id = publishMerchantId);
		publishMenuUuid && (publishSubmitObject.menu_uuid = publishMenuUuid);
		publishPlatform && (publishSubmitObject.integration_name = publishPlatform);
		publishResult && (publishSubmitObject.result = publishResult);

		try {
			setShowPublishLogs(true);
			const axios = await createMenuManagementAxiosInstance();
			const { data } = await axios.patch(
				`/cuboh/publish-logs`,
				publishSubmitObject,
			);
			setData(data);
			clearFields();
		} catch (e) {
			message.error(e);
			clearFields();
		}
	};

	const downloadCSV = (data) => {
		const blob = new Blob([data], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.setAttribute('href', url);
		a.setAttribute('download', 'publish_logs.csv');
		a.click();
	};

	const generateCSV = () => {
		const csvRows = [];
		const headers = Object.keys(data.logs[0]);
		csvRows.push(headers.join(','));
		for (const row of data.logs) {
			const values = headers.map((header) => {
				const val = row[header];
				return `"${typeof val === 'object' ? JSON.stringify(val) : val}"`;
			});

			csvRows.push(values.join(','));
		}
		downloadCSV(csvRows.join('\n'));
	};

	const displayRates = () => {
		return Object.keys(data?.success_rates).map((integration) => (
			<Space key={integration} direction="vertical" className="full-width">
				<Title level={4}>
					{READABLE_APPS_NAME[integration] ?? integration}:
				</Title>
				<Text>
					{`Total Logs: ${data.success_rates[integration].total_count}`}
				</Text>
				<Text>
					{`Successes: ${data.success_rates[integration].success_count}`}
				</Text>
				<Text>{`Failures: ${data.success_rates[integration].fail_count}`}</Text>
				<Text>
					{`Success Rate: ${data.success_rates[integration].success_rate}
					`}
				</Text>
			</Space>
		));
	};

	const displayLogs = () => {
		return data ? (
			<>
				<Title level="3">Query Results</Title>
				<Button
					className="mt-15"
					onClick={() => generateCSV()}
					disabled={!data?.logs?.length}
				>
					Download CSV
				</Button>
				{displayRates()}
				<Divider />
				<List
					style={{ overflow: 'auto', height: '500px' }}
					dataSource={data.logs}
					renderItem={(item) => {
						return (
							<List.Item>
								<List.Item.Meta
									title={
										<Space direction="horizontal">
											<Text>{`Company ${item.company_id}, Merchant ${item.merchant_id}`}</Text>

											<Tag
												color={
													item.result === 'published' ? 'green' : 'volcano'
												}
											>
												{`${
													item.result.charAt(0).toUpperCase() +
													item.result.slice(1)
												} (${
													READABLE_APPS_NAME[item.integration_name_id] ??
													item.integration_name_id
												})`}
											</Tag>
										</Space>
									}
									description={
										<Space direction="vertical">
											<Text type="secondary">{`${item.menu_uuid_id}`}</Text>

											<Text>
												{`Date: ${item.timestamp.substr(
													0,
													10,
												)} | ${item.timestamp.substr(11, 5)}`}
											</Text>
											<Text>{`Detail: ${JSON.stringify(item.detail)}`}</Text>
										</Space>
									}
								/>
							</List.Item>
						);
					}}
				></List>
				<Divider />
			</>
		) : (
			<Spin />
		);
	};

	const displayForm = () => {
		return (
			<Space direction="vertical">
				<Title level={3}>Build Publish Logs Query</Title>
				<Text>
					{`Please input fields to query by. To leave fields out of the query,
						leave them blank.`}
				</Text>

				<Input
					addonBefore="Company ID:"
					allowClear
					value={publishCompanyId}
					onChange={(e) => setPublishCompanyId(e.target.value)}
				/>

				<Input
					addonBefore="Merchant ID:"
					allowClear
					onChange={(e) => setPublishMerchantId(e.target.value)}
				/>

				<Input
					addonBefore="Menu UUID:"
					allowClear
					onChange={(e) => setPublishMenuUuid(e.target.value)}
				/>
				<Row>
					<Col span={6}>
						<Typography.Text>App:</Typography.Text>
					</Col>
					<Col span={10}>
						<IntegrationSelector
							setIntegrationSelectValue={setPublishPlatform}
							integrationType={'push'}
							selectType={'single'}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={6}>
						<Typography.Text>Publish Result:</Typography.Text>
					</Col>
					<Col span={10}>
						<Select
							className="full-width"
							defaultValue={null}
							value={publishResult}
							options={[
								{ label: 'Any', value: null },
								{ label: 'Published', value: 'published' },
								{ label: 'Failed', value: 'failed' },
							]}
							onChange={(value) => setPublishResult(value)}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={6}>
						<Typography.Text>Start Date:</Typography.Text>
					</Col>
					<Col span={10}>
						<DatePicker
							showToday={false}
							allowClear
							value={publishStartDate ? publishStartDate[0] : null}
							onChange={(date, dateString) => {
								setPublishStartDate([date, dateString]);
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={6}>
						<Typography.Text>End Date:</Typography.Text>
					</Col>
					<Col span={10}>
						<DatePicker
							showToday
							allowClear
							value={publishEndDate ? publishEndDate[0] : null}
							onChange={(date, dateString) =>
								setPublishEndDate([date, dateString])
							}
						/>
					</Col>
				</Row>
				<Tooltip title="Date Range is required.">
					<Button
						disabled={!publishStartDate.length || !publishEndDate.length}
						type="primary"
						htmlType="submit"
						onClick={() => handlePublishSubmit()}
					>
						Submit
					</Button>
				</Tooltip>
				<Button type="primary" onClick={() => clearFields()}>
					Clear
				</Button>
			</Space>
		);
	};

	return (
		<>
			<Button
				onClick={() => {
					setData(null);
					setShowPublishModal(true);
				}}
				type="primary"
			>
				Query Publish Logs
			</Button>
			<Modal
				open={showPublishModal}
				closable
				footer={null}
				onCancel={() => {
					clearFields();
					setShowPublishModal(false);
					setShowPublishLogs(false);
				}}
			>
				{showPublishLogs ? displayLogs() : displayForm()}
			</Modal>
		</>
	);
}
