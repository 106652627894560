import React from 'react';

import { Tabs, Typography, Space } from 'antd';
import PublishMenuMultiple from 'menuManagement/PublishMenuMultiple';
import propTypes from 'prop-types';

import MassUpdateMenuHours from './MassUpdateMenuHours';
import MassUpdateSpecialHours from './MassUpdateSpecialHours';
import MenuSizeAnalyser from './MenuSizeAnalysis';

MenuManagementAdminParentCompany.propTypes = {
	companyId: propTypes.string.isRequired,
	companyData: propTypes.object.isRequired,
};

export default function MenuManagementAdminParentCompany({
	companyId,
	companyData,
}) {
	const { Text } = Typography;
	const items = [
		{
			label: 'Tools',
			key: 1,
			children: (
				<Space direction="vertical">
					<Text strong>Parent Company Menu Management Tools</Text>
					<Text>Please select a Tab above Menu Management</Text>
				</Space>
			),
		},
		{
			label: 'Publish Multiple Menus',
			key: 2,
			children: (
				<PublishMenuMultiple
					parentCompanyId={companyId}
					parentCompanyData={companyData}
				/>
			),
		},
		{
			label: 'Mass Update Menu Hours',
			key: 3,
			children: (
				<MassUpdateMenuHours
					parentCompanyId={companyId}
					parentCompanyData={companyData}
				/>
			),
		},
		{
			label: 'Mass Update Special/Holiday Hours',
			key: 4,
			children: (
				<MassUpdateSpecialHours
					parentCompanyId={companyId}
					parentCompanyData={companyData}
				/>
			),
		},
		{
			label: 'Menu Size Analysis',
			key: 5,
			children: <MenuSizeAnalyser parentCompanyData={companyData} />,
		},
	];
	return <Tabs items={items} defaultActiveKey="1" size="small" />;
}
