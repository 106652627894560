/* eslint-disable react/display-name */
import { relation } from 'components/Generic/genericHelper';
import Loader from 'components/Loader';

const routes = [
	{
		path: '/list/allsetCredentials',
		sideMenu: true,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericList')),
		containerProps: {
			query: `
             query Q($limit: Int!, $offset: Int!) {
                 allsetCredentials(limit: $limit, offset: $offset) {
                    id,
                    displayName
                    merchant: merchantCuboh {
                         id
                         name
                    },
                    company {
                        id
                        name
                    },
                    active,
                 }
              }
         `,
			...relation('allsetCredential', 'many'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
			showSearch: false,
			excludeColumns: ['displayName'],
		},
	},
	{
		path: '/list/allsetCredentials/:id',
		sideMenu: false,
		layout: 'list',
		container: Loader(() => import('components/Generic/GenericDetail')),
		containerProps: {
			query: `
             query Q($id: Int!) {
                 allsetCredential(id: $id) {
                    id,
                     displayName
                     active
                     merchant: merchantCuboh {
                         id
                         name
                     }
                     company {
                         id
                         name
                     }
                 }
              }
         `,
			...relation('allsetCredential', 'single'),
			relations: [
				relation('merchant', 'single'),
				relation('company', 'single'),
			],
		},
	},
];

export default routes;
