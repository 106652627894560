import React, { useState, createContext } from 'react';

import {
	Card,
	Typography,
	Button,
	Form,
	message,
	Skeleton,
	Row,
	Col,
} from 'antd';
import PropTypes from 'prop-types';

import { KILOMETERS, MILES } from 'constants/storefront.constants';

import { updateCompany, triggerStorefrontCache } from 'utils/helpers';
import { convertMilesToKilometers } from 'utils/storefront.helper';

import StorefrontDelivery from 'components/Company/Storefront/StorefrontDelivery';

const { Title } = Typography;

StorefrontDetail.Context = createContext();

StorefrontDetail.propTypes = {
	companyID: PropTypes.string.isRequired,
	storefrontDeliveryActive: PropTypes.bool.isRequired,
	storefrontSelfDeliveryActive: PropTypes.bool.isRequired,
	storefrontSelfDeliveryFee: PropTypes.string,
	storefrontSelfDeliveryMaximumDistanceKilometers: PropTypes.string,
	children: PropTypes.node,
};

export default function StorefrontDetail({
	companyID,
	storefrontDeliveryActive,
	storefrontSelfDeliveryActive,
	storefrontSelfDeliveryFee,
	storefrontSelfDeliveryMaximumDistanceKilometers,
	children,
}) {
	const [form] = Form.useForm();
	const [selfDeliveryDistanceType, setSelfDeliveryDistanceType] =
		useState(KILOMETERS);
	const [formLoading, setFormLoading] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(false);

	const onFinish = async (values) => {
		setFormLoading(true);
		try {
			const storefront_self_delivery_maximum_distance_kilometers =
				selfDeliveryDistanceType === MILES
					? convertMilesToKilometers(
							values.storefront_self_delivery_maximum_distance_kilometers,
					  )
					: values.storefront_self_delivery_maximum_distance_kilometers;

			const storefront_maximum_consumer_delivery_fee_cents =
				values.storefront_maximum_consumer_delivery_fee_cents * 100;

			const data = {
				...values,
				storefront_self_delivery_maximum_distance_kilometers,
				storefront_maximum_consumer_delivery_fee_cents,
			};

			await updateCompany({ companyID, data });
			message.success('Storefront settings updated successfully');
		} catch (error) {
			message.error(`Error updating storefront settings: ${error}}`);
		} finally {
			setFormLoading(false);
		}
	};

	if (formLoading) {
		return <Skeleton active paragraph={{ rows: 10 }} />;
	}

	return (
		<StorefrontDetail.Context.Provider
			value={{
				selfDeliveryDistanceType,
				setSelfDeliveryDistanceType,
			}}
		>
			<Card title={<Title level={2}>Storefront Settings</Title>}>
				<Form
					className="edit-storefront"
					id="edit-storefront"
					form={form}
					onFinish={onFinish}
				>
					<StorefrontDelivery
						companyID={companyID}
						storefrontDeliveryActive={storefrontDeliveryActive}
						storefrontSelfDeliveryActive={storefrontSelfDeliveryActive}
						storefrontSelfDeliveryFee={storefrontSelfDeliveryFee}
						storefrontSelfDeliveryMaximumDistanceKilometers={
							storefrontSelfDeliveryMaximumDistanceKilometers
						}
						setSubmitDisabled={setSubmitDisabled}
					/>
					{children}
					<Row>
						<Col span={8}>
							<Button
								type="primary"
								onClick={async () => {
									try {
										setSubmitDisabled(true);
										await triggerStorefrontCache(companyID);
										message.success('Storefront Cache Success');
									} catch (error) {
										console.error(error);
										message.error(
											`Error triggering storefront cache: ${error}}`,
										);
									} finally {
										setSubmitDisabled(false);
									}
								}}
								disabled={submitDisabled}
								loading={submitDisabled}
							>
								Trigger Storefront Cache
							</Button>
						</Col>
						<Col
							span={8}
							offset={8}
							style={{ display: 'flex', justifyContent: 'flex-end' }}
						>
							<Button
								type="primary"
								htmlType="submit"
								disabled={submitDisabled}
								loading={submitDisabled}
							>
								Save Storefront Settings
							</Button>
						</Col>
					</Row>
				</Form>
			</Card>
		</StorefrontDetail.Context.Provider>
	);
}
