import React, { useState, useCallback } from 'react';

import { Button, Space, Form } from 'antd';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import propTypes from 'prop-types';

import { convertMenuImages } from 'menuManagement/MenuManagementHelpers';

import ResponseModal from './ResponseModal';

const { Item } = Form;

ConvertImages.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function ConvertImages({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const [menuSelectValue, setMenuSelectValue] = useState('');

	const onFinish = useCallback(async () => {
		try {
			setResponseModalVisible(true);
			setResponse({ loading: true });
			const menuManagementResponse = await convertMenuImages(menuSelectValue);
			setResponse(menuManagementResponse);
		} catch (e) {
			setResponseModalVisible(true);
			console.error(`Exception while accessing Menu Management - ${e}`);
			setResponse({ error: 'Exception while accessing Menu Management' });
		}
	}, [setResponse, setResponseModalVisible, menuSelectValue]);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid UUID');
	}, []);

	return (
		<Form
			name="convertImages"
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 18 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Space direction="vertical" size="small">
				<Item
					label="Target Menu for Image Conversion:"
					name="menuUuid"
					rules={[
						() => ({
							validator() {
								if (!menuSelectValue.length) {
									return Promise.reject(new Error('Select a menu.'));
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<MerchantMenuSelector
						setMenuSelectValue={setMenuSelectValue}
						merchantId={merchantId}
						selectType="single"
					/>
				</Item>
				<Button type="primary" htmlType="submit" disabled={!menuSelectValue}>
					Convert Images
				</Button>
				<ResponseModal
					title="Image Conversion Result"
					response={response}
					responseModalVisible={responseModalVisible}
					setResponseModalVisible={setResponseModalVisible}
				/>
			</Space>
		</Form>
	);
}
