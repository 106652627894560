import React from 'react';

import { Button, message, Typography, Space, Divider } from 'antd';
import { createMenuManagementAxiosInstance } from 'createAxiosInstance';
import PropTypes from 'prop-types';

RemoveReadOnlyMenus.propTypes = {
	companyId: PropTypes.string.isRequired,
	merchantId: PropTypes.string.isRequired,
};

const { Text, Title } = Typography;

export default function RemoveReadOnlyMenus({ companyId, merchantId }) {
	const removeReadOnly = async () => {
		try {
			const axios = await createMenuManagementAxiosInstance();
			const response = await axios.delete(
				`/company/${companyId}/merchant/${merchantId}/read_only`,
			);
			if (response.data.menus) {
				message.success({
					content: `Menus ${response.data.menus?.toString()} removed`,
					duration: 10,
				});
			}
			if (response.status === 404) {
				message.error(response.data?.detail);
			}
		} catch {
			message.error('Something went wrong removing read only menus.');
		}
	};

	return (
		<Space direction="vertical" style={{ width: 600 }}>
			<Title level={5}>Remove Read Only Menus:</Title>
			<Text strong>
				Click execute to remove read only menus from Merchant ID {merchantId}.
			</Text>
			<Text>
				Deleting a menu with this feature is an IRREVERSIBLE action. The menu
				and all of the categories, items, etc. contained within will be removed
				from the database.
			</Text>
			<Text>The menu will NOT be restorable using the Restore Menu tool!</Text>
			<Divider />
			<Button onClick={removeReadOnly}>Execute</Button>
		</Space>
	);
}
