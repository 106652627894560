import React, { useState } from 'react';

import { Alert, Button, Form, Input, Popconfirm, Row, Typography } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import PropTypes from 'prop-types';

BulkMerchantCreator.propTypes = {
	companyID: PropTypes.number.isRequired,
};
export default function BulkMerchantCreator({ companyID }) {
	const [unsuccessfullyCreatedMerchants, setUnsuccessfullyCreatedMerchants] =
		useState([]);

	const [form] = Form.useForm();
	const onFinish = async (values) => {
		setUnsuccessfullyCreatedMerchants([]);
		const merchants = values.merchants
			.split(',')
			.map((merchantName) => merchantName.trim());
		try {
			const axios = await createCoreAxiosInstance();
			for (const merchantName of merchants) {
				try {
					await axios.post('/merchant/', {
						name: merchantName,
						company_id: companyID,
					});
				} catch (error) {
					setUnsuccessfullyCreatedMerchants((oldArray) => [
						...oldArray,
						merchantName,
					]);
				}
			}
		} catch (error) {
			alert(error);
		} finally {
			form.resetFields();
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.error(errorInfo);
		alert('Failed to create merchants');
	};
	return (
		<>
			<Form
				form={form}
				name="multi-merchant-create"
				style={{
					maxWidth: 1000,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label="Merchant names (comma separated)"
					name="merchants"
					rules={[
						{
							required: true,
							message: 'Input Merchant Names comma separated',
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					wrapperCol={{
						offset: 8,
						span: 16,
					}}
				>
					<Popconfirm
						placement="top"
						title="Create the merchants? this can't be undone"
						onConfirm={() => {
							form.submit();
						}}
						okText="Ok"
						cancelText="Nah"
					>
						<Button type="primary">Submit</Button>
					</Popconfirm>
				</Form.Item>
			</Form>
			{unsuccessfullyCreatedMerchants?.length > 0 && (
				<>
					<Row>
						<Typography.Title level={4}>
							The Following merchants were NOT created
						</Typography.Title>
					</Row>
					<Row>
						<Alert
							message={unsuccessfullyCreatedMerchants.toString()}
							type="error"
						/>
					</Row>
				</>
			)}
		</>
	);
}
