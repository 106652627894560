import React from 'react';

import { Avatar } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';

import config from 'config/app.config';

/**
 * Map a configuration object to an enum from the API
 * @param {string} app: An integration enum from the backend
 * @returns {object}: A configuration object
 */
export const getConfigFromIntegration = (integration) => {
	switch (integration) {
		case 'allset':
		case 'ALLSET':
			return config.integrations.allSet;
		case 'chownow':
		case 'CHOWNOW':
			return config.integrations.chowNow;
		case 'doordash':
		case 'DOORDASH':
			return config.integrations.doorDash;
		case 'eatstreet':
		case 'EATSTREET':
			return config.integrations.eatStreet;
		case 'ezcater':
		case 'EZCATER':
			return config.integrations.ezCater;
		case 'foodora':
		case 'FOODORA':
			return config.integrations.foodora;
		case 'freshbytes':
		case 'FRESHBYTES':
			return config.integrations.freshBytes;
		case 'grubhub':
		case 'GRUBHUB':
			return config.integrations.grubHub;
		case 'postmates':
		case 'POSTMATES':
			return config.integrations.postmates;
		case 'ritual':
		case 'RITUAL':
			return config.integrations.ritual;
		case 'skip':
		case 'SKIP':
			return config.integrations.skipTheDishes;
		case 'uber':
		case 'UBEREATS':
			return config.integrations.uberEats;
		default:
			return config.integrations.default;
	}
};

export function getAvatar(user, avatar, size = {}) {
	const { firstName, lastName } = user;
	return (
		<Avatar
			size={size}
			className={avatar}
			src={user.upload && user.upload.path}
		>
			{firstName.charAt(0)}
			{lastName.charAt(0)}
		</Avatar>
	);
}

export function formatCurrency(value) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return formatter.format(value);
}

export const capitalize = (word) => `${word[0].toUpperCase() + word.slice(1)}`;

// Returns a Promise that resolves after "ms" Milliseconds
export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Related entities returned from graphQL will either be an object or object[].
 * Flatten the related data down to be an id or a space delimited string of ids
 *
 * @example
 * [{ id: 1, users: [{ id: 2}, {id: 3}], merchant: { id: 4 }  }]
 * relations: ['users', 'merchant']
 *
 * [{ id: 1, users: '2;3', merchant: '4'}]
 *
 * @param {Object[]} data: Data returned from GraphQL
 * @param {string[]} relationKeys: Array of related entity key names
 * @returns Object[] of data with related entries flattened
 */
export const flattenCSVData = (data, relationKeys) =>
	data.map((entry) => ({
		...entry,
		...relationKeys.reduce((accumulator, relationKey) => {
			// relatedData will either be an array of objects, or an object
			const relatedData = entry[relationKey];

			// If not an array, return the id in place of the relation
			if (!Array.isArray(relatedData)) {
				return { ...accumulator, [relationKey]: relatedData?.id };
			}

			// Otherwise, return the relation as a string of ; delimated id's
			return {
				...accumulator,
				[relationKey]: relatedData.reduce(
					(accumulator, current, index) =>
						index === 0
							? `${current.id}`
							: `${accumulator};${current.id}`.trim(),
					'',
				),
			};
		}, {}),
	}));

export const atLeastOneNumber = (value) => !!value?.match(/(?=.*\d)/);
export const atLeastOneLowerCase = (value) => !!value?.match(/.*[a-z].*/);
export const atleastOneUpperCase = (value) => !!value?.match(/.*[A-Z].*/);
export const noEmptySpaces = (value) => value && !value?.match(/(?=.*\s)/);
export const atLeastOneSpecialCharacter = (value) =>
	!!value?.match(/(?=.*[@#$%^&+=])/);
export const atLeastTenCharactersLong = (value) => !!value?.match(/.{10,}/);

export async function requestChunker({
	axios,
	endpointUrl,
	parameterArray,
	maxChunkSize = 200,
	allowedConcurrentRequests = 5,
}) {
	const parameterChunks = [];
	// split the parameter array into manageable arrays
	for (let i = 0; i < parameterArray.length; i += maxChunkSize) {
		const chunk = parameterArray.slice(i, i + maxChunkSize);
		parameterChunks.push(chunk);
	}
	// for each array of parameters, make the request
	let allData = [];
	while (parameterChunks.length) {
		const currentBatchReturn = await Promise.all(
			parameterChunks
				.splice(0, allowedConcurrentRequests) // perform the queries in batches to avoid throttling failures
				.map((chunk) => axios.get(urlBuilder(endpointUrl, chunk))),
		);
		allData = [...allData, ...currentBatchReturn];
	}

	// flatten the data under one object and return in a familiar structure
	return {
		data: allData.reduce((accumulator, { data }) => {
			// check the data return type
			if (Array.isArray(data) && !Array.isArray(accumulator)) {
				return [...data];
			} else if (Array.isArray(data) && Array.isArray(accumulator)) {
				return [...accumulator, ...data];
			} else {
				// loop over each object key to prevent overwriting data
				Object.keys(data).forEach((key) => {
					if (!accumulator[key]) {
						accumulator[key] = data[key];
					} else if (Array.isArray(accumulator[key])) {
						accumulator[key] = [...accumulator[key], ...data[key]];
					} else {
						accumulator[key] = { ...accumulator[key], ...data[key] };
					}
				}, {});
			}

			return accumulator;
		}, {}),
	};
}

const urlBuilder = (endpointUrl, chunk) => {
	if (endpointUrl.includes('/??/')) {
		return endpointUrl.replace('??', chunk);
	}
	return `${endpointUrl}${chunk}`;
};

export const updateCompany = async ({ companyID, data }) => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.put(`api/v2/company/${companyID}`, data);
	return response?.data?.company;
};

export const triggerStorefrontCache = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	const { response } = await axios.get(`api/v2/storefront/${companyID}/cache`);
	return response;
};
