import 'es6-shim';
import React from 'react';

import { ConfigProvider } from 'antd';
import AuthProvider from 'context/AuthProvider';
import QueryProvider from 'context/QueryProvider';
import PropTypes from 'prop-types';
import HttpsRedirect from 'react-https-redirect';
import { Routes, Route } from 'react-router-dom';

import routes from 'config/router.config';
import { corpaTheme } from 'config/theme.config';

import { useAuth } from 'hooks/useAuth';

import Loader from 'components/Loader';

const AuthForm = Loader(() => import('containers/AuthForm'));
const LoginForm = Loader(() => import('components/Login/LoginForm'));
const ForgotPassword = Loader(
	() => import('components/Login/ForgotPassword'),
	'forgotPassword',
);
const ResetPasswordForm = Loader(
	() => import('components/Login/ResetPasswordForm'),
	'resetPassword',
);
const Layout = Loader(() => import('layouts/AppLayout'));
const Exception = Loader(() => import('exceptions/404'));

App.propTypes = {
	container: PropTypes.node,
	layout: PropTypes.node,
	containerProps: PropTypes.object,
};

export default function App() {
	return (
		<HttpsRedirect>
			<ConfigProvider prefixCls={'cuboh'} theme={corpaTheme}>
				<AuthProvider>
					<QueryProvider>
						<Routes>
							{[
								{
									path: '/login',
									render: () => <LoginForm authHook={useAuth} />,
								},
								{
									path: '/forgot-password',
									render: () => <ForgotPassword />,
								},
								{
									path: '/reset-password',
									render: () => <ResetPasswordForm />,
								},
							].map(({ path, render }) => (
								<Route
									key={path}
									path={path}
									element={<AuthForm>{render()}</AuthForm>}
								/>
							))}
							{routes.map((route) => {
								const { container: Component, containerProps } = route;
								return route.routes ? (
									route.routes.map((subRoute) => {
										const { container: Component, containerProps } = subRoute;
										return (
											<Route
												exact
												key={subRoute.key}
												path={subRoute.path}
												element={
													<Layout {...route}>
														<Component {...containerProps} />
													</Layout>
												}
											/>
										);
									})
								) : (
									<Route
										exact
										key={route.name}
										path={route.path}
										layout={route.layout}
										element={
											<Layout {...route}>
												<Component {...containerProps} />
											</Layout>
										}
									/>
								);
							})}
							<Route element={<Exception />} />
						</Routes>
					</QueryProvider>
				</AuthProvider>
			</ConfigProvider>
		</HttpsRedirect>
	);
}
