import React, { useState, useCallback } from 'react';

import { FormOutlined } from '@ant-design/icons';
import { Form, Input, Button, Modal, Select, Spin, Tooltip } from 'antd';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import ResponseModal from 'menuManagement/ResponseModal';

import {
	INTEGRATION_STATUSES,
	SUPPORTED_APPS_STATUS_UPDATE,
} from './menuManagementConstants';

const { Item } = Form;

export default function UpdateStatusButton() {
	const [platform, setPlatform] = useState(
		SUPPORTED_APPS_STATUS_UPDATE[0].value,
	);
	const [mainModalVisible, setMainModalVisible] = useState(false);
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState(null);
	const [status, setStatus] = useState(INTEGRATION_STATUSES[0].value);
	const [loading, setLoading] = useState(false);
	const onFinish = useCallback(
		async (values) => {
			setLoading(true);
			const allIds = values.menuIds.split(',').map((id) => parseInt(id.trim()));
			try {
				const axios = await createCoreAxiosInstance();
				const data = await axios.patch(`/api/v2/update_integration_status`, {
					status: status,
					app: platform,
					integrations: allIds,
				});
				setResponse(data);
			} catch (e) {
				setResponse(e.response);
			}

			setResponseModalVisible(true);
			setLoading(false);
			setPlatform(SUPPORTED_APPS_STATUS_UPDATE[0].value);
			setStatus(INTEGRATION_STATUSES[0].value);

			setResponseModalVisible(true);
			setLoading(false);
		},
		[platform, status],
	);

	const onCancel = () => {
		setPlatform(SUPPORTED_APPS_STATUS_UPDATE[0].value);
		setResponseModalVisible(false);
		setResponse(null);
		setMainModalVisible(false);
		setStatus(INTEGRATION_STATUSES[0].value);

		setLoading(false);
	};

	return (
		<>
			<Button
				type="primary"
				icon={<FormOutlined />}
				onClick={() => {
					setMainModalVisible(true);
					setPlatform(SUPPORTED_APPS_STATUS_UPDATE[0].value);
					setStatus(INTEGRATION_STATUSES[0].value);

					setResponseModalVisible(false);
					setResponse(false);
				}}
			>
				Bulk Update Integration Status
			</Button>
			<Modal
				title="Bulk Update Integration Status"
				open={mainModalVisible}
				width={800}
				footer={null}
				onCancel={onCancel}
			>
				{loading ? (
					<Spin />
				) : (
					<Form
						name="updateStatusButton"
						labelCol={{ span: 8 }}
						wrapperCol={{ span: 16 }}
						onFinish={onFinish}
						autoComplete="off"
					>
						<Item
							label="Integration IDs (comma-separated)"
							name="menuIds"
							rules={[
								{
									required: true,
									message: 'List of IDs is required',
								},
								{
									validator: (_, value) => {
										if (!(value && value.length)) return;
										const ids = value.split(',').map((id) => id.trim());

										ids.forEach((id) => {
											if (!id) {
												return Promise.reject(
													'Enter a valid list of IDs, separated by commas',
												);
											}
										});

										return Promise.resolve();
									},
								},
							]}
						>
							<Input className="meta-update-inputs" />
						</Item>

						<Item label="App" name="userMeta">
							<Select
								className="full-width"
								defaultValue={SUPPORTED_APPS_STATUS_UPDATE[0].value}
								value={platform}
								maxTagCount={1}
								options={SUPPORTED_APPS_STATUS_UPDATE}
								onChange={(value) => setPlatform(value)}
							/>
						</Item>
						<Item label="Status" name="status">
							<Select
								className="full-width"
								defaultValue={INTEGRATION_STATUSES[0].value}
								value={status}
								maxTagCount={1}
								options={INTEGRATION_STATUSES}
								onChange={(value) => setStatus(value)}
							/>
						</Item>

						<Item wrapperCol={{ offset: 18 }}>
							<Tooltip title={'IDs are required'}>
								<Button type="primary" htmlType="submit">
									Execute
								</Button>
							</Tooltip>
							<ResponseModal
								title="Status Updates:"
								response={response}
								responseModalVisible={responseModalVisible}
								setResponseModalVisible={setResponseModalVisible}
							/>
						</Item>
					</Form>
				)}
			</Modal>
		</>
	);
}
