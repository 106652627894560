import React, { useState } from 'react';

import {
	Alert,
	Button,
	Slider,
	Row,
	Col,
	InputNumber,
	Divider,
	Typography,
} from 'antd';
import PropTypes from 'prop-types';

import { updatePrepTimes } from 'utils/company.helper';

PrepTimes.propTypes = {
	companyData: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	setLoading: PropTypes.func.isRequired,
};
export default function PrepTimes({ companyData, loading, setLoading }) {
	const [pickupTimeInput, setPickupTimeInput] = useState(
		Math.floor(companyData.pickupTime / 60),
	);
	const [deliveryTimeInput, setDeliveryTimeInput] = useState(
		Math.floor(companyData.deliveryTime / 60),
	);

	const onPickupTimeChange = (newTime) => {
		setPickupTimeInput(newTime);
	};
	const onDeliveryTimeChange = (newTime) => {
		setDeliveryTimeInput(newTime);
	};

	const updatePrepTimesHandler = async () => {
		setLoading(true);
		const result = await updatePrepTimes({
			companyID: companyData.id,
			pickup_time: Math.floor(pickupTimeInput * 60),
			delivery_time: Math.floor(deliveryTimeInput * 60),
		});
		if (result) {
			alert('Success updating company!!');
			window.location.reload();
		}
		setLoading(false);
	};

	return (
		<>
			<Row>
				<Col>
					<Typography.Title underline level={4}>
						Prep Times
					</Typography.Title>
				</Col>
			</Row>
			<Row>
				<Col>
					<Typography.Title className="mt-0 mb-0" level={5}>
						Pickup:
					</Typography.Title>
				</Col>
			</Row>
			<Row className="ml-10">
				<Col span={12}>
					<Slider
						min={1}
						onChange={onPickupTimeChange}
						value={pickupTimeInput}
					/>
				</Col>
				<Col span={4}>
					<InputNumber
						min={1}
						style={{
							margin: '0 16px',
						}}
						value={pickupTimeInput}
						onChange={onPickupTimeChange}
						addonAfter="Minutes"
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Typography.Title className="mt-0 mb-0" level={5}>
						Delivery:
					</Typography.Title>
				</Col>
			</Row>
			<Row className="ml-10">
				<Col span={12}>
					<Slider
						min={1}
						onChange={onDeliveryTimeChange}
						value={deliveryTimeInput}
					/>
				</Col>
				<Col span={4}>
					<InputNumber
						min={1}
						style={{
							margin: '0 16px',
						}}
						value={deliveryTimeInput}
						onChange={onDeliveryTimeChange}
						addonAfter="Minutes"
					/>
				</Col>
			</Row>
			<Row className="m-10">
				<Button
					type="primary"
					disabled={loading}
					onClick={() => {
						updatePrepTimesHandler();
					}}
				>
					Update Prep Times
				</Button>
			</Row>
			<Divider />
			<Alert
				message="Please note that high traffic preparation times will override the
				regular preparation times for the configured dates and times"
				type="warning"
			/>
			<Divider />
		</>
	);
}
