import React, { useEffect, useState } from 'react';

import { GraphQLClient, ClientContext } from 'graphql-hooks';

import { useAuth } from 'hooks/useAuth';
/* eslint-disable react/prop-types */
export default function QueryProvider({ children }) {
	const [client, setClient] = useState(null);
	const { user } = useAuth();

	useEffect(() => {
		if (user) {
			const client = new GraphQLClient({
				url: `${process.env.REACT_APP_CORE_SERVER_URL}/graphql`,
				useGETForQueries: true,
				headers: {
					Authorization: `Bearer ${window.localStorage.getItem('access')}`,
				},
			});
			setClient(client);
		}
	}, [user]);

	return (
		<ClientContext.Provider value={client}>{children}</ClientContext.Provider>
	);
}
