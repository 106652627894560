import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import propTypes from 'prop-types';

import { getMerchantMenus } from 'menuManagement/MenuManagementHelpers';

MerchantMenuSelector.propTypes = {
	setMenuSelectValue: propTypes.func.isRequired,
	merchantId: propTypes.string.isRequired,
	selectType: propTypes.string,
};

export default function MerchantMenuSelector({
	setMenuSelectValue,
	merchantId,
	selectType,
}) {
	const [allMenus, setAllMenus] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async function () {
			setIsLoading(true);
			try {
				const menusFormattedForSelect = await getMerchantMenus(merchantId);
				setAllMenus(menusFormattedForSelect);
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [merchantId]);

	return (
		<Select
			className={'menu-management-menu-selector'}
			mode={selectType || 'single'}
			optionFilterProp={'label'}
			showSearch
			loading={isLoading}
			placeholder={'Select from menus...'}
			options={allMenus}
			onChange={(value) => setMenuSelectValue(value)}
		/>
	);
}
