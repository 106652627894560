import React, { useState, useCallback } from 'react';

import { Form, Button } from 'antd';
import IntegrationSelector from 'menuManagement/IntegrationSelector';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { linkAppToMenu } from 'menuManagement/MenuManagementHelpers';

const { Item } = Form;

LinkAppToMenu.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function LinkAppToMenu({ merchantId }) {
	const [form] = Form.useForm();

	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const onFinish = useCallback(
		async (values) => {
			const menuManagementResponse = await linkAppToMenu(merchantId, values);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		},
		[setResponse, setResponseModalVisible, merchantId],
	);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid form data');
	}, []);

	return (
		<Form
			name="linkAppToMenu"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			form={form}
			style={{ width: 600 }}
		>
			<Item
				label="Menu UUID"
				name="menuUuid"
				rules={[{ required: true, message: 'Menu UUID to add app' }]}
			>
				<MerchantMenuSelector
					setMenuSelectValue={(value) =>
						form.setFieldsValue({ menuUuid: value })
					}
					merchantId={merchantId}
					selectType="single"
				/>
			</Item>

			<Item
				label="Integration Name"
				name="integrationName"
				rules={[
					{ required: true, message: 'Select the integration you want to add' },
				]}
			>
				<IntegrationSelector
					setIntegrationSelectValue={(value) => {
						form.setFieldsValue({ integrationName: value });
					}}
					integrationType={'push'}
					selectType={'single'}
				/>
			</Item>

			<Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type="primary" htmlType="submit">
					Execute
				</Button>
				<ResponseModal
					title="Add App to Menu"
					response={response}
					responseModalVisible={responseModalVisible}
					setResponseModalVisible={setResponseModalVisible}
				/>
			</Item>
		</Form>
	);
}
